import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getMeasurementsHistory = (sampled_objects = '', resource_types = '', date_from = null, date_to = null, sample_types = '', quality_types = '') => {
    const queryParams = new URLSearchParams();

    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));

    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }

    for (let el in resource_types) {
        queryParams.append('resource_type', resource_types[el]);
    }

    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el]);
    }

    return instance.get('/measurements?' + queryParams.toString());
}

const getMultiTypeMeasurements = (sampled_object, resource_types = '', date_from = null, date_to = null, sample_types = '', aggregation = null) => {
    const queryParams = new URLSearchParams();
    queryParams.append('sampled_object', sampled_object);
    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));

    for (let el in resource_types) {
        queryParams.append('resource_type', resource_types[el]);
    }

    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el]);
    }

    queryParams.append('aggregation', aggregation);

    return instance.get('/measurements/values?' + queryParams.toString());
}

const getTBMeasurements = (sampled_object, resource_types = '', date_from = null, date_to = null, aggregation = null) => {
    const queryParams = new URLSearchParams();
    queryParams.append('sampled_object', sampled_object);
    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));

    for (let el in resource_types) {
        queryParams.append('resource_type', resource_types[el]);
    }

    queryParams.append('aggregation', aggregation);

    return instance.get('/measurements/tb/values?' + queryParams.toString());
}

const postMeasurements = (token, collectorId, locationId, values, files) => {
    const config = setAuthHeader(token);
    const data = [];
    const dataKeys = Object.keys(values);
    for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (key === 'notes' || values[key] === "") continue;
        else data.push({ resourceTypeKey: key, value: values[key] });
    }

    const formData = new FormData();

    formData.append('samplingType', 3);
    formData.append('sampledObject', locationId);
    // formData.append('collectorId', collectorId);
    formData.append('notes', values.notes);
    formData.append('data', JSON.stringify(data));
    if (!!files && files.length) {
        for (const file of files) {
            formData.append('pictures[]', file, file.name);
        }
    }

    return instance.post('/samplinglogs/', formData, config);
}

const postHistoryMeasurements = (token, sampled_objects, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();

    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }
    queryParams.append('skipFlag', skipFlag);
    
    const formData = new FormData();
    // formData.append('collectorId', collectorId);
    formData.append('file', file, file.name);

    return instance.post('/samplinglogs/history?' + queryParams.toString(), formData, config);
}

const getLocationLatest = (sampled_objects = '', resource_types = '', date = new Date()) => {
    const queryParams = new URLSearchParams();

    queryParams.append('date', localISOString(date));

    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }

    for (let el in resource_types) {
        queryParams.append('resource_type', resource_types[el]);
    }

    return instance.get('/measurements/latest?' + queryParams.toString());
}

const getTBLocationLatest = (sampled_objects = '', resource_types = '') => {
    const queryParams = new URLSearchParams();
    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }

    for (let el in resource_types) {
        queryParams.append('resource_type', resource_types[el]);
    }

    return instance.get('/measurements/tb/latest?' + queryParams.toString());
}


const measurementsAPI = {
    getMultiTypeMeasurements,
    getTBMeasurements,
    postMeasurements,
    getLocationLatest,
    getTBLocationLatest,
    getMeasurementsHistory,
    postHistoryMeasurements
}

export default measurementsAPI;
