import React, { useEffect, useState, useCallback } from 'react';
import { Text, Loader } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { MultiAxisLineChart } from 'charts'
import { deepEqual } from 'utils';


import API from 'api';


const MeasurementsTrendChart = (props) => {
  const { tbLocation, locationId, resourceTypes, sampleTypes, aggregation } = props;
  const selectedDateRange = useSelector(selectDateRange);
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const intl = useIntl();

  const [data, setData] = useState(null);

  const convertData = useCallback((apiData) => {
    // sometimes, resource types config is not yet ready when chart is beeing rendered - use plain
    if (resourceTypesConf) {
      // get (translated) names for used resource types
      // replace code with actual labels
      apiData.forEach(dataSerie => {
        const labelId = resourceTypesConf.find(type => type.key === dataSerie._id.label).name;
        dataSerie.key = dataSerie._id.label;
        dataSerie.label = intl.formatMessage({ id: labelId });
      });
    }
    setData({ datasets: apiData });
  }, [setData, intl, resourceTypesConf]);

  useEffect(() => {
    setData(null);
    let api_req;
    if (tbLocation !== undefined) api_req = API.measurements.getTBMeasurements(locationId, resourceTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, aggregation);
    else api_req = API.measurements.getMultiTypeMeasurements(locationId, resourceTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes, aggregation);
    api_req.then(items => {
      if (items.data && items.data.length) {
        // store data in state
        convertData(items.data);
      }
      else setData({});
    }).catch(error => {
      setData({ error: error });
      console.log("MeasurementsTrendChart -- ", error);
    });
  }, [locationId, tbLocation, resourceTypes, selectedDateRange, sampleTypes, aggregation, convertData]);

  if (data !== null && data.datasets)
    return <MultiAxisLineChart
      name={"trendLineChart_" + locationId}
      data={data}
      sidebarDispatch={props.sidebarDispatch}
      locationId={locationId}
      chartRef={props.chartRef}
    />;

  else if (data === null) return <Loader size="small" />;

  else if (data.error) return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>;

  else return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA_TIME_PERIOD' })}</Text>;
}

export default React.memo(MeasurementsTrendChart, (prevProps, nextProps) => deepEqual(prevProps, nextProps, ['locationId', 'resourceTypes', 'selectedDateRange', 'sampleTypes', 'aggregation']));