import instance from './axiosEnv';

const getConfiguration = (user) => {
    return instance.get('/configuration');
}

const authAPI = {
    getConfiguration
}

export default authAPI;