import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { fetchConfiguration } from 'redux/configurationSlice';
import { fetchLocations } from 'redux/locationsSlice';
import { authUser } from 'redux/userSlice';

// start loading initial auth then config/location list
store.dispatch(authUser()).then(() => {
  store.dispatch(fetchConfiguration());
  store.dispatch(fetchLocations());
});


// start loading initial config


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
