import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, Icon } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { cookieConsentSelector, toggleCookieConsent } from 'redux/userSlice';
import './cookieFooter.scss'

const Footer = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const cookie = useSelector(cookieConsentSelector);
    const [isHidden, setHidden] = useState(cookie);

    const renderFooter = useCallback(() => {
        const acceptEssentialCookies = () => {
            const date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            document.cookie = `COOKIE_CONSENT=accepted;expires=${date.toUTCString()};path=/`;
            dispatch(toggleCookieConsent({ cookieConsent: true }));
            setHidden(!isHidden);
        }
        const closeCookieFooter = () => {
            setHidden(!isHidden);
        }

        return (
            <div className={!isHidden ? "cookie-footer" : "cookie-footer hidden"}>
                <div className="cookie-footer-message-container">
                    <Text as="span" className="cookie-footer-message">{intl.formatMessage({ id: 'COOKIE_FOOTER.MESSAGE' })}</Text>
                    <a className="cookie-footer-href" href="/cookie-policy">{intl.formatMessage({ id: 'COOKIE_FOOTER.COOKIES_INFO' })}</a>
                </div>
                <div className="cookie-footer-button-container">
                    <Button className="cookie-footer-button" primary onClick={acceptEssentialCookies}>{intl.formatMessage({ id: 'COOKIE_FOOTER.ACCEPT_ESSENTIAL_COOKIES' })}</Button>
                </div>
                <Icon className="cookie-footer-close" name="cross" onClick={closeCookieFooter} base />
            </div>
        )
    }, [isHidden, dispatch, intl]);
    return renderFooter();
};

export default Footer;