import React, { useState, useEffect, useCallback } from "react";
import { AppView, Column, Row, Accordion, AccordionItem, Loader, Text, Tile } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLocationDetails } from "redux/locationsSlice";
import { ThresholdOverview, LocationBreadcrumb } from 'components';
import { sortbyName } from "utils";
import API from "api";
import './ThresholdDetailsPage.scss';
import { userSelector } from "redux/userSlice";
import { LoginRequired } from "components";

function ThresholdDetailsPage(props) {
    const intl = useIntl();
    const { locationId } = useParams();
    const thisLocation = useSelector(state => selectLocationDetails(state, locationId));
    const isLoggedIn = useSelector(userSelector).isSuccess;
    const [data, setData] = useState({});

    useEffect(() => {
        setData(null);
        API.thresholds.getLocationThresholds(locationId)
            .then(({ data }) => {
                if (data && data.length) {
                    const statusCodesLocation = thisLocation.statusCode.map(loc => Object.keys(loc)[0])
                    const locationThresholds = data.sort(sortbyName).map(threshold => {
                        if (statusCodesLocation.includes(Object.keys(threshold)[0])) return threshold
                        else return undefined;
                    }).filter(el => el !== undefined)
                    setData(locationThresholds);
                }
                else setData({});
            }).catch(error => {
                setData({ error: error });
            });
    }, [locationId, thisLocation]);

    const render = useCallback(() => {

        if (data !== null && data.length)
            return <Accordion className="accordion-tsi">
                {data.map(thresholdType =>
                    <Tile key={Object.keys(thresholdType)[0]}><AccordionItem title={intl.formatMessage({ id: Object.keys(thresholdType)[0] })} className="thresholds-accordion-tile">
                        <ThresholdOverview thresholds={thresholdType} location={thisLocation} />
                    </AccordionItem></Tile>)}
            </Accordion>
        else if (data === null) return <span>
            <Loader size="small" className="loading-data" />
            <Text className="loading-data" size="md">{intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })}</Text>
        </span>;
        else if (data.error) return <Tile><Text size="md">{intl.formatMessage({ id: 'THRESHOLDS.NO_DATA' })}</Text></Tile>;
        else return <Tile><Text size="md" className="no-thresholds">{intl.formatMessage({ id: 'THRESHOLDS.NO_DATA' })}</Text></Tile>;
    }, [data, intl, thisLocation]);

    return (
        <AppView
            theme='light'
            title={<LocationBreadcrumb locationId={locationId} />}
            key='thresholds-details-page'
            menu={props.menu()}
        >
            {isLoggedIn ? <Column>
                <Row className="row-tsi-content">
                    {render()}
                </Row>
            </Column> : <LoginRequired />}
        </AppView>
    );
}

export default ThresholdDetailsPage;