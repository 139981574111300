import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Tile, MultiSelect, Text, Pill, Checkbox, Icon, Tooltip } from '@react-gcc-eds/core';
import { DateRangeSelector } from 'components';
import { useSelector } from 'react-redux';
import { selectDataTypesConf, selectStatusCodesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { selectLegacyLocations } from 'redux/locationsSlice';
import './reportingFilters.scss';



function SampleReportingFilters(props) {
    const {
        multiLocation,
        selectedLocations,
        sidebarDispatch,
        filterHandler,
        sampleTypesSelected,
        qualityTypesSelected,
        pictureSample
    } = props;
    const [filterSidebar, setFilterSidebar] = useState(0);
    const intl = useIntl();

    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const samplingTypesConfig = useSelector(selectDataTypesConf);
    const statusCodesConfig = useSelector(selectStatusCodesConf);
    const locations = useSelector(selectLegacyLocations);

    useEffect(() => {
        const selectedFilters = sampleTypesSelected.length || qualityTypesSelected.length || pictureSample;
        sidebarDispatch('SET', {
            title: intl.formatMessage({ id: 'LOCATION.ADITIONAL_FILTERS' }),
            subtitle: selectedFilters ? <Pill onRemove={() => filterHandler()}><FormattedMessage id="LOCATION.FILTERS.CLEAR" /></Pill> : '',
            width: 'narrow',
            display: () => <div className="aditional-filter-items">
                <div className="filter-item">
                    <Text as="span" size="lg"> <FormattedMessage id="SAMPLE_LOG.TYPES" /> </Text>
                    <MultiSelect
                        items={samplingTypesConfig.map(samplingType => {
                            return {
                                title: intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${samplingType.value}` }),
                                value: samplingType.key
                            }
                        })}
                        onChange={(data) => filterHandler(data, 'samples')}
                        placeholder={`${sampleTypesSelected.length} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`}
                        selectedItems={sampleTypesSelected.map(el => {
                            return {
                                title: intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${samplingTypesConfig.find(sampleType => sampleType.key === el.value).value}` }),
                                value: el.value
                            }
                        })}
                        type="input-multi"
                    /></div>
                <div className="filter-item">
                    <Text as="span" size="lg"> <FormattedMessage id="CHARTS.QUALITY_TYPE" /> </Text>
                    <MultiSelect
                        items={statusCodesConfig.map(statusCode => {
                            return {
                                title: intl.formatMessage({ id: `QUALITY_LEVEL.TSI.${statusCode.value}` }),
                                value: statusCode.key
                            }
                        })}
                        onChange={(data) => filterHandler(data, 'quality')}
                        placeholder={`${qualityTypesSelected.length} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`}
                        selectedItems={qualityTypesSelected.map(el => {
                            return {
                                title: intl.formatMessage({ id: `QUALITY_LEVEL.TSI.${statusCodesConfig.find(statusCode => statusCode.key === el.value).value}` }),
                                value: el.value
                            }
                        })}
                        type="input-multi"
                    />
                </div>
                <div className="filter-item">
                    <Checkbox
                        checked={pictureSample}
                        onChange={() => filterHandler(!pictureSample, 'picture')}
                        text={intl.formatMessage({ id: 'INPUT.CHECKBOX.CONTAINS_PICTURE' })}
                    />
                </div>
            </div>
        });
    },
        [
            filterHandler,
            filterSidebar,
            samplingTypesConfig,
            sampleTypesSelected,
            resourceTypesConfig,
            statusCodesConfig,
            qualityTypesSelected,
            intl,
            pictureSample,
            sidebarDispatch
        ]
    );

    const selectedFilters = sampleTypesSelected.length || qualityTypesSelected.length || pictureSample;
    return <Row>
        <Tile
            className="filter-tile"
            title={
                <Row>
                    <DateRangeSelector />
                    {!!multiLocation &&
                        <div className='filter-item location-select'>
                            <Text as="span" size="lg"> <FormattedMessage id="LOCATION.ALL_LOCATIONS" />: </Text>
                            <MultiSelect
                                items={locations.map(location => {
                                    return {
                                        title: location.name,
                                        value: location._id
                                    }
                                })}
                                onChange={(data) => filterHandler(data, 'locations')}
                                placeholder={intl.formatMessage({ id: "LOCATION.SELECT" })}
                                selectedItems={selectedLocations}
                                type="input-multi"
                            />
                        </div>
                    }
                </Row>
            }
            actions={[
                <Tooltip
                    key="aditional-filters" position="left" type="pointer"
                    text={
                        `${intl.formatMessage({ id: "LOCATION.ADITIONAL_FILTERS" })}\n
                        ${sampleTypesSelected.length + qualityTypesSelected.length + pictureSample} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`
                    }
                >
                    <Pill onToggle={() => { setFilterSidebar(!filterSidebar); sidebarDispatch('OPEN') }}>
                        <Icon name={selectedFilters ? "filter" : "filter-off"} />
                        {intl.formatMessage({ id: "LOCATION.ADITIONAL_FILTERS" })}
                    </Pill>
                </Tooltip>
            ]}
        />
    </Row>;
}

export default SampleReportingFilters;
