import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initDone: true,
  theme: 'light',
  lang: 'hr'
};


export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    toggleTheme: (state) => {
      if (state.theme === 'light') {
        state.theme = 'dark';
      } else {
        state.theme = 'light';
      }
    },

    toggleLanguage: (state) => {
      if (state.lang === 'hr') {
        state.lang = 'en';
      } else {
        state.lang = 'hr';
      }
    },    

  },

});

export const { toggleTheme, toggleLanguage } = appSlice.actions;

// SELECTORS

/** Ensure that Redux is ready */
export const selectInitDone = ( state ) => {
  if (state.locations.status === 'failed' || state.configuration.status === 'failed' || state.user.isError) return 'failed';
  return (state.configuration.status === 'complete' && state.locations.status === 'complete' && state.user.isDone);
}



export default appSlice.reducer;
