import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  zoomLevel: process.env.REACT_APP_MAP_DEFAULT_ZOOM_LEVEL,
  zoomThreshold: process.env.REACT_APP_MAP_ZOOM_THRESHOLD,
  minZoom: process.env.REACT_APP_MAP_MIN_ZOOM_LEVEL,
  maxZoom: process.env.REACT_APP_MAP_MAX_ZOOM_LEVEL,
  maxBounds: [
    [process.env.REACT_APP_CROATIA_NE_BOUND_LAT, process.env.REACT_APP_CROATIA_NE_BOUND_LON],
    [process.env.REACT_APP_CROATIA_SW_BOUND_LAT, process.env.REACT_APP_CROATIA_SW_BOUND_LON],
  ],
  defaultX: process.env.REACT_APP_MAP_DEFAULT_X,
  defaultY: process.env.REACT_APP_MAP_DEFAULT_Y
};


export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // TODO: add map reducers if needed
  }
});

//export const { toggleTheme, toggleLanguage } = mapSlice.actions;

// SELECTORS

/** Ensure that Redux is ready */
export const selectMapProps = (state) => state.map;



export default mapSlice.reducer;
