import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tile, Accordion, AccordionItem, Icon, Button, Pill, Tooltip } from '@react-gcc-eds/core';
import { selectLocationFilters, toggleFilter, clearFilters } from 'redux/locationsSlice';
import { selectStatusCodesConf, selectStatusCodesTypesConf, selectTypologyConf } from 'redux/configurationSlice';
import { useIntl } from 'react-intl';
import './mapControl.scss';

const MapControl = (props) => {
    const { sidebarCallback } = props;
    const activeFilters = useSelector(selectLocationFilters);
    const statusCodes = useSelector(selectStatusCodesConf);
    const statusCodesTypes = useSelector(selectStatusCodesTypesConf);
    const locationTypes = useSelector(selectTypologyConf);
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        sidebarCallback('SET', {
            title: intl.formatMessage({ id: 'MAP_CONTROL.TILE_TITLE' }),
            width: 'narrow',
            display: () =>
                <div key="map-control" >
                    <Tile noMargin className='map-control-menu '>
                        <Accordion key="filters">
                            <AccordionItem
                                key="type-filters"
                                className='filter-title location-types'
                                defaultOpen
                                title={
                                    <div>
                                        <Icon name={activeFilters.locationTypes.length ? 'filter' : 'filter-off'} />
                                        <span> {intl.formatMessage({ id: 'MAP_CONTROL.GROUP_TITLE.TYPES' })} </span>
                                    </div>
                                }
                            >
                                {locationTypes.map((code, index) => {
                                    const isActive = activeFilters.locationTypes.includes(code.key) ? false : true;
                                    return <div key={"type-filter" + index} className={isActive ? 'item active' : 'item inactive'} onClick={() => dispatch(toggleFilter({ filterType: 'locationTypes', filterValue: code.key }))}>
                                        <Icon name='weather-humidity' base />
                                        <span>{intl.formatMessage({ id: 'MP_TYPES.' + code.value })}</span>
                                    </div>
                                })}
                            </AccordionItem>
                            {statusCodesTypes.map(statusType =>
                                <AccordionItem
                                    key={"status-filters"+statusType.key}
                                    className='filter-title status-codes'
                                    title={
                                        <div>
                                            <Icon name={activeFilters.statusCodes.length  && activeFilters.statusCodes.find(el=> el.key === statusType.key) ? 'filter' : 'filter-off'} />
                                            <span> {intl.formatMessage({ id: 'MAP_CONTROL.GROUP_TITLE.STATUS' })} </span>
                                            <span> {intl.formatMessage({ id: statusType.key })} </span>
                                        </div>
                                    }
                                >
                                    {statusCodes.map((code, index) => {
                                        const isActive = activeFilters.statusCodes.find(el => el.key === statusType.key && el.value === code.key) ? false : true;
                                        return <div key={"status-filter" + index} className={isActive ? 'item active' : 'item inactive'} onClick={() => dispatch(toggleFilter({ filterType: 'statusCodes', filterValue: { key: statusType.key, value: code.key } }))}>
                                            <div className={'status-type-' + code.value}>
                                                <Icon name='alarm-level5' base />
                                                <span>{intl.formatMessage({ id: 'QUALITY_LEVEL.TSI.' + code.value })}</span>
                                            </div>
                                        </div>
                                    })}
                                </AccordionItem>)}
                            </Accordion> 
                        {(activeFilters.statusCodes.length || activeFilters.locationTypes.length) ? <div className="clear-filters-pill text-sm">
                            <Pill onToggle={() => dispatch(clearFilters())}>
                                <Icon name='filter-clear' />{intl.formatMessage({ id: 'MAP_CONTROL.CLEAR_FILTERS' })}
                            </Pill>
                        </div> : null}
                    </Tile>
                </div>
        });
    }, [locationTypes, activeFilters, statusCodes, statusCodesTypes, sidebarCallback, intl, dispatch]);


    return (
        <div key="map-control" className='leaflet-top leaflet-right'>
            <div className='leaflet-control leaflet-bar sm-3 md-3 lg-3'>
                <Tooltip
                    key="filter-button-tooltip" position="left" type="pointer"
                    text={intl.formatMessage({ id: 'MAP_CONTROL.TILE_TITLE' })}>
                    <Button className='filter-button' iconName='filter' onClick={() => sidebarCallback('OPEN')} />
                </Tooltip>
            </div>
        </div>
    );
}


export default MapControl;