import { createSlice } from '@reduxjs/toolkit';
const { subDays, startOfDay, endOfDay } = require('date-fns');

export const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState: {
    dateFrom: startOfDay(subDays(new Date(), 7)).getTime(),
    dateTo: endOfDay(new Date()).getTime(),
    rangeName: null
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    /** Set date range by invididual dates  */
    setDatesRange: (state, action) => {
      // TODO: caluclate ranges here?
      state.dateFrom = action.payload.dateFrom
      state.dateTo = action.payload.dateTo
      state.rangeName = undefined
    },

    /** Set one of predefined ate ranges  */
    setNamedRange: (state, action) => {
      switch (action.payload) {
        case 'week':
          state.dateFrom = startOfDay(subDays(new Date(), 7)).getTime();
          state.dateTo = endOfDay(new Date()).getTime();
          state.rangeName = 'week'
          break;

        case 'month':
          state.dateFrom = startOfDay(subDays(new Date(), 28)).getTime();
          state.dateTo = endOfDay(new Date()).getTime();
          state.rangeName = 'month'
          break;

        case 'year':
          state.dateFrom = startOfDay(subDays(new Date(), 365)).getTime();
          state.dateTo = endOfDay(new Date()).getTime();
          state.rangeName = 'year'
          break;

        default:
          state.rangeName = undefined;
          break;
      }
    },

  },
});

export const { setDatesRange, setNamedRange } = dateRangeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDateRange = (state) => state.dateRange
export const getDateRange = (state) => {
  const startDate = state.dateRange.dateFrom;
  const endDate = state.dateRange.dateTo;
  const diffTime = Math.abs(endDate - startDate);
  const dateDifference = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return dateDifference;
}

export default dateRangeSlice.reducer;
