import React, { useState } from 'react';
import { Button, Loader, Text } from '@react-gcc-eds/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { userSelector } from 'redux/userSlice';
import { DataEntryReport, FileUploadButton } from 'components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { deepEqual, formatDate } from 'utils';
import * as Yup from "yup";

import API from 'api';
import './dataEntryForm.scss';

function DataEntryForm(props) {
  const { field } = props;
  const intl = useIntl();
  const resourceTypes = useSelector(selectResourcesTypesConf).filter(type => field.includes(type.key));
  const user = useSelector(userSelector);
  const resourceTypesKeys = resourceTypes.map(type => type.key);
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState([]);

  const initialValues = Object.fromEntries(new Map([...resourceTypesKeys.map(key => [key, '']), ['notes', '']]));

  const validationSchema = resourceTypes.map(resource => {
    return [
      resource.key,
      Yup.number().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
        .min(resource.range.min, `${intl.formatMessage({ id: 'FORM.MIN_ERROR' })} ${resource.range.min}`)
        .max(resource.range.max, `${intl.formatMessage({ id: 'FORM.MAX_ERROR' })} ${resource.range.max}`)
    ]
  });
  validationSchema.push([
    'notes',
    Yup.string().typeError(intl.formatMessage({ id: 'FORM.TYPE_ERROR' }))
      .max(256, intl.formatMessage({ id: 'FORM.NOTES_ERROR' }))
  ]);

  return <div>
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(Object.fromEntries(new Map(validationSchema)))}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        props.sidebarDispatch('SET', {
          title: intl.formatMessage({ id: 'APP.SENDING_DATA' }) + '...',
          subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
          display: () => <Loader size="large" />
        });
        API.measurements.postMeasurements(user.token, user.userId, props.locationId, values, files)
          .then(({ data }) => {
            setTimeout(() => {
              props.sidebarDispatch('SET', {
                title: intl.formatMessage({ id: 'REPORT.TITLE' }),
                subtitle: formatDate(new Date(), { getDetailedTime: true }),
                width: 'wide',
                display: () => <DataEntryReport data={data.data} status={data.statusCodes} notes={values.notes} collector={user.fullName} locationId={props.locationId} pictures={images} />
              });
            }, 1500);
          })
          .catch((error) => {
            let errorMsg;
            const response = error.response;
            if(response.status === 403) errorMsg = "ACCESS_DENIED";

            props.sidebarDispatch('SET', {
              title: <FormattedMessage id='REPORT.ERROR' />,
              subtitle: '',
              display: () => <div className='entry-form-error'>
                {errorMsg ? 
                  intl.formatMessage({id: "FORM." + errorMsg }) :
                  response.data.error
                }
                </div>
            });
          });
      }}
    >
      {(formikProps) => (
        <Form>
          {resourceTypes.map(resource =>
            <div key={resource._id} className="entry-block tile">
              <div className="content">
                <Text as="div" size="md" className="resource"><FormattedMessage id={resource.name} /></Text>
                <Field className="entry-field" name={resource.key} value={formikProps.values[resource.key]} placeholder={intl.formatMessage({ id: 'FORM.DATA_PLACEHOLDER' }) + ` (${resource.range.min} - ${resource.range.max})`} />
                <Text as="span" size="md" className="suffix">{resource.unit}&nbsp;</Text>
                {formikProps.touched[resource.key] && formikProps.values[resource.key] === '' ?
                  <Text as="div" size="sm" className="warning-message" >
                    <FormattedMessage id="FORM.NO_DATA_ERROR" />
                  </Text> : null}
                <Text as="div" size="sm" className="error-message" >
                  <ErrorMessage name={resource.key} />
                </Text>
              </div>
            </div>
          )}
          <div key='notes' className="entry-block tile">
            <div className="content">
              <Text as="div" size="md"><FormattedMessage id="FORM.NOTES_LABEL" /></Text>
              <Field name='notes'>{({ field }) => <textarea placeholder={intl.formatMessage({ id: "FORM.NOTES_PLACEHOLDER" })} {...field} type="text" className='notes' />}</Field>
              <Text as="div" size="sm" className="error-message" >
                <ErrorMessage name='notes' />
              </Text>
            </div>
          </div>

          <div key='picture' className="entry-block tile">
            <div className="content">
              <Field name='notes'>{({ field }) =>
                <FileUploadButton
                  {...field}
                  className='file-input'
                  text={intl.formatMessage({ id: 'FORM.PHOTO_UPLOAD' })}
                  altText={intl.formatMessage({ id: 'FORM.PHOTO_UPLOAD_DIF' })}
                  setFiles={setFiles}
                  setImages={setImages}
                />}
              </Field>
            </div>
          </div>

          <div key='actions' className="entry-block actions">
            <Button className="clear-button" onClick={() => { formikProps.setValues(initialValues); formikProps.resetForm() }}><FormattedMessage id="FORM.BUTTON_CLEAR" /></Button>
            <Button primary submit className="submit-button" disabled={deepEqual(initialValues, formikProps.values)}><FormattedMessage id="FORM.SUBMIT_DATA" /></Button>
          </div>
        </Form>
      )}
    </Formik >
  </div >
}

export default DataEntryForm;