import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownMenu } from '@react-gcc-eds/core';

function DropdownAggregation(props) {
    const { aggregation, setAggregation } = props;

    const dropdownValues = {
        month: {
            item: <FormattedMessage id="GROUPING.AGGREGATION.PER_MONTH" />,
            callback: () => setAggregation('m'),
        },
        week: {
            item: <FormattedMessage id="GROUPING.AGGREGATION.PER_WEEK" />,
            callback: () => setAggregation('w'),
        },
        day: {
            item: <FormattedMessage id="GROUPING.AGGREGATION.PER_DAY" />,
            callback: () => setAggregation('d'),
        },
        hour: {
            item: <FormattedMessage id="GROUPING.AGGREGATION.PER_HOUR" />,
            callback: () => setAggregation('h'),
        }
    }

    const dropdownItems = [];
    for (let index = 0; index < aggregation.length; index++) {
        const dropdownElement = aggregation[index];
        dropdownItems.push(dropdownValues[dropdownElement])
    }
    dropdownItems.push({ separator: true }, { item: <FormattedMessage id="GROUPING.AGGREGATION.NO_GROUP" />, callback: () => setAggregation(null) })

    return <DropdownMenu menuItems={dropdownItems}
        // label={<FormattedMessage id="LOCATION.AGGREGATION" />}
        onChange={(item) => item.callback()}
    />;
}

export default DropdownAggregation;