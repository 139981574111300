import React, { useReducer, useCallback, useEffect } from 'react';
import { useSettings } from '@react-gcc-eds/core';
import { useSelector } from 'react-redux';
import { selectMapProps } from 'redux/mapSlice';
import { MapContainer, TileLayer } from 'react-leaflet'
import { MapControl, MapElements, SideDrawer} from 'components';
import './mapPage.scss'

function MapPage(props) {
    const { locationId, position } = props;
    const mapProps = useSelector(selectMapProps);
    const settingsVisible = useSettings().settingsVisible;
    let center, zoom;
    if (position) {
        center = position;
        zoom = 13;
    } else {
        center = [mapProps.defaultX, mapProps.defaultY];
        zoom = mapProps.zoomLevel;
    }

    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });

    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);
    
    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);
    
    return (
        <>
            <SideDrawer className="map-filters" state={sidebarState} onClose={() => sidebarCallback('CLOSE')} />
            <MapContainer className='map-main-container'
                center={center}
                zoom={zoom}
                maxZoom={mapProps.maxZoom}
                minZoom={mapProps.minZoom}
                maxBounds={mapProps.maxBounds}
            >
                <TileLayer
                    key={"map-layer"}
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={
                        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    }
                />
                {!locationId && <MapControl key="map-control" position="topright" sidebarCallback={sidebarCallback} />}
                <MapElements key="map-elements" locationId={locationId} />
            </MapContainer>
        </>
    );
}

export default MapPage;
