import React from 'react';
import { SettingsNode } from '@react-gcc-eds/core'
import './UserSettings.scss';

function UserSettings() {

    return <SettingsNode title={" "} key="user-shortcuts">
    </SettingsNode>;
}

export default UserSettings;