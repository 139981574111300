import React from 'react';
import { Line } from 'react-chartjs-2';
import { Text, Tile, Loader } from '@react-gcc-eds/core';
import { paletteColors } from '../utils/chartsCollorPallete';
import defaultOptions from 'charts/utils/defaultOptions';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectDataTypesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { SamplingLogDetails } from 'components';
import { selectLocationDetails } from 'redux/locationsSlice';
import { deepMerge, formatDate } from 'utils';
import API from 'api';


const MultiAxisLineChart = (props) => {
  const location = useSelector((state) => selectLocationDetails(state, props.locationId));
  const resourceTypesConfig = useSelector(selectResourcesTypesConf);
  const sampleTypesConfig = useSelector(selectDataTypesConf);
  const intl = useIntl();
  const autoColor = paletteColors();
  // inject palette colors to each serie
  if (props.data && props.data.datasets) {
    props.data.datasets.forEach(dataset => {
      // get new color from palette
      const dsColor = autoColor();
      dataset.cubicInterpolationMode = 'monotone';
      dataset.tension = 0.4;
      dataset.backgroundColor = dsColor;
      dataset.borderColor = dsColor;
      dataset.pointStyle = 'circle';
    })
  }


  const propKey = props.data.datasets[0].data ? props.data.datasets[0].data.length : 0;

  const chartOptions = {
    pointRadius: 0,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    onClick: (event, elements) => {
      let date, displayArray = [];
      const point = elements[0];
      if (point === undefined) return;
      const { datasetIndex, index } = point;
      const dataset = props.data.datasets[datasetIndex];
      const pointElement = dataset.data[index];

      if (pointElement.id) {
        props.sidebarDispatch('SET', {
          title: intl.formatMessage({ id: 'LOCATION.LOADING_DATA' }) + '...',
          subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
          display: () => <Loader size="large" />,
          width: 'wide'
        });
        API.samplingLogs.getSamplingDetails(pointElement.id)
          .then(({ data }) => {
            const dataArray = [];
            const sampleType = sampleTypesConfig.find(el => el.key === data.samplingType).value;
            for (let index = 0; index < data.measurements.length; index++) {
              const displayData = data.measurements[index];
              const resource = resourceTypesConfig.find(el => el.key === displayData.resourceTypeKey);
              dataArray.push({
                id: resource.key,
                resource: intl.formatMessage({ id: resource.name }),
                value: displayData.value,
                unit: resource.unit
              });
            }
            setTimeout(() =>
              props.sidebarDispatch('SET', {
                title: intl.formatMessage({ id: "LOCATION.SAMPLE_LOG" }),
                subtitle: formatDate(pointElement.x, { getDetailedTime: true }),
                display: () => <SamplingLogDetails locationName={location.name} locationId={location._id} logId={data._id} sampleType={sampleType} values={dataArray.filter(el => !isNaN(parseFloat(el.value)))} notes={data.notes} pictures={data.pictures} />,
                width: 'wide'
              }), 100);
          });
      }

      else if (!pointElement.id) {
        props.sidebarDispatch('SET', {
          title: intl.formatMessage({ id: 'LOCATION.LOADING_DATA' }) + '...',
          subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
          display: () => <Loader size="large" />
        });
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const { datasetIndex, index } = element;
          const dataset = props.data.datasets[datasetIndex];

          const resource = resourceTypesConfig.find(el => el.key === dataset.key);
          const pointData = dataset.data[index];

          date = pointData.x;
          displayArray.push(
            <div className="resource-block" key={resource._id.label}>
              <div className="content">
                <Text as="div" size="md" className="left label">{intl.formatMessage({ id: resource.name })}</Text>
                <div className="value">
                  <Text as="span" size="xl">{parseFloat(pointData.y).toFixed(2)} </Text>
                  <Text as="span" size="lg" >{resource.unit}</Text>
                </div>
              </div>
            </div>
          );
        }

        setTimeout(() =>
          props.sidebarDispatch('SET', {
            title: intl.formatMessage({ id: "LOCATION.MEASUREMENT_LOG" }),
            subtitle: formatDate(date, { getDetailedTime: true }),
            display: () => <Tile>{displayArray}</Tile>
          }), 300);
      }
      else return;
      props.sidebarDispatch('OPEN');
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            const title = formatDate(context[0].label, { getDetailedTime: true });
            return title;
          },
          label: function (context) {
            const resource = resourceTypesConfig.find(type => type.key === context.dataset.key);
            const label = ` ${intl.formatMessage({ id: resource.name })}`;
            const value = ` ${context.formattedValue}`;
            const unit = resource.unit;
            return [label, [value, unit].join(' ')];
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          callback: function (tick) {
            let label = '';
            if (props.data.datasets[0] && props.data.datasets[0].data[tick]) label = formatDate(props.data.datasets[0].data[tick].x, { getGraphTime: true });
            if (props.data.datasets[1] && props.data.datasets[1].data[tick]) label = formatDate(props.data.datasets[1].data[tick].x, { getGraphTime: true });
            return label;
          }
        }
      }
    }
  };

  const options = deepMerge(deepMerge({}, props.options), defaultOptions);
  return (
    <Line key={propKey} data={props.data} options={deepMerge(options, chartOptions)} ref={props.chartRef} />
  );
}

export default MultiAxisLineChart;