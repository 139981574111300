import instance, { setAuthHeader } from './axiosEnv';

const getAllLocationData = () => {
    return instance.get('/locations');
}

const getLocationMetas = (location_id) => {
    return instance.get('/locations/' + location_id + '/metas');
}

const createFormData_helper = (newLocationObject, imageFile) => {
    const formData = new FormData();
    for (const key in newLocationObject) {
        formData.append(key, JSON.stringify(newLocationObject[key]));
    }
    if (imageFile) for (const file of imageFile) {
        formData.append('pictureFile', file, file.name);
    }

    return formData;
}

const updateLocation = (token, location_id, newLocationObject, imageFile) => {
    const config = setAuthHeader(token);
    return instance.put('/locations/' + location_id, createFormData_helper(newLocationObject, imageFile), config);
}

const createLocation = (token, newLocationObject, parentLocationId, imageFile) => {
    const config = setAuthHeader(token);
    if (parentLocationId) {
        return instance.post('/locations/' + parentLocationId, createFormData_helper(newLocationObject, imageFile), config);
    }
    else return instance.post('/locations', createFormData_helper(newLocationObject, imageFile), config);
}

const locationsAPI = {
    // locations + measure points
    getAllLocationData,
    // location's metas property
    getLocationMetas,
    // update location info
    updateLocation,
    // create new location
    createLocation,
}

export default locationsAPI;
