import React from "react";
import { useParams } from "react-router-dom";
import { Row, Tile } from '@react-gcc-eds/core'
import {
    DateRangeSelector,
    MeasurementsTrendChart,
    SamplingLogQualityDistributionPie,
    SamplingLogTypeDistributionPie,
    SamplingLogsSamplesCount,
    LatestMeasurements,
    LocationDetailsTab,
    QualityStatusKPI,
    ReportingButtons,
    SamplingButtons,
    DropdownAggregation
} from 'components';
import { useSelector } from 'react-redux';
import { selectLocationDetails } from "redux/locationsSlice";
import { FormattedMessage } from 'react-intl';

function MeasurePointDetails(props) {
    const { aggregation, aggregationCallbacks, sidebarDispatch } = props;
    // get id from path
    const { locationId } = useParams();
    const location = useSelector(state => selectLocationDetails(state, locationId));

    const services = location.resourceTypes.filter(resKey => resKey > 1000 && resKey < 2000);
    const sensors = location.resourceTypes.filter(resKey => resKey > 2000 && resKey < 3000);
    const field = location.resourceTypes.filter(resKey => resKey > 3000 && resKey < 4000);
    const microbiological = location.resourceTypes.filter(resKey => resKey > 6000 && resKey < 7000);
    const chemical = location.resourceTypes.filter(resKey => resKey > 4000 && resKey < 5000);
    const indicator = location.resourceTypes.filter(resKey => resKey > 5000 && resKey < 5500);
    const water_indicator = location.resourceTypes.filter(resKey => resKey > 5500 && resKey < 6000);

    return (
        <div name={'locationDetailsComplex_' + locationId} className='location-details measure-point-layout'>
            <Row>
                <Tile lg={7} md={7} sm={12} className='latest-measurements-tile'>
                    <Row>
                        <QualityStatusKPI locationId={locationId} />
                    </Row>
                    <Row>
                        <LatestMeasurements locationId={locationId} />
                    </Row>
                </Tile>
                <LocationDetailsTab sidebarDispatch={sidebarDispatch} key={locationId} location={location} />
            </Row>

            <Row>
                <Tile lg={7} md={12} sm={12} className='date-range-selector-tile'>
                    <DateRangeSelector />
                </Tile>
                <Tile lg={5} md={6} sm={12} className='reporting-buttons-tile'>
                    <ReportingButtons />
                    <SamplingButtons
                        sidebarDispatch={sidebarDispatch}
                        key={locationId}
                    />
                </Tile>
            </Row>

            <Row>
                {sensors.length ? <Tile title={<FormattedMessage id="CHARTS.SENSOR_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.sensorAggregation.group} />}
                    canMaximize={!!services.length} lg={services.length ? 6 : 12} md={12} sm={12}
                    className='sensor-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='sensor-trend-chart-key'
                        setAggregation={aggregationCallbacks.setSensorAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={sensors}
                        aggregation={aggregation.sensorAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}

                {services.length ? <Tile title={<FormattedMessage id={services.length > 2 ? "CHARTS.WEATHER_DATA" : "CHARTS.TEMPERATURE_AND_PRECIPITATION"} />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.weatherAggregation.group} />}
                    bottom={<span className="data-attribution text-sm"><FormattedMessage id="API.ATTRIBUTION"/></span>}
                    canMaximize={!!sensors.length} lg={sensors.length ? 6 : 12} md={12} sm={12}
                    className='weather-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='weather-trend-chart-key'
                        setAggregation={aggregationCallbacks.setWeatherAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={services}
                        aggregation={aggregation.weatherAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                {field.length ? <Tile title={<FormattedMessage id="CHARTS.FIELD_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.fieldAggregation.group} />}
                    lg={12} md={12} sm={12}
                    className='field-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='field-trend-chart-key'
                        setAggregation={aggregationCallbacks.setFieldAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={field}
                        aggregation={aggregation.fieldAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                {microbiological.length ? <Tile title={<FormattedMessage id="CHARTS.MICROBIOLOGICAL_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.microbiologicalAggregation.group} />}
                    canMaximize={!!chemical.length} lg={chemical.length ? 6 : 12} md={12} sm={12}
                    className='microbiological-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='microbiological-trend-chart-key'
                        setAggregation={aggregationCallbacks.setMicrobiologicalAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={microbiological}
                        aggregation={aggregation.microbiologicalAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
                {chemical.length ? <Tile title={<FormattedMessage id="CHARTS.CHEMICAL_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.chemicalAggregation.group} />}
                    canMaximize={!!microbiological.length} lg={microbiological.length ? 6 : 12} md={12} sm={12}
                    className='chemical-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='chemical-trend-chart-key'
                        setAggregation={aggregationCallbacks.setChemicalAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={chemical}
                        aggregation={aggregation.chemicalAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                {water_indicator.length ? <Tile title={<FormattedMessage id="CHARTS.WATER_INDICATOR_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.indicatorAggregation.group} />}
                    canMaximize={!!indicator.length} lg={indicator.length ? 6 : 12} md={12} sm={12}
                    className='waterindicator-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='waterindicator-trend-chart-key'
                        setAggregation={aggregationCallbacks.setIndicatorAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={water_indicator}
                        aggregation={aggregation.indicatorAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}

                {indicator.length ? <Tile title={<FormattedMessage id="CHARTS.INDICATOR_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.indicatorAggregation.group} />}
                    canMaximize={!!water_indicator.length} lg={water_indicator.length ? 6 : 12} md={12} sm={12}
                    className='indicator-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='indicator-trend-chart-key'
                        setAggregation={aggregationCallbacks.setIndicatorAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={indicator}
                        aggregation={aggregation.indicatorAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                <Tile title={<FormattedMessage id="CHARTS.NO_OF_MEASUREMENTS" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.numberAggregation.group} />}
                    canMaximize lg={6} md={12} sm={12}
                    className='sampling-logs-type-distribution-bars-tile'
                    dropdownMenu={[<DropdownAggregation key='sampling-logs-type-key'
                        setAggregation={aggregationCallbacks.setNumberMeasurementsAggregationCallback}
                        aggregation={['month', 'week', 'day']}
                    />]}
                >
                    <SamplingLogsSamplesCount locations={[locationId]} aggregation={aggregation.numberAggregation.time} />
                </Tile>

                <Tile lg={3} md={12} sm={12}
                    title={<FormattedMessage id="CHARTS.TYPE_OF_MEASUREMENTS" />}
                    className='sampling-logs-type-distribution-pie-tile' >
                    <SamplingLogTypeDistributionPie locations={[locationId]} />
                </Tile>

                <Tile lg={3} md={12} sm={12}
                    title={<FormattedMessage id="CHARTS.AVERAGE_QUALITY" />}
                    className='sampling-logs-quality-distribution-pie-tile' >
                    <SamplingLogQualityDistributionPie locations={[locationId]} />
                </Tile>
            </Row>

        </div >
    );
}

export default MeasurePointDetails;
