import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectLocationDetails } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import { AppView, Dropdown, Loader, Text, Icon, Tile } from "@react-gcc-eds/core";
import { LocationBreadcrumb, ContactsTable } from "components";
import { exportData } from "utils";
import API from "api";


function ContactsDetailsPage(props) {
    const { locationId } = useParams();
    const location = useSelector(state => selectLocationDetails(state, locationId))

    const { token, isSuccess } = useSelector(userSelector);
    const intl = useIntl();
    const [contactsData, setContactsData] = useState(null);

    useEffect(() => {
        setContactsData(null);
        if (token !== null) {
            API.keycloak.getUserGroups(token, location.name).then((res) => {
                if (res.data.length) {
                    const data = res.data.map(el => {
                        return {
                            locationName: location.name,
                            username: el.username || "",
                            firstName: el.firstName || "",
                            lastName: el.lastName || "",
                            email: el.email || ""
                        }
                    }
                    );
                    setContactsData(data);
                }
                else setContactsData([]);
            }).catch((error) => { setContactsData({ error }); });
        }
    }, [token, location.name]);

    const tableHeaders = [
        intl.formatMessage({ id: 'LOCATION.LOCATION' }),
        intl.formatMessage({ id: 'CONTACTS_USERNAME' }),
        intl.formatMessage({ id: 'CONTACTS_FIRST_NAME' }),
        intl.formatMessage({ id: 'CONTACTS_LAST_NAME' }),
        intl.formatMessage({ id: 'CONTACTS_EMAIL' })
    ];
    const tableData = contactsData !== null && contactsData.length ?
        contactsData.map(el => [
            el.locationName,
            el.username,
            el.firstName,
            el.lastName,
            el.email
        ]) : null;

    const render = useCallback(() => {

        if(!isSuccess) return null;

        if (contactsData !== null && contactsData.length) {
            return <ContactsTable contactsData={contactsData} />
        }
        else if (contactsData === null) {
            return <span>
                <Loader size="small" className="loading-data" />
                <Text className="loading-data" size="md"> {intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })} </Text>
            </span>;
        }
        else if (contactsData.error && contactsData.error.message.indexOf(403) > 0) return <Text size="md"> {intl.formatMessage({ id: 'CONTACTS.ERROR_FORBIDDEN' })} </Text>
        else if (contactsData.error) return <Text size="md">{location.name + ": "} {intl.formatMessage({ id: 'CONTACTS.NO_GROUP' })} </Text>
        else return <Text size="md"> {intl.formatMessage({ id: "CONTACTS.NO_DATA" })} </Text>


    }, [contactsData, intl, location.name, isSuccess]);

    return (
        <AppView
            key="contacts-page"
            menu={props.menu()}
            title={<LocationBreadcrumb locationId={locationId} />}
            actions={[tableData !== null && !!tableData.length ? [
                <Dropdown
                    key="export-dropdown"
                    items={[
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_JSON' }), value: { callback: () => exportData({ getJsonData: true }, location.name, intl.formatMessage({ id: 'CONTACTS' }), tableData), name: 'Export JSON' } },
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_CSV' }), value: { callback: () => exportData({ getCsvData: true }, location.name, intl.formatMessage({ id: 'CONTACTS' }), tableData, tableHeaders), name: 'Export CSV' } },
                        {
                            title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_PDF' }),
                            value: {
                                callback: () => {
                                    exportData({ getPdfData: true }, location.name, intl.formatMessage({ id: 'CONTACTS' }), tableData, tableHeaders)
                                }, name: 'Export PDF'
                            }
                        }
                    ]}
                    label={[<Icon key="icon" name="download-save" />, intl.formatMessage({ id: 'BUTTON.EXPORT' })]}
                    onItemClick={(item) => item.value.callback()}
                />
            ] : null]}

        >
            <Tile>
                {render()}
            </Tile>

        </AppView>
    )
}



export default ContactsDetailsPage;