import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
  filters: {
    // filter for quality types (key in array are FILTERED_OUT!)
    statusCodes: [],
    // filter for location/measure point types (key in array are FILTERED_OUT!)
    locationTypes: []
  }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchLocations = createAsyncThunk('locations/fetchLocations', async () => {
  const response = await API.locations.getAllLocationData();
  // The value we return becomes the `fulfilled` action payload
  return response.data;
}
);

export const counterSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    /** Set date range by invididual dates  */
    toggleFilter: (state, action) => {
      const { filterType, filterValue } = action.payload;
      if(typeof filterValue !== 'object') {
        // find value in filter arrays
        const index = state.filters[filterType].indexOf(filterValue);
        // if index is found - remove it, if not found - add it
        (index > -1) ? state.filters[filterType].splice(index, 1) : state.filters[filterType].push(filterValue);
      }
      else {
        const foundIndex = state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value);
        (foundIndex > -1) ? 
        state.filters[filterType].splice(state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value), 1) 
        : state.filters[filterType].push(filterValue)
      }
    },
    clearFilters: (state, action) => {
      const { type } = action.payload ? action.payload : { };
      if (!type) state.filters = initialState.filters;
      else state.filters[type] = initialState.filters[type]
      return state;
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'complete';
        const response = action.payload;
        let locations = [];
        for (let location in response) {
          locations.push(setConnections(response[location]));
        }
        state.data = locations;
      });
  },
});

export const { toggleFilter, clearFilters } = counterSlice.actions;

// Selectors

// get locations data root element (parent locations)
export const selectLocations = (state, locationId) => {
  return state.locations.data.filter(location => location.parentLocation === undefined);
}

export const selectMeasurePoints = (state, locationId) => {
  return state.locations.data.filter(location => location.parentLocation !== undefined);
}

// get locations and measure points data
export const selectAllLocations = (state) => state.locations.data;


// get locations and measure points data for specific location group
export const selectAllLogoLocations = (state) => state.locations.data.filter(location => location.logo !== undefined);
export const selectLegacyLocations = (state) => state.locations.data.filter(el => !el.tb_sensors || !el.tb_sensors.length);
export const selectTBLocations = (state) => state.locations.data.filter(el => !!el.tb_sensors && !!el.tb_sensors.length);

// get locations data filters
export const selectLocationFilters = (state) => state.locations.filters;

// get location matching active filters (beware: it is a negative match - locations with keys present in filter array will be ommited)
export const selectFilteredLocations = (state) => (
  state.locations.data.filter(location => !state.locations.filters.locationTypes.includes(location.type) && !state.locations.filters.statusCodes.includes(location.statusCode))
);

// get single location from it's ID
export const selectLocationDetails = (state, locationId) => {
  return state.locations.data.find(location => location._id === locationId)
};

// find parent location (if exist) of location with provided ID
export const selectLocationParent = (state, locationId) => {
  //return state.locations.data.find( location => location.measurePoints && location.measurePoints.length);
  return state.locations.data.find(location => {
    if (location.measurePoints && location.measurePoints.length) {
      return location.measurePoints.some(mp => mp._id === locationId);
    }
    return false;
  });
};

export default counterSlice.reducer;


function setConnections(location) {
  let connections = [];
  if (location.measurePoints.length > 0) {
    const measurePointsStatusCode = Math.min.apply(null, Array.from(location.measurePoints, (location) => location.statusCode));
    for (var i in location.measurePoints) {
      connections.push({
        key: Math.floor(Math.random() * 100000),
        filtersData: {
          type: location.measurePoints[i].type,
          parentType: location.type,
          statusCode: location.measurePoints[i].statusCode,
          parentStatusCode: measurePointsStatusCode
        },
        positions: [
          location.position,
          location.measurePoints[i].position
        ]
      });
    };
  }
  return { ...location, connections: connections };
}
