import { createBrowserHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit';

// slices
import configurationReducer from './configurationSlice';
import mapReducer from './mapSlice';
import userReducer from './userSlice';
import locationsReducer from './locationsSlice';
import dateRangeReducer from './dateRangeSlice';




export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    //router: connectRouter(history),
    user: userReducer,
    configuration: configurationReducer,
    map: mapReducer,
    locations: locationsReducer,
    dateRange: dateRangeReducer,
  },
  devTools: false,
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
});

