import React, { useCallback, useEffect, useState } from 'react';
import { Tile, Dropdown, Text, Loader, Icon, Tooltip } from '@react-gcc-eds/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf, selectDataTypesConf } from 'redux/configurationSlice';
import { selectLocationDetails } from 'redux/locationsSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { formatDate, deepEqual, exportData } from 'utils';
import MeasurementsTable from './MeasurementsTable';
import { useHistory } from 'react-router-dom';
import './table.scss';


import API from 'api';

function MeasurementsTableContainer(props) {
    const { multiLocation, locationId, sampleTypes, resourceTypes, sidebarDispatch } = props;
    const location = useSelector((state) => selectLocationDetails(state, locationId));
    const selectedDateRange = useSelector(selectDateRange);
    const measurementsSampleTypes = useSelector(selectDataTypesConf);
    const resourcesTypes = useSelector(selectResourcesTypesConf);
    const intl = useIntl();
    const history = useHistory()
    const [measurementData, setMeasurementData] = useState(null);
    const printPDF = Boolean(true);

    useEffect(() => {
        setMeasurementData(null);
        API.measurements.getMeasurementsHistory([location._id], resourceTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, sampleTypes)
            .then(items => {
                if (items.data) {
                    const data = items.data.map(collection => {
                        const sampleType = measurementsSampleTypes.find(el => el.key === collection.samplingType).value;
                        const resourceType = resourcesTypes.find(el => el.key === collection.resourceTypeKey).name;
                        return {
                            key: collection._id,
                            samplingLogId: collection.samplingLogId,
                            locationName: location.name,
                            date: formatDate(collection.timestamp, { getDetailedTime: true }),
                            sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                            measurementType: intl.formatMessage({ id: resourceType }),
                            value: parseFloat(collection.value).toFixed(2).toString()
                        }
                    });
                    setMeasurementData(data)
                }
                else setMeasurementData([]);
            }).catch(error => {
                setMeasurementData({ error });
            });
    }, [location, resourceTypes, resourcesTypes, sampleTypes, selectedDateRange, measurementsSampleTypes, intl]);

    const render = useCallback(() => {
        if (measurementData === null) return <span>
            <Loader size="small" className="loading-data" />
            <Text className="loading-data" size="md">{intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })}</Text>
        </span>;
        if (measurementData !== null && measurementData.error) return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>;
        else if (measurementData !== null && measurementData.length) return <MeasurementsTable multiLocation={multiLocation} measurementData={measurementData} sidebarDispatch={sidebarDispatch} />;
        else return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA_TIME_PERIOD' })}</Text>;
    }, [measurementData, sidebarDispatch, multiLocation, intl]);


    const tableHeaders = [
        intl.formatMessage({ id: 'LOCATION.LOCATION' }),
        intl.formatMessage({ id: 'DATE' }),
        intl.formatMessage({ id: 'LOCATION.SAMPLES' }),
        intl.formatMessage({ id: 'LOCATION.MEASUREMENT_TYPE' }),
        intl.formatMessage({ id: 'LOCATION.VALUE' }),
    ];
    const tableData = measurementData !== null && measurementData.length ? measurementData.map(el => [el.locationName, el.date, el.sampleType, el.measurementType, el.value]) : null;
    return (
        <Tile
            title={!multiLocation ? <FormattedMessage id="LOCATION.DATA_TABLE" /> : location.name}
            className='export-table-tile'
            lg={props.tileWidth ? props.tileWidth : 12}
            actions={!multiLocation && tableData !== null && !!tableData.length ? [
                <Dropdown
                    key="export-dropdown"
                    items={[
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_JSON' }), value: { callback: () => exportData({ getJsonData: true }, location.name, intl.formatMessage({ id: 'LOCATION.MEASUREMENTS' }), tableData), name: 'Export JSON' } },
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_CSV' }), value: { callback: () => exportData({ getCsvData: true }, location.name, intl.formatMessage({ id: 'LOCATION.MEASUREMENTS' }), tableData, tableHeaders), name: 'Export CSV' } },
                        {
                            title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_PDF' }), value: {
                                callback: () => {
                                    const chart = props.chartRef.current;
                                    const base64Image = chart ? chart.toBase64Image() : undefined;
                                    exportData({ getPdfData: true }, location.name, intl.formatMessage({ id: 'LOCATION.MEASUREMENTS' }), tableData, tableHeaders, base64Image)
                                }, name: 'Export PDF'
                            }
                        },
                        {
                            title: intl.formatMessage({ id: 'DROPDOWN_MENU.PRINT_PDF' }), value: {
                                callback: () => {
                                    const chart = props.chartRef.current;
                                    const base64Image = chart ? chart.toBase64Image() : undefined;
                                    exportData({ getPdfData: true }, location.name, intl.formatMessage({ id: 'LOCATION.MEASUREMENTS' }), tableData, tableHeaders, base64Image, printPDF)
                                }, name: 'Print PDF'
                            }
                        }
                    ]}
                    label={[<Icon key="icon" name="download-save" />, intl.formatMessage({ id: 'BUTTON.EXPORT' })]}
                    onItemClick={(item) => item.value.callback()}
                />
            ] : multiLocation ? [
                <Tooltip key="details" type="pointer" text={intl.formatMessage({ id: "LOCATION.DETAILS_PAGE" })} position="left" >
                    <Icon name="info" onClick={() => history.push('/location/' + location._id)} />
                </Tooltip>
            ] : null}
        >
            {render()}
        </Tile>
    );
};

export default React.memo(MeasurementsTableContainer, (prevProps, nextProps) => deepEqual(prevProps, nextProps, ['location', 'resourceTypes', 'sampleTypes', 'selectedDateRange']));