import React, { useEffect, useReducer, useCallback } from 'react';
import { AppView, Row, Tile, Text, useSettings } from '@react-gcc-eds/core'
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LocationBreadcrumb, SamplesTable, SideDrawer } from 'components';
import { formatDate } from 'utils';
import './historyReportPage.scss';
import { userSelector } from 'redux/userSlice';
import { LoginRequired } from 'components';

function HistoryReportPage(props) {
    const intl = useIntl();
    const history = useHistory();
    const locationId = useParams().locationId;
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const isLoggedIn = useSelector(userSelector).isSuccess;
    const settingsVisible = useSettings().settingsVisible;

    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);


    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    const dataArray = history.location.state ? history.location.state.data : undefined;
    if (!dataArray) {
        history.push('/location/' + locationId);
        return null;
    }

    else {
        const tableRows = dataArray.errorArrays.array;
        const samplingLogs = dataArray.samplingLogsArray.map(collection => {
            return {
                locationName: collection.sampledObject,
                date: formatDate(collection.timestamp, { getDetailedTime: true }),
                sampleType: collection.samplingType,
                sampleNotes: collection.notes ? collection.notes : '',
                data: collection.data.map(el => {
                    const resource = resourceTypesConf.find(re => re.key === el.resourceTypeKey);
                    return { resource, value: el.value }
                }),
                errors: collection.errors
            }
        });

        return (
            <AppView title={<LocationBreadcrumb locationId={locationId} />}
                menu={props.menu()}
                className="report-page"
            >
                {isLoggedIn ? <>
                    <SideDrawer
                        state={sidebarState}
                        onClose={() => sidebarCallback('CLOSE')}
                    />
                    <Row>
                        {dataArray.samplingLogsArray.length ? <Tile title={intl.formatMessage({ id: "HISTORY_REPORT.VALID_ROWS" })}>
                            <SamplesTable hideQuality sampleData={samplingLogs} sidebarDispatch={sidebarCallback} />
                        </Tile> : null}
                        <Tile title={intl.formatMessage({ id: "HISTORY_REPORT.INVALID_ROWS" })}>
                            <div className="invalid-rows">
                                {tableRows.map((row, index) => {
                                    return <div className='block' key={index}>
                                        {Object.keys(row).map((key, index) => {
                                            const title = key.includes("__EMPTY") ? " " : key;
                                            return <span className='column' key={title + index}>
                                                <Text className='title' as="div" size="sm"> {title}</Text>
                                                <Text className='value' as="div" size="lg">{row[key]} </Text>
                                            </span>;
                                        })}
                                    </div>
                                })}
                            </div>
                        </Tile>
                    </Row>
                </> : <LoginRequired />}
            </AppView>
        );
    }
}

export default HistoryReportPage;
