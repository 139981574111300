import React, { useEffect, useState, useCallback } from 'react';
import { Loader, Text } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDateRange } from 'redux/dateRangeSlice';
import { StackedBarChart } from 'charts'
import { deepEqual } from 'utils';
import { selectDataTypesConf } from "redux/configurationSlice";


import API from 'api';


const SamplingLogsSamplesCount = (props) => {
  const { locations, sampleTypes, qualityTypes, aggregation } = props;
  const selectedDateRange = useSelector(selectDateRange);
  const samplingTypesConfig = useSelector(selectDataTypesConf);
  const intl = useIntl();

  const [data, setData] = useState(null);

  const convertData = useCallback((apiData) => {
    const samplingTypes = samplingTypesConfig.map(type => 'TYPE_' + type.value);
    const chartData = {
      labels: [],
      datasets: samplingTypes.map(type => (
        {
          stack: 'stack0',
          label: intl.formatMessage({ id: 'SAMPLE_LOG.' + type }),
          data: []
        }
      ))
    }
    apiData.forEach(row => {
      // store date as chart label
      chartData.labels.push(row.date);
      samplingTypes.forEach((type, index) => chartData.datasets[index].data.push(row.values[type]));
    })

    setData({ datasets: chartData.datasets, labels: chartData.labels });
  }, [setData, intl, samplingTypesConfig]);

  useEffect(() => {
    setData(null);
    //date_from = null, date_to = null, sample_types = '', sampling_object = ''
    API.samplingLogs.getSamplingNumbers(locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes, aggregation)
      .then(items => {
        if (items.data && items.data.length) {
          // store data in state
          convertData(items.data);
        }
        else setData({});
      }).catch(error => {
        setData({ error: error });
      });
  }, [locations, selectedDateRange, sampleTypes, qualityTypes, aggregation, convertData]);

  const render = useCallback(() => {
    if (data !== null && data.datasets) return <StackedBarChart name={"sampleCountBarChart_" + locations[0]} data={data} chartRef={props.chartRef}/>;
    else if (data === null) return <Loader size="small" />;
    else if (data.error) return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>;
    else return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA_TIME_PERIOD' })}</Text>
  }, [locations, data, intl, props.chartRef]);

  return <div>
    {render()}
  </div>
}

export default React.memo(SamplingLogsSamplesCount, (prevProps, nextProps) => deepEqual(prevProps, nextProps, ['locations', 'sampleTypes', 'qualityTypes', 'selectedDateRange', 'aggregation']));