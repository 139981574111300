import React, { useReducer, useCallback, useState, useEffect } from 'react';
import { AppView, EmptyState, useSettings, Button, Tooltip } from '@react-gcc-eds/core'
import { LocationBreadcrumb, SideDrawer } from 'components';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { selectLocationDetails, selectLocationParent } from 'redux/locationsSlice';
import { useIntl } from 'react-intl';
import "./LocationDetailsPage.scss"

// specific templates - subcomponents
import { ComplexLocationDetails, HybridLocation, MeasurePointDetails, TBLocation } from './layouts';
import { useHistory } from 'react-router-dom';
import { userSelector } from 'redux/userSlice';
import { LoginRequired } from 'components';

const getAggregationHelper = (aggr) => {
    if (!aggr || aggr === null) {
        return {
            group: 'NO_GROUP',
            time: null
        }
    }
    if (aggr === 'h') {
        return {
            group: 'PER_HOUR',
            time: 'h'
        }
    }
    if (aggr === 'd') {
        return {
            group: 'PER_DAY',
            time: 'd'
        }
    }
    if (aggr === 'w') {
        return {
            group: 'PER_WEEK',
            time: 'w'
        }
    }
    if (aggr === 'm') {
        return {
            group: 'PER_MONTH',
            time: 'm'
        }
    }
}

function LocationDetailsPage(props) {
    const intl = useIntl();
    const settingsVisible = useSettings().settingsVisible;
    const history = useHistory();
    const isLoggedIn = useSelector(userSelector).isSuccess;

    const [aggregation, setNewAggregation] = useState({
        sensorAggregation: getAggregationHelper(),
        fieldAggregation: getAggregationHelper(),
        numberAggregation: getAggregationHelper(),
        weatherAggregation: getAggregationHelper(),
        chemicalAggregation: getAggregationHelper(),
        indicatorAggregation: getAggregationHelper(),
        microbiologicalAggregation: getAggregationHelper()
    });

    const aggregationCallbacks = {
        setSensorAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, sensorAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setWeatherAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, weatherAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setFieldAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, fieldAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setNumberMeasurementsAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, numberAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setChemicalAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, chemicalAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setIndicatorAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, indicatorAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),

        setMicrobiologicalAggregationCallback: useCallback((aggr) => {
            setNewAggregation({ ...aggregation, microbiologicalAggregation: getAggregationHelper(aggr) })
        }, [setNewAggregation, aggregation]),
    }
    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);

    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    // get id from path
    const { locationId } = useParams();

    // get location details
    const thisLocation = useSelector(state => selectLocationDetails(state, locationId));

    // get parent id (if exists)
    const parentLocation = useSelector(state => selectLocationParent(state, locationId));

    // get route to redirect on contacts page
    const contactsOnClick = useCallback(() => history.push('/contacts/' + locationId), [history, locationId]);

    // select which template to use bases ond location properties
    let locationDetailsTemplate;

    // location was not fetched and stored in redux - return error
    if (thisLocation === undefined) locationDetailsTemplate = <EmptyState message={intl.formatMessage({ id: 'LOCATION.MISSING_TEXT' })} />;
    // location have no parent - either is complex or hibrid
    else if (!parentLocation) {
        // complex locations contain more than one measure point
        if (thisLocation.measurePoints && thisLocation.measurePoints.length >= 1) {
            locationDetailsTemplate =
                <ComplexLocationDetails
                    locationId={locationId}
                    aggregation={aggregation}
                    aggregationCallbacks={aggregationCallbacks}
                    sidebarDispatch={sidebarCallback}
                />
        }
        // 'thingsboard location' - location with telemetry from thingsboard
        else if (thisLocation.tb_sensors && thisLocation.tb_sensors.length >= 1) {
            locationDetailsTemplate =
                <TBLocation
                    locationId={locationId}
                    aggregation={aggregation}
                    aggregationCallbacks={aggregationCallbacks}
                    sidebarDispatch={sidebarCallback}
                />
        }
        // 'hybrid location' - location as a measure point
        else {
            locationDetailsTemplate =
                <HybridLocation
                    locationId={locationId}
                    aggregation={aggregation}
                    aggregationCallbacks={aggregationCallbacks}
                    sidebarDispatch={sidebarCallback}
                />
        }
    } else {
        locationDetailsTemplate =
            <MeasurePointDetails
                locationId={locationId}
                aggregation={aggregation}
                aggregationCallbacks={aggregationCallbacks}
                sidebarDispatch={sidebarCallback}
            />
    }

    return (
        <AppView
            key={"LocationDetailsPage"}
            menu={props.menu()}
            className="locations-details-view"
            title={<LocationBreadcrumb locationId={locationId} />}
            actions={[isLoggedIn && !thisLocation.parentLocation ?
                <div key={locationId} className='contacts-button-link'>
                    <Tooltip key="view-contacts" type='pointer' text={intl.formatMessage({ id: 'VIEW_CONTACTS' })} position='left' className='contacts-button-locations'>
                        <Button key="view-contacts-button" className="title" onClick={contactsOnClick} > {intl.formatMessage({ id: 'CONTACTS' })} </Button>
                    </Tooltip>
                </div> : null
            ]}
        >
            {isLoggedIn ? <>
                <SideDrawer
                    state={sidebarState}
                    onClose={() => sidebarCallback('CLOSE')}
                />
                {locationDetailsTemplate}
            </> : <LoginRequired />}
        </AppView>
    );
}

export default LocationDetailsPage;
