import React from 'react';
import { Bar } from 'react-chartjs-2';
import { paletteColors } from '../utils/chartsCollorPallete'
import defaultOptions from 'charts/utils/defaultOptions';
import { deepMerge } from 'utils';


const StackedBarChart = (props) => {
  const autoColor = paletteColors();
  // inject palette colors to each serie
  if (props.data && props.data.datasets) {
    props.data.datasets.forEach(dataset => {
      dataset.backgroundColor = autoColor();
    })
  }

  const options = deepMerge(deepMerge({}, props.options), defaultOptions);
  return (
    <Bar data={props.data} options={options} ref={props.chartRef}/>
  );
}


export default StackedBarChart;