import { App } from '@react-gcc-eds/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { UserSettings, LanguageSelector } from 'components';
import { KeycloakLogoutLink } from 'api/AuthKeycloak';
import './../pageLayouts.scss';

const PublicLayout = (props) => {
    const { children } = props;
    const languageSelector = props.changeLanguage;
    const user = useSelector(userSelector);
    const signedInUser = user.isSuccess && { firstName: user.fullName, lastName: '' };
    return (
        <App
            applicationName={process.env.REACT_APP_TITLE}
            acronym={process.env.REACT_APP_TITLE_ACRONYM}
            user={signedInUser}
            hideNotificationBell
            forceTheme theme="light"
            settingsTitle=' '
            settingsContent={[<UserSettings key="shortcuts" />]}
            onLogout={() => { window.location.assign(KeycloakLogoutLink) }}
            appBarItems={[
                <LanguageSelector languageSelector={languageSelector} key='languageSelector' />
            ]}
        >
            {children}
        </App>
    );
}

export default PublicLayout;
