import React, { useCallback, useReducer, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { AppView, Column, Row, Tile, useSettings } from '@react-gcc-eds/core';
import { LocationBreadcrumb, SampleReportingFilters, SideDrawer, SamplingLogsSamplesCount, SamplingLogTypeDistributionPie, SamplingLogQualityDistributionPie } from 'components';

import '../reportingPage.scss';
import { SamplesTableContainer } from "components";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { LoginRequired } from "components";

function SamplesReportPage(props) {
    const { locationId } = useParams();
    const settingsVisible = useSettings().settingsVisible;
    const chartRef = useRef(null);
    const isLoggedIn = useSelector(userSelector).isSuccess;

    // STATE & CALLBACKS
    const [measurementTypes, setMeasurementTypes] = useState([]);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [qualityTypes, setQualityTypes] = useState([]);
    const [pictureSample, togglePictureSample] = useState(false);
    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);

    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    const filterHandler = useCallback((data, type) => {
        switch (type) {
            case 'samples':
                setSampleTypes(data);
                return;
            case 'measurements':
                setMeasurementTypes(data);
                return;
            case 'quality':
                setQualityTypes(data);
                return;
            case 'picture':
                togglePictureSample(data);
                return;
            default:
                setSampleTypes([]);
                setMeasurementTypes([]);
                setQualityTypes([]);
                togglePictureSample(false);
                return;
        }
    }, [setSampleTypes, setMeasurementTypes, setQualityTypes, togglePictureSample]);

    return (
        <AppView
            key="reporting-page"
            menu={props.menu()}
            title={<LocationBreadcrumb locationId={locationId} />}
        >
            {isLoggedIn ? <>
                <SideDrawer
                    state={sidebarState}
                    onClose={() => sidebarDispatch({ type: 'CLOSE' })}
                />
                <SampleReportingFilters
                    locationId={locationId}
                    filterHandler={filterHandler}
                    // samples={samplesReporting}
                    // measurements={measurementsReporting}
                    sampleTypesSelected={sampleTypes}
                    measurementTypesSelected={measurementTypes}
                    qualityTypesSelected={qualityTypes}
                    pictureSample={pictureSample}
                    sidebarDispatch={sidebarCallback}
                />
                <Column>
                    <Row>
                        <Tile title={<FormattedMessage id="CHARTS.SAMPLES" />}
                            subtitle={<FormattedMessage id="GROUPING.NO_GROUP" />}
                            className="sample-report-type-distribution-bars-tile"
                            lg={6} md={12} sm={12}
                        >
                            <SamplingLogsSamplesCount
                                locations={[locationId]}
                                resourceTypes={measurementTypes.map(el => el.value)}
                                sampleTypes={sampleTypes.map(el => el.value)}
                                qualityTypes={qualityTypes.map(el => el.value)}
                                pictureSample={pictureSample}
                                chartRef={chartRef}
                            />
                        </Tile>
                        <Tile lg={3} md={6} sm={12}
                            title={<FormattedMessage id="CHARTS.TYPE_OF_MEASUREMENTS" />}
                            className='sample-report-type-distribution-pie-tile' >
                            <SamplingLogTypeDistributionPie
                                locations={[locationId]}
                                resourceTypes={measurementTypes.map(el => el.value)}
                                sampleTypes={sampleTypes.map(el => el.value)}
                                qualityTypes={qualityTypes.map(el => el.value)}
                                pictureSample={pictureSample}
                            />
                        </Tile>
                        <Tile lg={3} md={6} sm={12}
                            title={<FormattedMessage id="CHARTS.AVERAGE_QUALITY" />}
                            className='sample-report-quality-distribution-pie-tile' >
                            <SamplingLogQualityDistributionPie
                                locations={[locationId]}
                                resourceTypes={measurementTypes.map(el => el.value)}
                                sampleTypes={sampleTypes.map(el => el.value)}
                                qualityTypes={qualityTypes.map(el => el.value)}
                                pictureSample={pictureSample}
                            />
                        </Tile>
                    </Row>
                    <Row>
                        <SamplesTableContainer
                            locations={[locationId]}
                            sampleTypes={sampleTypes.map(el => el.value)}
                            qualityTypes={qualityTypes.map(el => el.value)}
                            pictureSample={pictureSample}
                            sidebarDispatch={sidebarCallback}
                            chartRef={chartRef}
                        />
                    </Row>
                </Column>
            </> : <LoginRequired />}
        </AppView>
    );
}

export default SamplesReportPage;