import React from 'react';
import { App } from '@react-gcc-eds/core';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { UserSettings, LanguageSelector } from 'components';
import { useHistory } from 'react-router';
import { KeycloakLogoutLink } from 'api/AuthKeycloak';
import './../pageLayouts.scss';

const DefaultLayout = (props) => {
    const { children } = props;
    const languageSelector = props.changeLanguage;
    const user = useSelector(userSelector);
    const history = useHistory();
    // get information about signed in user
    const signedInUser = user.isSuccess && { firstName: user.fullName, lastName: '' };

    return (
        <App
            applicationName={process.env.REACT_APP_TITLE}
            acronym={process.env.REACT_APP_TITLE_ACRONYM}
            hideNotificationBell
            menuDefaultClosed
            user={signedInUser}
            forceTheme theme="light"
            settingsTitle=' '
            settingsContent={[<UserSettings key="shortcuts" />]}
            onLogout={() => { window.location.assign(KeycloakLogoutLink) }}
            onBrandClick={() => history.push('/home')}
            appBarItems={[
                <LanguageSelector languageSelector={languageSelector} key='languageSelector' />
            ]}
        >
            {children}
        </App>
    );
}

export default DefaultLayout;
