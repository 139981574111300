import React from 'react';
import { Row, Text, Icon } from '@react-gcc-eds/core';
import { useSelector } from 'react-redux';
import { selectLocationDetails, selectAllLogoLocations } from 'redux/locationsSlice';
import { selectStatusCodesConf } from 'redux/configurationSlice';
import { FormattedMessage } from 'react-intl';
import './qualityStatusKPI.scss'

const QualityStatusKPI = (props) => {
    const { locationId } = props;
    const logoLocations = useSelector(selectAllLogoLocations).map(el => el._id);

    const alarmSeverity = ["severity-critical", "severity-major", "severity-warning", "severity-minor", "severity-cleared"];
    const alarmIconIndex = logoLocations.indexOf(locationId);
    let alarmIcon;
    if (alarmIconIndex >= 0 && alarmIconIndex < 4) {
        alarmIcon = <Icon name={alarmSeverity[alarmIconIndex]} className={alarmSeverity[alarmIconIndex]} key="alarm-icon" />
    } else alarmIcon = null;

    const statusCodesConf = useSelector(selectStatusCodesConf)

    // get location details from Redux
    const location = useSelector(state => selectLocationDetails(state, locationId));
    return (
        <Row className='quality-status-kpi' asContainer>
            <Text className='kpi-title' size='xl'> <FormattedMessage id="LOCATION.QUALITY_LATEST" /> {alarmIcon} </Text>
            <div className='trophic-details'>
                {location.statusCode.map(status =>
                    <div key={Object.keys(status)[0]} className='trophic-index'>
                        <Text className='kpi-title' as='div' size='lg'><FormattedMessage id={Object.keys(status)[0]} /></Text>
                        <Text className='kpi-subtitle' as='span' size='lg'><FormattedMessage id={"QUALITY_LEVEL.TSI." + statusCodesConf.find(code => code.key === Object.values(status)[0]).value} /></Text>
                    </div>
                )}
            </div>
        </Row>
    );
}

export default QualityStatusKPI;