import React, { useState, useCallback } from "react";
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectStatusCodesConf, selectResourcesTypesConf } from 'redux/configurationSlice';
import { userSelector, isUserSelector } from 'redux/userSlice';
import { Button, Table, Tile } from '@react-gcc-eds/core';
import NumberFieldThreshold from "./NumberFieldThreshold";
import API from "api";

function AddThreshold(props) {
    const intl = useIntl();
    const resourceTypesConf = useSelector(state => selectResourcesTypesConf(state));
    const statusCodesConf = useSelector(state => selectStatusCodesConf(state));
    const user = useSelector(userSelector);
    const isUser = useSelector(isUserSelector);
    const thresholds = Object.values(props.thresholds)[0];
    const [addMode, setAddMode] = useState(false);

    const render = useCallback(() => {
        let newThresholds = [];

        const statusKeys = statusCodesConf.filter(status=> status.key !== 0)
        .sort((a,b) => a.key > b.key ? -1 : a.key < b.key ? 1 : 0);
    
        const cancelAddMode = () => {
            setAddMode(false);
        }

        const handleThresholdChange = (newThreshold) => {
            Object.assign(newThreshold, {            
                locationId: props.location._id,
                name: Object.keys(props.thresholds)[0],
            });

            const thresholdIndex = newThresholds.findIndex(el => el.qLevel === newThreshold.qLevel);
            if( thresholdIndex > -1 ) newThresholds[thresholdIndex] = newThreshold;
            else newThresholds.push(newThreshold);
        }

        const saveNewThresholds = () => {
            if(props.onThresholdAdd) props.onThresholdAdd();
            
            // for new thresholds call API to create threshold with threshold object
            if(newThresholds.length > 0) {
                newThresholds.forEach(addThreshold => {   
                    delete addThreshold._id;
                    API.thresholds.postThreshold(user.token, addThreshold).then((response) => {
                        if ( response.status === 200 ) {
                            thresholds.push(response.data);
                            props.updateDataThreshold(response.data);
                        }
                    }).catch((error) => console.log('ERROR:', error))
                })
            }
            setAddMode(false);
        }

        let resourceType = props.selectedResource;

        if(resourceType) {
            // HEADERS
            let tableColumns = statusKeys.map(el => {
                return {
                    header: intl.formatMessage({ id: "QUALITY_LEVEL.TSI." + el.value }), 
                    key: el.key,
                    Cell: (props) => (  
                        <NumberFieldThreshold 
                            qLevel={el.key} 
                            resourceKey={props[el.key].resourceTypeKey}
                            handleThresholdChange={handleThresholdChange}
                        />)
                }
            });
            tableColumns = [{ header: "", key: "resourceTypeKey" }, ...tableColumns]

            // ROW
            let tableRows = [];
            const resourceTypeName = resourceTypesConf.find(el => el.key.toString() === resourceType.toString())
            let thresholdRow = {
                resourceTypeKey: intl.formatMessage({ id: resourceTypeName.name })+" ("+ resourceTypeName.unit +") ",
            }; 
            statusKeys.forEach(status => {
                if( thresholdRow[status.key] === undefined ) Object.assign(thresholdRow, {[status.key]: {resourceTypeKey: resourceTypeName.key}})
            });

            tableRows.push(thresholdRow);

            return <Tile className="thresholds-overview-table">
                <Table columns={tableColumns} rows={tableRows} className="thresholds-table"/> 
                <div className="button-group-bottom" hidden={!addMode}>
                    <Button onClick={cancelAddMode}>{intl.formatMessage({id: "BUTTON.CANCEL"})}</Button>
                    <Button primary onClick={saveNewThresholds}>{intl.formatMessage({id: "BUTTON.SAVE"})}</Button>
                </div>
            </Tile>
        }
    }, [intl, resourceTypesConf, statusCodesConf, thresholds, addMode, props, user])
           
    const addNewThresholds = () => {
        setAddMode(true);
    }

    return <>
        {addMode ? 
            render() :  
            <Button disabled={!isUser} onClick={addNewThresholds}>{intl.formatMessage({id: "BUTTON.ADD"})}</Button>}
    </>
}

export default AddThreshold;