import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { deepMerge } from 'utils';
import { paletteColors } from '../utils/chartsCollorPallete'
import defaultOptions from 'charts/utils/defaultOptions';


const DoughnutChart = (props) => {
  const autoColor = paletteColors();
  // inject palette colors to each serie
  if (props.data && props.data.datasets) {
    props.data.datasets.forEach(dataset => {
      if (dataset.data) {
        dataset.backgroundColor = []
        dataset.data.forEach(dataItem => {
          // get new color from palette
          const dsColor = autoColor();
          dataset.backgroundColor.push(dsColor);
        })
      }
    })
  }

  const chartOptions = {
    scales: {
      x: { display: false },
      y: { display: false }
    },
    radius: 75,
    animation: {
      animateScale: true
    }
  };

  const options = deepMerge(deepMerge({}, props.options), defaultOptions);
  return (
    <Doughnut data={props.data} options={deepMerge(options, chartOptions)} />
  );
}


export default DoughnutChart;