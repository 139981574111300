import React, { useCallback, useReducer, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { AppView, Icon, Row, Tile, Tooltip, useSettings } from '@react-gcc-eds/core';
import { LocationBreadcrumb, MeasurementReportingFilters, SideDrawer, MeasurementsTrendChart, MeasurementsTableContainer } from 'components';
import '../reportingPage.scss';
import { userSelector } from "redux/userSlice";
import { LoginRequired } from "components";

function MeasurementsPage(props) {
    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const intl = useIntl();
    const history = useHistory();
    const chartRef = useRef(null);
    const settingsVisible = useSettings().settingsVisible;
    const isLoggedIn = useSelector(userSelector).isSuccess;

    // STATE & CALLBACKS
    const [locations, setLocations] = useState([]);
    const [tablesView, setTablesView] = useState(false);
    const [measurementTypes, setMeasurementTypes] = useState([]);
    const [selectedMeasurementType, setSelectedType] = useState(null);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [aggregation, setAggregation] = useState({ group: 'NO_GROUP', time: null });
    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);

    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    const filterHandler = useCallback((data, type) => {
        let locationTypes;
        switch (type) {
            case 'locations':
                setLocations(data);
                return;
            case 'tables':
                setTablesView(!tablesView);
                return;
            case 'samples':
                setSampleTypes(data);
                setSelectedType(null);
                return;
            case 'measurements':
                setMeasurementTypes(data);
                setSelectedType(null);
                return;
            case 'aggregation':
                setAggregation(data);
                return;
            case 'air':
                locationTypes = resourceTypesConfig.filter(el => el.key > 1000 && el.key < 2000);
                setMeasurementTypes(data.filter(el => locationTypes.includes(el.value)));
                setSelectedType(type);
                return;
            case 'water':
                locationTypes = resourceTypesConfig.filter(el => el.key > 2000 && el.key < 4000);
                setMeasurementTypes(data.filter(el => locationTypes.includes(el.value)));
                setSelectedType(type);
                return
            default:
                setSampleTypes([]);
                setMeasurementTypes([]);
                setSelectedType(null);
                setAggregation({ group: 'NO_GROUP', time: null });
                return;
        }
    }, [setLocations, setTablesView, tablesView, setSampleTypes, setMeasurementTypes, setAggregation, setSelectedType, resourceTypesConfig]);

    const renderCharts = useCallback(() => {
        let tileWidth = locations.length > 1 ? 6 : 12;
        return <Row>
            {locations.map((location, index) => {
                if (index === locations.length - 1 && index % 2 === 0) tileWidth = 12;
                return !tablesView ?
                    // CHARTS
                    <Tile key={location.value} lg={tileWidth} title={location.title}
                        subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.group} />}
                        className="measurement-report-trend-chart-tile"
                        actions={[
                            <Tooltip key="details" type="pointer" text={intl.formatMessage({ id: "LOCATION.DETAILS_PAGE" })} position="left" >
                                <Icon name="info" onClick={() => history.push('/location/' + location.value)} />
                            </Tooltip>
                        ]}
                    >
                        <MeasurementsTrendChart
                            locationId={location.value}
                            resourceTypes={measurementTypes.map(el => el.value)}
                            sampleTypes={sampleTypes.map(el => el.value)}
                            aggregation={aggregation.time}
                            sidebarDispatch={sidebarCallback}
                            chartRef={chartRef}
                        />
                    </Tile> :
                    // TABLES
                    <MeasurementsTableContainer
                        key={location.value}
                        multiLocation
                        tileWidth={tileWidth}
                        locationId={location.value}
                        resourceTypes={measurementTypes.map(el => el.value)}
                        sampleTypes={sampleTypes.map(el => el.value)}
                        sidebarDispatch={sidebarCallback}
                        chartRef={chartRef}
                    />
            })}
        </Row>
    },
        [locations, measurementTypes, sampleTypes, aggregation, sidebarCallback, tablesView, chartRef, history, intl]);

    return (
        <AppView
            key="reporting-page"
            menu={props.menu()}
            title={<LocationBreadcrumb />}
        >
            {isLoggedIn ? <>
                <SideDrawer
                    state={sidebarState}
                    onClose={() => sidebarCallback('CLOSE')}
                />
                <MeasurementReportingFilters
                    multiLocation
                    filterHandler={filterHandler}
                    tablesView={tablesView}
                    selectedLocations={locations}
                    selectedType={selectedMeasurementType}
                    sampleTypesSelected={sampleTypes}
                    measurementTypesSelected={measurementTypes}
                    types={resourceTypesConfig}
                    aggregation={aggregation}
                    sidebarDispatch={sidebarCallback}
                />
                <div>
                    {renderCharts()}
                </div>
            </> : <LoginRequired />}
        </AppView>
    );
}

export default MeasurementsPage;