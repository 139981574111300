import React from 'react';
import { Icon, Welcome, Splash } from '@react-gcc-eds/core'
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import './SplashScreenPage.scss';

function SplashComponent(props) {
    const intl = useIntl();
    const user = useSelector(userSelector);

    if (props.error) return <Splash content={
        <div>
            <h2>{intl.formatMessage({ id: "APP.SPLASH.ERROR" })}</h2>
            <h1><Icon name="triangle-warning" className="red" /></h1>
        </div>}
        productName={process.env.REACT_APP_TITLE}
    />;

    else return <Welcome
        productName={process.env.REACT_APP_TITLE}
        signedInUser={user.isSuccess ? { firstName: intl.formatMessage({ id: "APP.LOGGED_IN" }), lastName: '' } : null}
        welcomeText={<h2> {intl.formatMessage({ id: "APP.SPLASH.WELCOME" })} </h2>}
    />;
}

export default SplashComponent;
