import React, { useCallback, useReducer, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { selectLocationDetails } from "redux/locationsSlice";
import { selectResourcesTypesConf } from "redux/configurationSlice";
import { AppView, Column, Row, Tile, useSettings } from '@react-gcc-eds/core';
import { LocationBreadcrumb, MeasurementReportingFilters, SideDrawer, MeasurementsTrendChart, MeasurementsTableContainer } from 'components';
import '../reportingPage.scss';
import { userSelector } from "redux/userSlice";
import { LoginRequired } from "components";

function MeasurementsReportPage(props) {
    const { locationId } = useParams();
    const thisLocation = useSelector(state => selectLocationDetails(state, locationId));
    const resourceTypesConfig = useSelector(selectResourcesTypesConf);
    const chartRef = useRef(null);
    const settingsVisible = useSettings().settingsVisible;
    const isLoggedIn = useSelector(userSelector).isSuccess;

    // STATE & CALLBACKS
    const [measurementTypes, setMeasurementTypes] = useState([]);
    const [selectedMeasurementType, setSelectedType] = useState(null);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [aggregation, setAggregation] = useState({ group: 'NO_GROUP', time: null });
    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);

    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    const filterHandler = useCallback((data, type) => {
        let locationTypes;
        switch (type) {
            case 'samples':
                setSampleTypes(data);
                setSelectedType(null);
                return;
            case 'measurements':
                setMeasurementTypes(data);
                setSelectedType(null);
                return;
            case 'aggregation':
                setAggregation(data);
                return;
            case 'air':
                locationTypes = thisLocation.resourceTypes.filter(el => el > 1000 && el < 2000);
                setMeasurementTypes(data.filter(el => locationTypes.includes(el.value)));
                setSelectedType(type);
                return;
            case 'water':
                locationTypes = thisLocation.resourceTypes.filter(el => el > 2000 && el < 4000);
                setMeasurementTypes(data.filter(el => locationTypes.includes(el.value)));
                setSelectedType(type);
                return
            default:
                setSampleTypes([]);
                setMeasurementTypes([]);
                setSelectedType(null);
                setAggregation({ group: 'NO_GROUP', time: null });
                return;
        }
    }, [setSampleTypes, setMeasurementTypes, setAggregation, setSelectedType, thisLocation.resourceTypes]);

    return (
        <AppView
            key="reporting-page"
            menu={props.menu()}
            title={<LocationBreadcrumb locationId={locationId} />}
        >
            {isLoggedIn ? <>
                <SideDrawer
                    state={sidebarState}
                    onClose={() => sidebarCallback('CLOSE')}
                />
                <MeasurementReportingFilters
                    filterHandler={filterHandler}
                    locationResources={thisLocation.resourceTypes}
                    selectedType={selectedMeasurementType}
                    sampleTypesSelected={sampleTypes}
                    measurementTypesSelected={measurementTypes}
                    types={resourceTypesConfig}
                    aggregation={aggregation}
                    sidebarDispatch={sidebarCallback}
                />
                {
                    <Column>
                        <Row>
                            <Tile title={<FormattedMessage id="CHARTS.MEASUREMENTS" />}
                                subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.group} />}
                                className="measurement-report-trend-chart-tile"
                            >
                                <MeasurementsTrendChart
                                    locationId={thisLocation._id}
                                    resourceTypes={measurementTypes.map(el => el.value)}
                                    sampleTypes={sampleTypes.map(el => el.value)}
                                    aggregation={aggregation.time}
                                    sidebarDispatch={sidebarCallback}
                                    chartRef={chartRef}
                                />
                            </Tile>
                        </Row>
                        <Row>
                            <MeasurementsTableContainer
                                locationId={thisLocation._id}
                                resourceTypes={measurementTypes.map(el => el.value)}
                                sampleTypes={sampleTypes.map(el => el.value)}
                                sidebarDispatch={sidebarCallback}
                                chartRef={chartRef}
                            />
                        </Row>
                    </Column>
                }
            </> : <LoginRequired />}
        </AppView>
    );
}

export default MeasurementsReportPage;