import './measurePointsTable.scss';
import React from 'react';
import { Table } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectStatusCodesConf, selectTypologyConf, selectStatusCodesTypesConf } from 'redux/configurationSlice';

function MeasurePointsTable(props) {
    const measurePoints = props.measurePoints;
    const intl = useIntl();
    const history = useHistory();
    const path = useLocation().pathname;
    const statusCodes = useSelector(selectStatusCodesConf);
    const statusCodesTypes = useSelector(selectStatusCodesTypesConf);
    const locationTypes = useSelector(selectTypologyConf);

    const rowClickHandler = (row) => {
        if (path !== ('/location/' + row.key)) {
            return history.push('/location/' + row.key);
        }
    }

    if (measurePoints.length > 0) {
        const statusHeaders = statusCodesTypes.map(status => {
                return {
                    header: intl.formatMessage({ id: status.key }),
                    key: status.key
                }
            });

        return (
            <div className="measure-points">
                <Table
                    className="measure-points-table"
                    scrollHorizontal
                    columns={[
                        {
                            freeze: window.innerWidth < 980 ? true : false,
                            header: intl.formatMessage({ id: 'LOCATION.MEASURE_POINTS' }),
                            key: 'name'
                        },
                        {
                            header: intl.formatMessage({ id: 'LOCATION.TYPE' }),
                            key: 'typeName'
                        }
                    ].concat(statusHeaders)}
                    rows={
                        measurePoints.map(measurePoint => {
                            const mpStatus = statusCodesTypes.map(status => {
                               const mp = measurePoint.statusCode.find(el => Object.keys(el)[0] === status.key);
                               if ( mp !== undefined ) return { 
                                   [status.key]: intl.formatMessage({id: `QUALITY_LEVEL.TSI.${statusCodes.find(el => el.key === Object.values(mp)[0]).value}` }) 
                                }
                               else return { [status.key]: "" }
                               
                            }).reduce(
                                (obj, item) => Object.assign(obj, item ), {});

                            return Object.assign(mpStatus, {
                                key: measurePoint._id,
                                name: measurePoint.name,
                                typeName: intl.formatMessage({id: `MP_TYPES.${locationTypes.find(el => el.key === measurePoint.type).value}`})
                            });
                        })
                    }
                onRowClick={(row) => rowClickHandler(row)}
                />
            </div>
        )
    }
    else {
        return null;
    }
}

export default MeasurePointsTable;
