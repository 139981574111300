import React, { useCallback, useEffect, useState } from 'react';
import { isAdminSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { Tile, Icon, Text, Tooltip, useSettings, Button } from '@react-gcc-eds/core';
import { AspectRatio } from 'react-aspect-ratio';
import MapPage from 'pages/map'
import { FormattedMessage, useIntl } from 'react-intl';
import { LocationDetailsForm } from 'components';
import 'react-aspect-ratio/aspect-ratio.css'
import './locationDetailsTabs.scss';


const LocationDetailsTab = (props) => {
  const { location, sidebarDispatch } = props;
  const intl = useIntl();
  const settingsVisible = useSettings().settingsVisible;
  const isAdmin = useSelector(isAdminSelector);
  const [selected, changeSelected] = useState('map');
  const [description, setDescription] = useState(location.description[intl.locale])
  const { name, picture, position } = location;

  useEffect(() => {
    setDescription(location.description[intl.locale]);
  }, [location, description, intl]);

  useEffect(() => {
    if (settingsVisible) sidebarDispatch('CLOSE');
  }, [settingsVisible, sidebarDispatch])

  // Description text block
  const InfoTextTab = (props) => {
    return <div className="cover-info">
      <Text key="info" size="lg">{props.info}</Text>
    </div>
  };

  // Resizable image holder
  const ImageTab = () => (
    <AspectRatio key="image" ratio="16/9" className="cover-image" >
      <img src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + picture} alt={name} />
    </AspectRatio>
  );

  // Cover map
  const MiniMapTab = () => <><MapPage className="cover-map" locationId={location._id} position={position} /></>;

  const locationDetailsForm = () => {
    sidebarDispatch('SET', {
      title: <FormattedMessage id="FORM.LOCATION_UPDATE" />,
      subtitle: <FormattedMessage id="FORM.LOCATION_UPDATE.SUB" />,
      display: () => <LocationDetailsForm locationId={location._id} sidebarDispatch={sidebarDispatch} />,
      width: 'wide'
    });
    sidebarDispatch('OPEN');
  }

  const measurePointForm = () => {
    sidebarDispatch('SET', {
      title: <FormattedMessage id="FORM.NEW_LOCATION.MP" />,
      subtitle: <FormattedMessage id="FORM.NEW_LOCATION.SUB" />,
      display: () => <LocationDetailsForm sidebarDispatch={sidebarDispatch} />,
      width: 'wide'
    });
    sidebarDispatch('OPEN');
  }

  const renderTab = useCallback(() => {
    switch (selected) {
      case 'inf':
        return <InfoTextTab info={description} />;
      case 'img':
        return <ImageTab />;
      case 'map':
      default:
        return <MiniMapTab />;
    }
  }, [selected, description]);

  return (
    <Tile className={'location-details-tabs ' + location.name} lg={5} md={5} sm={12}
      title={
        <span className="titles">
          <Icon key="info" name="info" className="title" onClick={() => changeSelected('inf')} active={selected === 'inf'} />
          <Icon key="image" name="image" className="title" onClick={() => changeSelected('img')} active={selected === 'img'} />
          <Icon key="map" name="map" className="title" onClick={() => changeSelected('map')} active={selected === 'map'} />
        </span>
      }
      actions={isAdmin ? [
        !location.parentLocation && <Tooltip key="add" type='pointer' text={intl.formatMessage({ id: 'LOCATION.ADD_NEW.TOOLTIP.MP' })} position='left'>
          <Button iconName='plus' name="plus" className="title" onClick={measurePointForm} > {intl.formatMessage({ id: 'LOCATION.ADD' })} </Button>
        </Tooltip>,
        <Tooltip key="update" type='pointer' text={intl.formatMessage({ id: 'LOCATION.EDIT.TOOLTIP' })} position='left'>
          <Button iconName='edit' name="settings" className="title" onClick={locationDetailsForm} > {intl.formatMessage({ id: 'LOCATION.EDIT' })} </Button>
        </Tooltip>,
      ] : null}
    >
      {renderTab()}
    </Tile >
  );
}

export default LocationDetailsTab;