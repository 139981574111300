import React, { useState } from 'react';
import { Table, Pagination, Loader } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDataTypesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { SamplingLogDetails } from 'components';
import { formatDate } from 'utils';
import API from 'api';

function MeasurementsTable(props) {
    const { multiLocation, measurementData } = props;
    const [rowsPerPage, setRowsPerPage] = useState(multiLocation ? 25 : 100);
    const rowsNumber = [100, 200, 300, 400, 500];
    const [page, setPage] = useState(1);
    const intl = useIntl();
    const resourcesTypes = useSelector(selectResourcesTypesConf);
    const sampleTypesConfig = useSelector(selectDataTypesConf);

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = (page - 1) * rowsPerPage + rowsPerPage;
    const headers = [
        {
            header: intl.formatMessage({ id: 'LOCATION.LOCATION' }),
            key: 'locationName'
        },
        {
            header: intl.formatMessage({ id: 'DATE' }),
            key: 'date'
        },
        {
            header: intl.formatMessage({ id: 'LOCATION.SAMPLES_TYPE' }),
            key: 'sampleType'
        },
        {
            header: intl.formatMessage({ id: 'LOCATION.MEASUREMENT_TYPE' }),
            key: 'measurementType'
        },
        {
            header: intl.formatMessage({ id: 'LOCATION.VALUE' }),
            key: 'value'
        }
    ];
    const rows = measurementData.slice(startIndex, endIndex);

    return <div className="measurements-points">
        <Table
            className="measurements-table"
            columns={headers}
            rows={rows}
            onRowClick={(rowData) => {
                props.sidebarDispatch('SET', {
                    title: intl.formatMessage({ id: 'LOCATION.LOADING_DATA' }) + '...',
                    subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
                    display: () => <Loader size="large" />,
                    width: 'wide'
                });
                API.samplingLogs.getSamplingDetails(rowData.samplingLogId)
                    .then(({ data }) => {
                        const sampleType = sampleTypesConfig.find(el => el.key === data.samplingType).value;
                        const dataArray = [];
                        for (let index = 0; index < data.measurements.length; index++) {
                            const displayData = data.measurements[index];
                            const resource = resourcesTypes.find(el => el.key === displayData.resourceTypeKey);
                            dataArray.push({
                                id: resource.key,
                                resource: intl.formatMessage({ id: resource.name }),
                                value: parseFloat(displayData.value).toFixed(2),
                                unit: resource.unit
                            });
                        }
                        setTimeout(() =>
                            props.sidebarDispatch('SET', {
                                title: intl.formatMessage({ id: "LOCATION.MEASUREMENT_LOG" }),
                                subtitle: formatDate(data.timestamp, { getDetailedTime: true }),
                                display: () => <SamplingLogDetails locationName={rowData.locationName} locationId={data.sampledObject} logId={data._id} sampleType={sampleType} values={dataArray.filter(dataset => !isNaN(parseFloat(dataset.value)))} notes={data.notes} pictures={data.pictures} />,
                                width: 'wide'
                            }), 100);
                    })
                    .catch(() => {
                        props.sidebarDispatch('SET', {
                            title: intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.ERROR' }),
                            subtitle: '',
                            display: () => <div />
                        });
                    });
                props.sidebarDispatch('OPEN');
            }}

            paginaton={
                <Pagination
                    activePage={page}
                    currentItemsPerPage={rowsPerPage}
                    itemsPerPageOptions={!multiLocation ? rowsNumber : rowsNumber.map(el => el/4)}
                    onItemsPerPageChanged={(n) => setRowsPerPage(n)}
                    onLeftClick={(n) => setPage(n)} onRightClick={(n) => setPage(n)}
                    onPageChanged={(n) => setPage(n)} onPageClick={(n) => setPage(n)}
                    pages={Math.ceil(measurementData.length / rowsPerPage)}
                    showItemsPerPageSelector
                    showPageSelector
                />
            }
            tableInfo={{
                show: {
                    from: startIndex + 1,
                    to: endIndex > measurementData.length ?
                        measurementData.length : endIndex
                },
                total: measurementData.length
            }}
        />
    </div>;
};

export default MeasurementsTable;