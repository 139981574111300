import React, { useState } from 'react';
import { NumberField } from '@react-gcc-eds/core';

function NumberFieldThreshold(props) {
    const { qLevel, resourceKey, handleThresholdChange, readonly } = props;
    const [min, setMin] = useState(props.valueMin ? props.valueMin : 0);
    const [max, setMax] = useState(props.valueMax ? props.valueMax : 0); 

    const handleValueChange = (e, type) => {
        let updatedThreshold = {
            resourceTypeKey: resourceKey,
            qLevel: qLevel,
            min: min,
            max: max,
            _id: props.thresholdId ? props.thresholdId : null
        }

        if( type === "min" ) {
           setMin(e);  
           updatedThreshold.min = e;
        }
        else if ( type === "max" ) {
            setMax(e);
            updatedThreshold.max = e;
        }

        if (!(isNaN(updatedThreshold.min) || isNaN(updatedThreshold.max))) {  
            handleThresholdChange(updatedThreshold)
        }
    }

    return (
        <span className="number-field-min-max"> 
            <NumberField 
                step=".01" 
                min="-1000"
                max="1000"
                readonly={readonly ? readonly : false}                              
                onChange={(e) => handleValueChange(e, "min") }
                value={readonly ? props.valueMin : min }            
            /> 
            <NumberField 
                step=".01"
                min="-1000"
                max="1000"
                readonly={readonly ? readonly : false}
                onChange={(e) => handleValueChange(e, "max") }
                value={readonly ? props.valueMax : max } 
            />
        </span>)
}

export default NumberFieldThreshold;