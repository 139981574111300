
// Categorical palette defined by EDS: http://eds.internal.ericsson.com/data-visualization/colors
const palette = [
    '#878787',
    '#976CF4',
    '#23969A',
    '#C86F6D',
    '#333333',
    '#B98327',
    '#8087AD',
    '#3E0DA9',
    '#5B85D0',
    '#4D0C2B',
    '#D82177',
    '#0C3B35',
    '#74132A',
    '#3E4461',
    '#CF4219',
    '#5B4013',
    '#BD1F44'
];

/**
 * Utility function to get next color upon each call
 */
const paletteColors = () => {
    let index = 0;
    return function() {        
      const color = palette[index]      
      index++;
      return color;
    };
  }

export {
    palette,
    paletteColors
}