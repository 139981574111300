import React, { useState } from 'react';
import { Table, Pagination, Loader, Text, Tile } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDataTypesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { SamplingLogDetails } from 'components';
import { formatDate } from 'utils';
import API from 'api';

function SamplesTable(props) {
    const { sampleData, hideQuality } = props;
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(1);
    const intl = useIntl();
    const sampleTypesConfig = useSelector(selectDataTypesConf);
    const resourcesTypes = useSelector(selectResourcesTypesConf);

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = (page - 1) * rowsPerPage + rowsPerPage;

    const headers = [
        {
            header: intl.formatMessage({ id: 'LOCATION.LOCATION' }),
            key: 'locationName'
        },
        {
            header: intl.formatMessage({ id: 'DATE' }),
            key: 'date'
        },
        {
            header: intl.formatMessage({ id: 'SAMPLE_LOG.TYPES' }),
            key: 'sampleType'
        }
    ];

    if (!hideQuality) headers.push({
        header: intl.formatMessage({ id: 'LOCATION.QUALITY_LATEST' }),
        key: 'quality'
    });
    headers.push({
        header: intl.formatMessage({ id: 'LOCATION.SAMPLE_LOG_NOTES' }),
        key: 'sampleNotes'
    })
    const rows = sampleData.slice(startIndex, endIndex);

    return <div className="sample-points">
        <Table
            className="samples-table"
            columns={headers}
            rows={rows}
            onRowClick={(rowData) => {
                if (!rowData.key && rowData.data) {
                    const displayArray = rowData.data.map(el => {
                        return (
                            <div className="resource-block" key={el.resource.key}>
                                <Text as="div" size="md" className="left label">{intl.formatMessage({ id: el.resource.name })}</Text>
                                <div className="value">
                                    <Text as="span" size="xl">{parseFloat(el.value).toFixed(2)} </Text>
                                    <Text as="span" size="lg" >{el.resource.unit}</Text>
                                </div>
                            </div>
                        );
                    });

                    const skippedArray = rowData.errors.array.map(el => {
                        return (
                            <div className="resource-block" key={el.column}>
                                <Text as="div" size="md" className="left label">{el.column}</Text>
                                <div className="value">
                                    <Text as="span" size="lg">{el.value} </Text>
                                </div>
                            </div>
                        );
                    });
                    props.sidebarDispatch('SET', {
                        title: intl.formatMessage({ id: "HISTORY_REPORT.TITLE.SAMPLE" }),
                        subtitle: rowData.date,
                        display: () => <>
                            <Tile className='samplinglog-details'>
                                <Text className='title' as="div" >{intl.formatMessage({ id: "HISTORY_REPORT.MEASUREMENTS" })}</Text>
                                {displayArray}
                            </Tile>
                            {skippedArray.length ? <Tile className='samplinglog-details'>
                                <Text className='title' as="div" >{intl.formatMessage({ id: "HISTORY_REPORT.SKIPPED" })}</Text>
                                {skippedArray}
                            </Tile> : null}
                        </>,
                    });
                    props.sidebarDispatch('OPEN');
                    return;
                }
                props.sidebarDispatch('SET', {
                    title: intl.formatMessage({ id: 'LOCATION.LOADING_DATA' }) + '...',
                    subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
                    display: () => <Loader size="large" />,
                    width: 'wide'
                });
                API.samplingLogs.getSamplingDetails(rowData.key)
                    .then(({ data }) => {
                        const sampleType = sampleTypesConfig.find(el => el.key === data.samplingType).value;
                        const dataArray = [];
                        for (let index = 0; index < data.measurements.length; index++) {
                            const displayData = data.measurements[index];
                            const resource = resourcesTypes.find(el => el.key === displayData.resourceTypeKey);
                            dataArray.push({
                                id: resource.key,
                                resource: intl.formatMessage({ id: resource.name }),
                                value: parseFloat(displayData.value).toFixed(2),
                                unit: resource.unit
                            });
                        }
                        setTimeout(() =>
                            props.sidebarDispatch('SET', {
                                title: intl.formatMessage({ id: "LOCATION.SAMPLE_LOG" }),
                                subtitle: formatDate(data.timestamp, { getDetailedTime: true }),
                                display: () => <SamplingLogDetails locationName={rowData.locationName} locationId={data.sampledObject} logId={data._id} sampleType={sampleType} values={dataArray.filter(dataset => !isNaN(parseFloat(dataset.value)))} notes={data.notes} pictures={data.pictures} />,
                                width: 'wide'
                            }), 100);
                    })
                    .catch(() => {
                        props.sidebarDispatch('SET', {
                            title: intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.ERROR' }),
                            subtitle: '',
                            display: () => <div />
                        });
                    });
                props.sidebarDispatch('OPEN');
            }}

            paginaton={sampleData.length > rowsPerPage ?
                <Pagination
                    activePage={page}
                    currentItemsPerPage={rowsPerPage}
                    itemsPerPageOptions={[100, 150, 200, 250]}
                    onItemsPerPageChanged={(n) => setRowsPerPage(n)}
                    onLeftClick={(n) => setPage(n)} onRightClick={(n) => setPage(n)}
                    onPageChanged={(n) => setPage(n)} onPageClick={(n) => setPage(n)}
                    pages={Math.ceil(sampleData.length / rowsPerPage)}
                    showItemsPerPageSelector
                    showPageSelector
                /> : null
            }
            tableInfo={sampleData.length > rowsPerPage ? {
                show: {
                    from: startIndex + 1,
                    to: endIndex > sampleData.length ?
                        sampleData.length : endIndex

                },
                total: sampleData.length
            } : null}
        />
    </div>;
};

export default SamplesTable;