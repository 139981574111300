import { Button } from '@react-gcc-eds/core';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback } from 'react';
import './reportingButtons.scss';

const ReportingButtons = () => {
    const history = useHistory();
    const { locationId } = useParams();
    const samplesOnClick = useCallback(() => history.push('/samples/' + locationId), [history, locationId]);
    const measurementsOnClick = useCallback(() => history.push('/measurements/' + locationId), [history, locationId]);
    const thresholdsOnClick = useCallback(() => history.push('/thresholds/' + locationId), [history, locationId]);

    return (
        <div>
            <Button className="report-link samples" onClick={samplesOnClick}> <FormattedMessage id="BUTTON.REPORT_SAMPLES" /> </Button>
            <Button className="report-link measurements" onClick={measurementsOnClick}> <FormattedMessage id="BUTTON.REPORT_MEASUREMENTS" /> </Button>
            <Button className='report-link thresholds' onClick={thresholdsOnClick}><FormattedMessage id="BUTTON.REPORT_THRESHOLDS" /></Button>
        </div>
    )
}

export default ReportingButtons;