import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectInitDone } from 'redux/appSlice';

import './App.scss';


import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// LAYOUTS
import DefaultLayout from './layouts/defaultLayout';
import PublicLayout from './layouts/publicLayout';

// PAGES
import LandingPage from './pages/landing';
import MapPage from './pages/map';
import LocationDetailsPage from './pages/location/details';
import LocationsListPage from './pages/location/list';
import HistoryReportPage from './pages/location/historyReport';
import { MeasurementsReportPage, MeasurementsPage, SamplesReportPage, SamplesPage } from 'pages/reporting';
import SplashScreen from './pages/splash';
import CookiesPolicy from './pages/cookies'
import { ThresholdDetailsPage } from './pages/thresholds';

// COMPONENTS
import { MenuPublic, Footer } from 'components';

import locale_en from "./lang/en.json";
import locale_hr from "./lang/hr.json";
import { IntlProvider } from "react-intl";
import ContactsDetailsPage from 'pages/contacts/ContactsDetailsPage';

const languages = {
  'en': locale_en,
  'hr': locale_hr
};

function App() {
  document.title = process.env.REACT_APP_TITLE;
  // bind to Redux to check is ready
  const initDone = useSelector(selectInitDone);

  const navigatorLanguage = navigator.language.split(/[-_]/)[0];
  const [language, setLanguage] = useState(navigatorLanguage);

  const changeLanguage = (language) => {
    switch (language) {
      case 'hr': setLanguage('hr');
        break;
      case 'en': setLanguage('en');
        break;
      default: setLanguage('en');
        break;
    }
  }

  const render = useCallback(() => {
    if (initDone === 'failed') return <IntlProvider locale={language} messages={languages[language]}>
      <SplashScreen error />
    </IntlProvider>
    else if (initDone) {
      return <IntlProvider locale={language} messages={languages[language]}>
        <div className='app-container'>
          <div className='app-main-content'>
            <Router>
              <Switch>
              <Redirect exact path='/' to='/home' />

                <Route exact path="/home">
                  <PublicLayout changeLanguage={changeLanguage} ><LandingPage menu={MenuPublic} /></PublicLayout>
                </Route>

                <Route exact path="/map">
                  <DefaultLayout changeLanguage={changeLanguage} ><MapPage /></DefaultLayout>
                </Route>

                <Route exact path="/locations">
                  <DefaultLayout changeLanguage={changeLanguage} ><LocationsListPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path="/location/:locationId">
                  <DefaultLayout changeLanguage={changeLanguage} ><LocationDetailsPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path="/location/:locationId/report">
                  <DefaultLayout changeLanguage={changeLanguage} ><HistoryReportPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/measurements"]}>
                  <DefaultLayout changeLanguage={changeLanguage} ><MeasurementsPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/measurements/:locationId"]}>
                  <DefaultLayout changeLanguage={changeLanguage} ><MeasurementsReportPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/samples"]}>
                  <DefaultLayout changeLanguage={changeLanguage} ><SamplesPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/samples/:locationId"]}>
                  <DefaultLayout changeLanguage={changeLanguage} ><SamplesReportPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/thresholds/:locationId"]}>
                  <DefaultLayout changeLanguage={changeLanguage} ><ThresholdDetailsPage menu={MenuPublic} /></DefaultLayout>
                </Route>
                <Route exact path={["/contacts/:locationId"]}>
                  <DefaultLayout changeLanguage={changeLanguage}><ContactsDetailsPage menu={MenuPublic}/></DefaultLayout>
                </Route>
                <Route exact path="/cookie-policy">
                  <DefaultLayout changeLanguage={changeLanguage} ><CookiesPolicy /></DefaultLayout>
                </Route>

                <Route>
                  <SplashScreen error />
                </Route>
              </Switch>
            </Router>
          </div>
          <Footer />
        </div>
      </IntlProvider>
    }
    else return <IntlProvider locale={language} messages={languages[language]}>
      <SplashScreen />
    </IntlProvider>
  }, [initDone, language]);

  return render();

}

export default App;
