import configuration from './configurationAPI';
import auth from './authAPI';
import locations from './locationsAPI';
import measurements from './measurementsAPI';
import samplingLogs from './samplingLogsAPI';
import thresholds from './thresholdsAPI';
import keycloak from './keycloakAPI'

const API = {
    configuration,
    auth,
    locations,
    measurements,
    samplingLogs,
    thresholds,
    keycloak
}

export default API;