import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { Menu, MenuNode, MenuItem, AppBarItem } from '@react-gcc-eds/core';
import { FormattedMessage } from 'react-intl';
import { selectLocations, selectLocationParent } from 'redux/locationsSlice';
import { userSelector, cookieConsentSelector } from 'redux/userSlice';
import AuthKeycloak from 'api/AuthKeycloak';
import 'react-aspect-ratio/aspect-ratio.css';
import './menuPublic.scss'

const MenuPublic = () => {
    const history = useHistory();
    const locations = useSelector(selectLocations);
    const user = useSelector(userSelector);
    const cookie = useSelector(cookieConsentSelector);
    const locationID = useParams().locationId;
    const parentID = useSelector(state => selectLocationParent(state, locationID));

    const locationsMenuItems = locations.map(location => {
        const ICON_FLAG = (parentID && parentID._id === location._id) || locationID === location._id;
        return <MenuItem
            key={location._id}
            tag={() =>
                <AppBarItem
                    text={location.name}
                    iconName={ICON_FLAG ? 'position' : null}
                    onClick={() => history.push('/location/' + location._id)}
                />}
        />
    });

    const renderMenu = useCallback(() => {
        return (
            <Menu closeOnSelect key="locationMenu">
                <MenuItem key="landing" tag={() => <AppBarItem text={<FormattedMessage id='APP.MENU.HOME' />} onClick={() => history.push('/home')} />} />
                <MenuItem key="map" tag={() => <AppBarItem text={<FormattedMessage id='APP.MENU.MAP' />} onClick={() => history.push('/map')} />} />
                <MenuNode
                    expanded={locationID}
                    key="locations"
                    title={<span>
                        {/* {locationID && <Icon name='position' />} */}
                        <FormattedMessage id='LOCATION.LOCATIONS' />
                    </span>}
                >
                    <MenuItem
                        key='all-locations'
                        tag={() =>
                            <AppBarItem
                                text={<FormattedMessage id='LOCATION.ALL_LOCATIONS' />}
                                iconName='view-list'
                                onClick={() => history.push('/locations')}
                            />}
                    />
                    {locationsMenuItems}
                </MenuNode>
                {(user.isSuccess) && <>
                    <MenuItem key="measurements" tag={() => <AppBarItem text={<FormattedMessage id="LOCATION.MEASUREMENTS" />} onClick={() => history.push('/measurements')} />} />
                    <MenuItem key="samples" tag={() => <AppBarItem text={<FormattedMessage id="LOCATION.SAMPLES" />} onClick={() => history.push('/samples')} />} />
                </>}
                {(!user.isSuccess) &&
                    <div className={cookie ? "login" : "login-disabled"}>
                        <MenuItem key="auth" tag={() => <AppBarItem text={<FormattedMessage id='APP.LOG_IN' />} onClick={() => AuthKeycloak('login-required')} />} />
                    </div>
                }
            </Menu>
        );
    }, [cookie, locationID, history, user, locationsMenuItems]
    );
    return (
        <div>
            {renderMenu()}
        </div>
    )
}

export default MenuPublic;