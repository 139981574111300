import React from 'react';
import { Text } from '@react-gcc-eds/core';
import { PictureGallery } from 'components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTypologyConf } from 'redux/configurationSlice';
import { selectStatusCodesConf, selectResourcesTypesConf } from 'redux/configurationSlice';
import { selectLocationDetails } from 'redux/locationsSlice';

import './dataEntryReport.scss';

function DataEntryReport(props) {
    const { status, data, notes, collector, locationId, pictures } = props;
    const intl = useIntl();
    const statusCodes = useSelector(selectStatusCodesConf);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const locationTypes = useSelector(selectTypologyConf);
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    const type = locationTypes.find(el => el.key === location.type).value;
    const entryDataArray = [];
    if (!!data) {
        for (let index = 0; index < data.length; index++) {
            const entryData = data[index];
            const resource = resourceTypes.find(el => el.key.toString() === entryData.resourceTypeKey);
            entryDataArray.push({
                resource: intl.formatMessage({ id: resource.name }),
                value: entryData.value,
                unit: resource.unit,
                status: entryData.status
            });
        }
    }

    return <div className="tile">

        <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: 'LOCATION.LOCATION' })}</Text>
        <Text as="div" className="location-name">{location.name}</Text>

        <div className="report-block">
            <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: 'LOCATION.TYPE' })}</Text>
            <Text as="div" className="details-label">{intl.formatMessage({ id: 'MP_TYPES.' + type })}</Text>
        </div>
        {status.length ? status.map(status =>
            <div className="report-block" key={status.key}>
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: status.key })}</Text>
                <Text as="div" className="details-label">{intl.formatMessage({ id: 'QUALITY_LEVEL.TSI.' + statusCodes.find(el => el.key === status.value).value })}</Text>
            </div>) : null}
        <div className="report-block">
            <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: 'SAMPLE_LOG.COLLECTOR' })}</Text>
            <Text as="div" className="details-label">{collector}</Text>
        </div>

        {!!notes && notes.length ? <div className="report-block">
            <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "FORM.NOTES_LABEL" })}</Text>
            <Text as="div" className="notes details-label" >{notes}</Text>
        </div> : null}

        {entryDataArray.length ? <div className="report-block">
            <Text as="div" size="lg" >{intl.formatMessage({ id: 'FORM.VALUES' })}</Text>
            <div className="values">
                {entryDataArray.map(entry =>
                    <div className="value-block" key={"value-block " + entry.resource}>
                        <div className="content">
                            <Text as="div" size="md" className="resource-label">{entry.resource}</Text>
                            <div className="value">
                                <Text as="span" size="xl">{entry.value} </Text>
                                <Text as="span" size="lg" >{entry.unit}</Text>
                            </div>
                            {entry.status && entry.status.length ? entry.status.map(status => (
                                <div key={status.key}>
                                    <Text as="span" size="md" className="resource-status" key={status.key}>{intl.formatMessage({ id: status.key })}: {intl.formatMessage({ id: 'QUALITY_LEVEL.TSI.' + statusCodes.find(el => el.key === status.value).value })} </Text>
                                </div>)) : null}
                        </div>
                    </div>
                )}
            </div>
        </div> : null
        }

        {
            pictures.length ? <div key="gallery" className="report-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "FORM.PICTURES" })} ({pictures.length})</Text>
                <PictureGallery pictures={pictures} />
            </div> : null
        }
    </div >;
}

export default DataEntryReport;