import React, { useState, /* useEffect */ } from 'react';
import { Icon, Card, Text, Pill } from '@react-gcc-eds/core';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectStatusCodesConf } from 'redux/configurationSlice';
import { selectLocationFilters } from 'redux/locationsSlice';
import { useMap, useMapEvent, Marker, Popup, Tooltip } from 'react-leaflet';
import LeafletMarkers from 'utils/LeafletMarkers';
import { statusCodeColor } from 'utils';
import { FormattedMessage, useIntl } from 'react-intl';

function tooltipFilterPoints(filterList, location) {
    const isFiltered = (el) =>
    !(filterList.locationTypes.includes(el.type) || filterList.statusCodes.find(status => 
             el.statusCode.find(locStatus => 
                Object.keys(locStatus)[0] === status.key && Object.values(locStatus)[0] === status.value 
            )));
    let tooltipText = location.measurePoints.filter((location) => isFiltered(location)).length;
    return isFiltered(location) ? tooltipText + 1 : tooltipText;
};

/****************************
*       MAIN COMPONENT          
*****************************/

function MapMarker(props) {
    const {
        element, // location[object] - Marker subject
        location, // boolean - is element a location
        measurePoint, // boolean - is element a measure point
        hidePopup, // boolean - hide Marker popups
        maxShowZoom // boolean - distance to render location cluster
    } = props;
    const statusCodesConfig = useSelector(selectStatusCodesConf)
    const filterList = useSelector(selectLocationFilters);
    const map = useMap();
    const intl = useIntl();
    const history = useHistory();
    const [zoomLevel, setZoomLevel] = useState(map.getZoom());

    useMapEvent('zoom', () => {
        setZoomLevel(map.getZoom());
    });

    const statusArray = element.statusCode.filter(el => Object.values(el)[0] > 0 ).map(el => Object.values(el)[0]);
    const statusCodeMin = statusArray.length ? Math.min(...statusArray) : 0;
    const MARKER_ICON = location ? LeafletMarkers.positionMarker(statusCodeMin) : LeafletMarkers.measurePointMarker(statusCodeMin);

    return (
        <Marker position={element.position} icon={zoomLevel > maxShowZoom ? MARKER_ICON : LeafletMarkers.clusterMarker()} eventHandlers={!hidePopup ? {
            click: (e) => {
                const ZOOM = measurePoint ? 15 : 10;
                map.setView(e.latlng, ZOOM);
                setZoomLevel(ZOOM);
            },
            mouseover: (e) => {
                e.target.openPopup();
            }
        } : null}>
            {!hidePopup && <Popup key={element._id}>
                <Card
                    headerActions={[
                        location && element.measurePoints.length !== 0 && <Pill key='measure-point-number' className='measure-point-number' icon='antenna'>{element.measurePoints.length}</Pill>,
                        <Pill key='details-link' icon='graph-line' onToggle={() => history.push('/location/' + element._id)}> <FormattedMessage id="LOCATION.DETAILS" /> </Pill>,
                    ]}
                    title={<Text size="md"><Icon name="position" />{element.name}</Text>}
                    content={
                        <>
                        {element.statusCode.length ?
                            <div className='card-status-indexes'>{element.statusCode.map(el =>
                                <div key={Object.keys(el)[0]} >  
                                    <span className={`status-icon ${statusCodesConfig.find(status => status.key === Object.values(el)[0]).value.toLowerCase()}`}> <Icon name='alarm-level2' /> </span>
                                    <span className='status-text'><FormattedMessage id={Object.keys(el)[0]} /> </span>
                                    <span className='status-text'><FormattedMessage id={`QUALITY_LEVEL.TSI.${statusCodesConfig.find(status => status.key === Object.values(el)[0]).value }`} /> </span>
                                </div>)}
                            </div>: null}
                            <div className={element.description[intl.locale]!=='' ? 'card-content' : ''}>{element.description[intl.locale]}</div>
                        </>}
                    selectable={false}
                />
            </Popup>}
            {!hidePopup && location && zoomLevel <= maxShowZoom &&
                <Tooltip direction="bottom" offset={[8, -20]} opacity={1} permanent>
                    {<Pill
                        key='mp-number-tooltip'
                        className={element.logo ? "cluster-logo" :"cluster"}
                        style={{
                            border: "1px solid " + statusCodeColor(statusCodeMin)
                        }}
                    >
                        {element.logo ? <img src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + element.logo} alt={element.name +" logo image"} className="logo-image-locations" /> : tooltipFilterPoints(filterList, element)}
                       
                    </Pill>}
                </Tooltip>}
        </Marker>
    );
};

export default MapMarker;