import { AppView, Row } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import './landingPage.scss';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/styles';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);

function LandingPage(props) {
    const intl = useIntl();
    const slides =
        [
            {
                key: 0,
                source: window.location.origin + '/images/dam1.jpg',
                title: <div className='autoplay-slider-title'> <div className='autoplay-slider-logo-container'><img alt='EP-logo' src={window.location.origin + '/images/hzhblogo.png'} className='autoplay-slider-title-logo' /><span>{intl.formatMessage({ id: 'LANDING.PAGE_CONTENT.TITLE' })}</span></div></div>,
                caption: intl.formatMessage({ id: 'LANDING.PAGE_CONTENT.ONE' }),
            },
            {
                key: 1,
                source: window.location.origin + '/images/dam2.jpg',
                caption: intl.formatMessage({ id: 'LANDING.PAGE_CONTENT.TWO' }),
            },
            {
                key: 2,
                source: window.location.origin + '/images/dam3.jpg',
                caption: intl.formatMessage({ id: 'LANDING.PAGE_CONTENT.THREE' }),
            },
            {
                key: 3,
                source: window.location.origin + '/images/dam4.jpg',
                caption: intl.formatMessage({ id: 'LANDING.PAGE_CONTENT.FOUR' }),
            }
        ];

    return (
        <AppView
            theme='light'
            className='landing-page-app-content'
            title={intl.formatMessage({ id: 'APP.SPLASH.WELCOME' })}
            menu={props.menu()}
        >
            <Row className="content">
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={true}
                    interval={4000}
                    mobileTouch={true}
                >
                    {slides.map(image => {
                        return (
                            <div key={image.key} className='image-background' data-src={image.source}>{image.title}{image.caption}</div>
                        )
                    }
                    )}
                </AutoplaySlider>
            </Row>
        </AppView>
    )

}

export default LandingPage;
