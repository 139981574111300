import instance from './axiosEnv';
import { localISOString } from 'utils';

const getSamplingNumbers = (sampled_objects = '', date_from = null, date_to = null, quality_statuses = '', sample_types = '', aggregation = null, collectors = '') => {
    const queryParams = new URLSearchParams();
    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));
    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }
    for (let el in quality_statuses) {
        queryParams.append('quality_status', quality_statuses[el])
    }
    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el])
    }
    queryParams.append('aggregation', aggregation);

    return instance.get('/samplinglogs/samplingnumber?' + queryParams.toString());
}

const getQualityLevelDistribution = (sampled_objects = '', date_from = null, date_to = null, quality_statuses = '', sample_types = '', collectors = '') => {
    const queryParams = new URLSearchParams();
    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));
    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }
    for (let el in quality_statuses) {
        queryParams.append('quality_status', quality_statuses[el])
    }
    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el])
    }

    return instance.get('/samplinglogs/qualitylevels?' + queryParams.toString());
}

const getSamplingValues = (sampled_objects = '', date_from = null, date_to = null, quality_statuses = '', sample_types = '', collectors = '') => {
    const queryParams = new URLSearchParams();
    queryParams.append('date_from', new Date(date_from).toISOString());
    queryParams.append('date_to', new Date(date_to).toISOString());
    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }
    for (let el in quality_statuses) {
        queryParams.append('quality_status', quality_statuses[el])
    }
    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el])
    }

    return instance.get('/samplinglogs?' + queryParams.toString());
}

const getSampleTypeDistribution = (sampled_objects = '', date_from = null, date_to = null, quality_statuses = '', sample_types = '', collectors = '') => {
    const queryParams = new URLSearchParams();
    queryParams.append('date_from', localISOString(date_from));
    queryParams.append('date_to', localISOString(date_to));
    for (let el in sampled_objects) {
        queryParams.append('sampled_object', sampled_objects[el]);
    }
    for (let el in quality_statuses) {
        queryParams.append('quality_status', quality_statuses[el])
    }
    for (let el in sample_types) {
        queryParams.append('sample_type', sample_types[el])
    }

    return instance.get('/samplinglogs/sampletypes?' + queryParams.toString());
}

const getSamplingDetails = (samplingLogId) => {

    return instance.get('/samplinglogs/details/' + samplingLogId);
}

const samplingLogsApi = {
    getSamplingNumbers,
    getQualityLevelDistribution,
    getSampleTypeDistribution,
    getSamplingValues,
    getSamplingDetails
}
export default samplingLogsApi;