import React, { useCallback, useEffect, useState } from 'react';
import { Tile, Text, Loader, Dropdown, Icon } from '@react-gcc-eds/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectStatusCodesConf, selectDataTypesConf } from 'redux/configurationSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { formatDate, deepEqual, exportData } from 'utils';
import SamplesTable from './SamplesTable';
import './table.scss';

import API from 'api';


function SamplesTableContainer(props) {
    const { locations, qualityTypes, sampleTypes, sidebarDispatch } = props;
    const selectedDateRange = useSelector(selectDateRange);
    const samplesSampleTypes = useSelector(selectDataTypesConf);
    const statusCodes = useSelector(selectStatusCodesConf);
    const intl = useIntl();
    const [sampleData, setSampleData] = useState(null);
    const printPDF = Boolean(true);
    
    useEffect(() => {
        setSampleData(null);
        API.samplingLogs.getSamplingValues(locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes)
            .then(items => {
                if (items.data) {
                    const data = items.data.map(collection => {
                        const sampleType = samplesSampleTypes.find(el => el.key === collection.samplingType).value;
                        const status = statusCodes.find(el => el.key === collection.statusCode).value;
                        return {
                            key: collection._id,
                            locationName: collection.sampledObject.name,
                            date: formatDate(collection.timestamp, { getDetailedTime: true }),
                            sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                            quality: intl.formatMessage({ id: 'QUALITY_LEVEL.TSI.' + status }),
                            sampleNotes: collection.notes ? collection.notes : ''
                            // sampleNumber: collection.sampleNumber THIS SHOULD BE IN TABLE INSTED OF sampleNotes
                        }
                    });
                    setSampleData(data);
                }
                else setSampleData([]);
            }).catch(error => {
                setSampleData({ error });
            });
    }, [locations, qualityTypes, sampleTypes, samplesSampleTypes, selectedDateRange, statusCodes, intl]);

    const render = useCallback(() => {
        if (sampleData !== null && sampleData.length) return <SamplesTable sampleData={sampleData} sidebarDispatch={sidebarDispatch} />
        else if (sampleData === null) return <span>
            <Loader size="small" className="loading-data" />
            <Text className="loading-data" size="md">{intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })}</Text>
        </span>;
        else if (sampleData.error) return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>;
        else return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA_TIME_PERIOD' })}</Text>;
    }, [sampleData, sidebarDispatch, intl]);


    const tableHeaders = [
        intl.formatMessage({ id: 'LOCATION.LOCATION' }),
        intl.formatMessage({ id: 'DATE' }),
        intl.formatMessage({ id: 'LOCATION.SAMPLES' }),
        intl.formatMessage({ id: 'LOCATION.QUALITY_LATEST' }),
        intl.formatMessage({ id: 'FORM.NOTES_PLACEHOLDER' })
    ];
    const tableData = sampleData !== null && sampleData.length ? sampleData.map(el => [el.locationName, el.date, el.sampleType, el.quality, el.sampleNotes]) : null;

    return (
        <Tile
            title={<FormattedMessage id="LOCATION.DATA_TABLE" />}
            className='export-table-tile'
            actions={tableData !== null && !!tableData.length ? [
                <Dropdown
                    key="export-dropdown"
                    items={[
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_JSON' }), value: { callback: () => exportData({ getJsonData: true }, intl.formatMessage({ id: 'LOCATION.ALL_LOCATIONS' }), intl.formatMessage({ id: 'LOCATION.SAMPLES' }), tableData), name: 'Export JSOn' } },
                        { title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_CSV' }), value: { callback: () => exportData({ getCsvData: true }, intl.formatMessage({ id: 'LOCATION.ALL_LOCATIONS' }), intl.formatMessage({ id: 'LOCATION.SAMPLES' }), tableData, tableHeaders), name: 'Export CSV' } },
                        {
                            title: intl.formatMessage({ id: 'DROPDOWN_MENU.EXPORT_PDF' }), value: {
                                callback: () => {
                                    const chart = props.chartRef.current;
                                    const base64Image = chart ? chart.toBase64Image() : undefined;
                                    exportData({ getPdfData: true }, intl.formatMessage({ id: 'LOCATION.ALL_LOCATIONS' }), intl.formatMessage({ id: 'LOCATION.SAMPLES' }), tableData, tableHeaders, base64Image)
                                }, name: 'Export PDF'
                            }
                        },
                        {
                            title: intl.formatMessage({ id: 'DROPDOWN_MENU.PRINT_PDF' }), value: {
                                callback: () => {
                                    const chart = props.chartRef.current;
                                    const base64Image = chart ? chart.toBase64Image() : undefined;
                                    exportData({ getPdfData: true }, intl.formatMessage({ id: 'LOCATION.ALL_LOCATIONS' }), intl.formatMessage({ id: 'LOCATION.SAMPLES' }), tableData, tableHeaders, base64Image, printPDF)
                                }, name: 'Print PDF'
                            }
                        }
                    ]}
                    label={[<Icon key="icon" name="download-save" />, intl.formatMessage({ id: 'BUTTON.EXPORT' })]}
                    onItemClick={(item) => item.value.callback()}
                />
            ] : null}
        >
            {render()}
        </Tile >
    );
}

export default React.memo(SamplesTableContainer, (prevProps, nextProps) => deepEqual(prevProps, nextProps, ['locations', 'qualityTypes', 'sampleTypes', 'selectedDateRange']));