import Keycloak from 'keycloak-js';

export const keycloak = Keycloak("/keycloak.json");

// keycloak.onTokenExpired = () => keycloak.updateToken(0);
export const KeycloakLogoutLink = process.env.REACT_APP_KEYCLOAK_BASE_URL + process.env.REACT_APP_KEYCLOAK_LOGOUT_URL + process.env.REACT_APP_UI_BASE_URL;

const AuthKeycloak = (option) => {
   return keycloak.init({ onLoad: option });
}


export default AuthKeycloak;