import React, { useState } from 'react';
import { Carousel, Slide } from '@react-gcc-eds/core';

import './pictureGallery.scss';

function PictureGallery(props) {
    const { pictures } = props;
    const [active, setActive] = useState(0)
    return <div className={`picture-gallery${props.single ? ' single' : ''}`}>
        <Carousel
            key={active}
            current={active}
            onClick={(page) => { setActive(page) }}
            onLeftClick={() => { if (active !== 0) setActive(active - 1) }}
            onRightClick={() => { if (active !== pictures.length - 1) setActive(active + 1) }}
            slides={pictures.map((img, index) =>
                <Slide active={index === active} key={index}>
                    <img className='picture' src={img.url} alt={img.name} />
                </Slide>
            )}
        />
    </div>
}

export default PictureGallery;