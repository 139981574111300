import React, { useEffect, useState, useCallback } from 'react';
import { Loader, Text } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectStatusCodesConf } from 'redux/configurationSlice';
import { DoughnutChart } from 'charts'
import { deepEqual } from 'utils';


import API from 'api';


const SamplingLogQualityDistributionPie = (props) => {
  const { locations, sampleTypes, qualityTypes } = props;
  const selectedDateRange = useSelector(selectDateRange);
  const samplingQuality = useSelector(selectStatusCodesConf);
  const intl = useIntl();

  const [data, setData] = useState(null);

  const convertData = useCallback((apiData) => {
    const chartData = {
      labels: [],
      datasets: [{ data: [] }]
    }
    apiData.forEach(row => {
      const quality = `QUALITY_LEVEL.TSI.${samplingQuality.find(el => el.key === row._id).value}`;
      chartData.labels.push(intl.formatMessage({ id: quality }));
      chartData.datasets[0].data.push(row.count);
    });
    setData({ ...chartData });
  }, [setData, intl, samplingQuality]);

  useEffect(() => {
    setData(null);
    //date_from = null, date_to = null, sample_types = '', sampling_object = ''
    API.samplingLogs.getQualityLevelDistribution(locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes)
      .then(items => {
        if (items.data && items.data.length) {
          // store data in state
          convertData(items.data);
        }
        else setData({});
      }).catch(error => {
        setData({ error: error });
      });
  }, [locations, selectedDateRange, sampleTypes, qualityTypes, convertData])

  if (data !== null && data.datasets) return <DoughnutChart name={"qualityDoughnutChart_" + locations[0]} data={data} />;
  else if (data === null) return <Loader size="small" />;
  else if (data.error) return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>;
  else return <Text size="md">{intl.formatMessage({ id: 'LOCATION.NO_DATA_TIME_PERIOD' })}</Text>
}

export default React.memo(SamplingLogQualityDistributionPie, (prevProps, nextProps) => deepEqual(prevProps, nextProps));