import React from 'react';
import { Text, Icon } from '@react-gcc-eds/core';
import './SideDrawer.scss';

const concatClasses = function (...args) {
    const classNames = [];
    for (let index = 0; index < args.length; index++) {
        if(!args[index]) continue;
        classNames.push(args[index]);
    }
    return classNames.join(' ');
}

const SideDrawer = (props) => {
    const { state, className } = props;
    return (
        <div key={state} className={concatClasses((state.open ? "sidebar open" : "sidebar"), className, state.width)}>
            <div className="title">
                <div>{state.title}</div>
                <Text as="span" size="sm">{state.subtitle}</Text>
            </div>
            <Text className="close" size="lg">
                <Icon
                    name="cross"
                    onClick={() => props.onClose()}
                    base
                />
            </Text>
            <div className="content">
                {state.display()}
            </div>
        </div>
    );
}

export default SideDrawer;