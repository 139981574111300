import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { AppView, EmptyState, Table, Tile, Row, Tooltip, Button, Text, Icon, useSettings } from '@react-gcc-eds/core'
import { SideDrawer, LocationDetailsForm } from 'components';
import { useSelector } from 'react-redux';
import { selectLocations } from 'redux/locationsSlice';
import { isAdminSelector } from 'redux/userSlice';
import { useHistory } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { AspectRatio } from 'react-aspect-ratio';
import './locationsListPage.scss'

function LocationsListPage(props) {
    const intl = useIntl();
    const history = useHistory();
    const settingsVisible = useSettings().settingsVisible;
    const isAdmin = useSelector(isAdminSelector);

    // is control collapsed
    const [cardViewFlag, setCardViewFlag] = useState(true);

    // get all locations
    const locations = useSelector(selectLocations);

    /** Toggle collapse mode - helper function  */
    const toggleView = () => {
        cardViewFlag ? setCardViewFlag(false) : setCardViewFlag(true);
    }

    const [sidebarState, sidebarDispatch] = useReducer((state, action) => {
        const payload = action.payload;
        switch (action.type) {
            case 'OPEN':
                return {
                    ...state,
                    open: true
                }
            case 'CLOSE':
                return {
                    ...state,
                    title: '',
                    subtitle: '',
                    display: () => null,
                    open: false
                }
            case 'SET':
                return {
                    ...state,
                    title: payload.title,
                    subtitle: payload.subtitle,
                    display: payload.display,
                    width: payload.width
                }
            default:
                break;
        }
    }, {
        title: '',
        subtitle: '',
        display: () => null,
        open: false,
        width: null
    });
    const sidebarCallback = useCallback((type, data) => {
        sidebarDispatch({ type: type, payload: { ...data } });
    }, [sidebarDispatch]);

    useEffect(() => {
        if (settingsVisible) sidebarCallback('CLOSE');
    }, [settingsVisible, sidebarCallback]);

    const handleButtonClick = () => {
        sidebarCallback('SET', {
            title: <FormattedMessage id="FORM.NEW_LOCATION" />,
            subtitle: <FormattedMessage id="FORM.NEW_LOCATION.SUB" />,
            display: () => <LocationDetailsForm sidebarDispatch={sidebarCallback}/>,
            width: 'wide'
        })
        sidebarCallback('OPEN');

    }

    // render approprite component (table/cards) based on state and fill it with data from Redux
    const renderView = useCallback(() => {


        if (locations && locations.length) {
            if (cardViewFlag) {
                // render cards

                // define table rows
                const cards = locations.map(location => {
                    const content = (<>
                        <Text key="position" size='md'><Icon name='position' /> Lat: {location.position[0].toFixed(5)}, Lon: {location.position[1].toFixed(5)} </Text>
                        <AspectRatio key="image" ratio="16/9" >
                            <img className="cover-image" src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + location.picture} alt={location.name} onClick={() => history.push('/location/' + location._id)} />
                        </AspectRatio>
                        <Text key="description" as="div">{location.description[intl.locale]}</Text>
                    </>)
                    return (
                        <Tile key={location._id}
                            sm={12} md={6} lg={4} className="location-card"
                            title={<Text as="div">{location.name}</Text>}
                            subtitle={location.measurePoints.length ? <span><Icon key="measurePoints" name='signal' /> {location.measurePoints.length} <FormattedMessage id="LOCATION.MEASURE_POINTS" /> </span> : null}
                            actions={[
                                <Tooltip
                                    key="details"
                                    position="left"
                                    text={intl.formatMessage({ id: 'LOCATION.DETAILS' })}
                                    type="pointer"
                                >
                                    <Icon className="details-link" name="info" onClick={() => history.push('/location/' + location._id)} />
                                </Tooltip>]}
                        >
                            {content}
                        </Tile >
                    )
                });

                // return return component
                return (
                    <Row key="gallery" className='locations-list-cards-container'>
                        {cards}
                    </Row>
                );
            } else {
                //render table
                // define table columns
                const tableColumns = [
                    { header: intl.formatMessage({ id: 'LOCATION.NAME' }), key: 'name' },
                    { header: intl.formatMessage({ id: 'LOCATION.DESCRIPTION' }), key: 'description' },
                    { header: intl.formatMessage({ id: 'LOCATION.MEASURE_POINTS' }), key: 'mpList' },
                    { header: intl.formatMessage({ id: 'LOCATION.POSITION' }), key: 'position' },
                ];

                // define table rows
                const tableRows = locations.map(location => {
                    const measurePoints = location.measurePoints.map(mp => mp.name);
                    return {
                        key: location._id,
                        name: location.name,
                        description: location.description[intl.locale],
                        mpList: measurePoints.length > 0 ? measurePoints.length : intl.formatMessage({ id: 'LOCATION.NO_DATA' }),
                        position: location.position[0].toFixed(5) + '\n' + location.position[1].toFixed(5)
                    }
                });
                // return table component
                return <Table
                    key="list" className='locations-list-table-container'
                    columns={tableColumns} rows={tableRows}
                    onRowClick={(location) => history.push('/location/' + location.key)}
                    splitCellStyle="dashed"
                />
            }
        } else {
            // show 'no data' message by default
            return <EmptyState key="noData" message={intl.formatMessage({id: 'APP.COMING_SOON'})} />
        }
    }, [locations, cardViewFlag, history, intl]);


    return (
        <AppView title={intl.formatMessage({ id: 'LOCATION.LOCATIONS' })}
            menu={props.menu()}
            className="list-page"
            actions={[
                <Tooltip
                    key="addLocation"
                    position="left"
                    text={intl.formatMessage({ id: 'LOCATION.ADD_NEW.TOOLTIP' })}
                    type="pointer"
                >
                    <Button disabled={!isAdmin} iconName="plus" className="view-toggle" onClick={handleButtonClick} >{intl.formatMessage({ id: 'LOCATION.ADD' })}</Button>
                </Tooltip>,
                <Tooltip
                    key="toggleView"
                    position="left"
                    text={intl.formatMessage({ id: cardViewFlag ? 'LOCATION.LIST_VIEW' : 'LOCATION.GALLERY_VIEW' })}
                    type="pointer"
                >
                    <Icon className="view-toggle" name={cardViewFlag ? 'view-list' : 'view-tiles'} onClick={toggleView} />
                </Tooltip>,
            ]}
        >
            <SideDrawer
                state={sidebarState}
                onClose={() => sidebarCallback('CLOSE')}
            />
            {renderView()}
        </AppView>
    );
}

export default LocationsListPage;
