import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Breadcrumb } from '@react-gcc-eds/core';
import { selectLocationDetails, selectLocationParent } from 'redux/locationsSlice';
import { useIntl } from 'react-intl';
import 'react-aspect-ratio/aspect-ratio.css';
import'./LocationBreadcrumb.scss';

const LocationBreadcrumb = (props) => {
    const { locationId } = props; //TODO: can this be improved? This way, component will be re-rendered on each location change, even for props that are not used!
    const history = useHistory();
    const intl = useIntl();
    const path = useLocation().pathname.split('/');
    const samplesReporting = path[1] === 'samples';
    const measurementsReporting = path[1] === 'measurements';
    const historyReport = path[3] === 'report';
    const thresholdsDetails = path[1] === 'thresholds';
    const contactsDetailsPage = path[1] === 'contacts';

    let locationAction;
    if (samplesReporting || measurementsReporting || thresholdsDetails || historyReport || contactsDetailsPage) {
        locationAction = {
            callback: () => history.push('/location/' + locationId)
        }
    } else {
        locationAction = {
            callback: () => { }
        }
    }

    // get location details from Redux
    const thisLocation = useSelector(state => selectLocationDetails(state, locationId));

    // if parent location exist, get it's info too
    const parentLocation = useSelector(state => selectLocationParent(state, locationId));
    

    const items = [];
    items.push({ action: { callback: () => history.push('/locations'), }, title: intl.formatMessage({ id: 'LOCATION.LOCATIONS' }) })
    if (parentLocation) items.push({ action: { callback: () => history.push('/location/' + parentLocation._id), }, title: parentLocation.name });
    if (thisLocation) items.push({ action: locationAction, title: thisLocation.name });
    else if (locationId && !thisLocation) items.push({ action: () => { }, title: intl.formatMessage({ id: 'LOCATION.MISSING_SUBTITLE' }) });
    if (samplesReporting) items.push({ action: { callback: () => { } }, title: intl.formatMessage({ id: 'LOCATION.SAMPLES' }) });
    if (measurementsReporting) items.push({ action: { callback: () => { } }, title: intl.formatMessage({ id: 'LOCATION.MEASUREMENTS' }) });
    if (historyReport) items.push({ action: { callback: () => { } }, title: intl.formatMessage({ id: 'FORM.REPORT' }) });
    if (thresholdsDetails) items.push({ action: { callback: () => { } }, title: intl.formatMessage({ id: 'LOCATION.THRESHOLDS' }) });
    if (contactsDetailsPage) items.push({action: { callback: () => { } }, title: intl.formatMessage({ id: 'CONTACTS' }) })

    return (
        <Breadcrumb items={items} onItemClick={(data) => data.action.callback(props)} />
    );
}

export default LocationBreadcrumb;