import L from 'leaflet';

import pinMarker from './icons/position-icons/pin-solid.svg';
import positionMarker1 from './icons/position-icons/position-status-1.svg';
import positionMarker2 from './icons/position-icons/position-status-2.svg';
import positionMarker3 from './icons/position-icons/position-status-3.svg';
import positionMarker4 from './icons/position-icons/position-status-4.svg';
import positionMarker5 from './icons/position-icons/position-status-5.svg';
import positionMarkerUnknown from './icons/position-icons/position-unknown.svg';

import pointMarker1 from './icons/measurePoint-icons/point-status-1.svg';
import pointMarker2 from './icons/measurePoint-icons/point-status-2.svg';
import pointMarker3 from './icons/measurePoint-icons/point-status-3.svg';
import pointMarker4 from './icons/measurePoint-icons/point-status-4.svg';
import pointMarker5 from './icons/measurePoint-icons/point-status-5.svg';
import pointMarkerUnknown from './icons/measurePoint-icons/point-unknown.svg';


import nodeClusterMarker from './icons/node-network.svg'
import hardwarePlatformMarker from './icons/hardware-platform.svg'
import nodeMarker from './icons/node.svg'
import signalMarker from './icons/signal.svg'
import dot from './icons/dot.svg'

const clusterMarker = () => {
    return new L.Icon({
        iconUrl: dot,
        iconRetinaUrl: dot,
        iconSize: new L.Point(0, 0),
        className: 'icon cluster'
    });
}

const positionMarker = (statusCode) => {
    let markerIcon, iconSize;
    switch (statusCode) {
        case 0:
            markerIcon = positionMarkerUnknown
            break;            
        case 1:
            markerIcon = positionMarker1
            break;
        case 2:
            markerIcon = positionMarker2
            break;
        case 3:
            markerIcon = positionMarker3
            break;
        case 4:
            markerIcon = positionMarker4
            break;
        case 5:
            markerIcon = positionMarker5
            break;
        default:
            markerIcon = pinMarker;
            iconSize = new L.Point(20, 20);
            break;
    }

    return new L.Icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon,
        iconSize: !!iconSize ? iconSize : new L.Point(40, 40),
        className: 'icon icon-position'
    });
}

const measurePointMarker = (statusCode) => {
    let markerIcon;
    switch (statusCode) {
        case 1:
            markerIcon = pointMarker1
            break;
        case 2:
            markerIcon = pointMarker2
            break;
        case 3:
            markerIcon = pointMarker3
            break;
        case 4:
            markerIcon = pointMarker4
            break;
        case 5:
            markerIcon = pointMarker5
            break;
        default:
            markerIcon = pointMarkerUnknown
            break;
    }

    return new L.Icon({
        iconUrl: markerIcon,
        iconRetinaUrl: markerIcon,
        iconSize: new L.Point(9, 9),
        className: 'icon icon-alarm-level5'
    });
}

const customNodeClusterMarker = new L.Icon({
    iconUrl: nodeClusterMarker,
    iconRetinaUrl: nodeClusterMarker,
    iconSize: new L.Point(25, 25),
    className: 'icon icon-node-network'
});

const customNodeMarker = new L.Icon({
    iconUrl: nodeMarker,
    iconRetinaUrl: nodeMarker,
    iconSize: new L.Point(20, 20),
    className: 'icon icon-node'
});

const customHardwareMarker = new L.Icon({
    iconUrl: hardwarePlatformMarker,
    iconRetinaUrl: hardwarePlatformMarker,
    iconSize: new L.Point(20, 20),
    className: 'icon icon-hardware-platform'
});

const customSignalMarker = new L.Icon({
    iconUrl: signalMarker,
    iconRetinaUrl: signalMarker,
    iconSize: new L.Point(20, 20),
    className: 'icon icon-signal'
});

export { clusterMarker, positionMarker, measurePointMarker, customNodeClusterMarker, customNodeMarker, customHardwareMarker, customSignalMarker };