import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectResourcesTypesConf, selectStatusCodesConf, selectStatusCodesTypesConf } from "redux/configurationSlice";
import { userSelector, isUserSelector } from 'redux/userSlice';
import { AddThreshold, ThresholdResourceSelect, NumberFieldThreshold } from 'components';
import { Text, Table, Button } from '@react-gcc-eds/core';
import { groupByKey, deepEqual } from 'utils';
import API from "api";
import "./ThresholdOverview.scss";

function ThresholdOverview(props) {
    const intl = useIntl();
    const [editMode, setEditMode] = useState(false);
    const [thresholdsData, setThresholdsData] = useState({});
    const [errMsg, setErrMsg] = useState("");
    const history = useHistory();
    const resourceTypesConf = useSelector(state => selectResourcesTypesConf(state));
    const statusCodesConf = useSelector(state => selectStatusCodesConf(state));
    const statusCodesTypes = useSelector(selectStatusCodesTypesConf);
    const user = useSelector(userSelector);
    const isUser = useSelector(isUserSelector)
    // sort Status Codes in descending order
    const statusKeys = statusCodesConf.filter(status=> status.key !== 0)
        .sort((a,b) => a.key > b.key ? -1 : a.key < b.key ? 1 : 0);

    const threshold = Object.values(props.thresholds)[0];
    const location = props.location;

    let thresholdArray = useRef([]);
    // initialize threshold state
    useEffect(() => {
        if(threshold.length){
            thresholdArray.current = threshold;
            let thresholds = groupByKey(threshold, "_id");
            setThresholdsData(thresholds);
        }
    }, [threshold])

    const switchOnEditMode = () => {
        setEditMode(true)
        setErrMsg("");
    }

    const render = useCallback(() => {

        const updateDataThreshold = (addedThreshold) => {

            const found = thresholdArray.current.findIndex(el =>
                el.qLevel === addedThreshold.qLevel && el.resourceTypeKey === addedThreshold.resourceTypeKey);
            if(found === -1) thresholdArray.current.push(addedThreshold);

            let thresholds = groupByKey(thresholdArray.current, "_id");
            setThresholdsData(thresholds);
        }

        // on Cancel return to initial state of thresholds
        const cancelEditMode = () => {
            setEditMode(false);
            setErrMsg("");
            
            const cancelThresholds = Object.values(thresholdsData).map(el => el[0])
            thresholdArray.current = cancelThresholds;

            let thresholds = groupByKey(cancelThresholds, "_id");
            setThresholdsData(thresholds);
        }

        const handleThresholdChange = (updatedThreshold) => {
            setErrMsg("");
            Object.assign(updatedThreshold, {
                locationId: location._id,
                name: Object.keys(props.thresholds)[0],
            })
            const found = thresholdArray.current.findIndex(el => el.qLevel === updatedThreshold.qLevel && el.resourceTypeKey.toString() === updatedThreshold.resourceTypeKey.toString());
            if(found > -1) thresholdArray.current[found] = updatedThreshold;
            else thresholdArray.current.push(updatedThreshold);
        }

        const submitThresholds = () => {
        // compare original threshold with threshold in state
            thresholdArray.current.forEach(el => {
                //if exist compare them
                if( thresholdsData[el._id] ) {
                    // if true, nothing changed, if false, threshold needs to be updated
                    const isEqual = deepEqual(el, thresholdsData[el._id][0], Object.keys(el));
                    if( !isEqual ) {
                        //for thresholds with changed values call API to update threshold with _id
                        API.thresholds.updateThreshold(user.token, el._id, el)
                        .then((response) => {
                            if ( response.status === 200 ) {
                                const index = thresholdArray.current.findIndex(el => el._id === response.data._id)
                                thresholdArray.current[index] = response.data;
                                setThresholdsData(groupByKey(thresholdArray.current, "_id"));
                                
                                // in some cases thresholdArray.current doesn't update immedietly even though request is successful
                                // after refresh change is visible, updating threshold again before refresh will result in error.
                                // so refresh in those cases is necessary. 
                                if(response.data.min !== thresholdsData[response.data._id].min || 
                                    response.data.max !== thresholdsData[response.data._id].max) 
                                    setTimeout(() => history.go(0), 200);
                                
                            }   
                        }).catch((error) => {
                            if(error.message.includes('403')) setErrMsg(intl.formatMessage({id: "THRESHOLDS.FORBIDDEN_ERROR"}))
                            else setErrMsg(error.message)
                        });
                    }
                }
            });

            // for new thresholds call API to create threshold with threshold object
            if( Object.keys(thresholdsData).length !== thresholdArray.current.length ) {
                const diff = thresholdArray.current.filter(el => el._id === null);
                if ( diff.length > 0 ) {
                    diff.forEach((newThreshold) => {
                        delete newThreshold._id;
                        API.thresholds.postThreshold(user.token, newThreshold).then((response) => {
                            if ( response.status === 200 ) {
                                thresholdArray.current.push(response.data);

                                setThresholdsData(groupByKey(thresholdArray.current, "_id"));
                                // cancel edit mode
                                setEditMode(false);
                            }
                        }).catch((error) => {
                            if(error.message.includes('403')) setErrMsg(intl.formatMessage({id: "THRESHOLDS.FORBIDDEN_ERROR"}))
                            else setErrMsg(error.message)
                            return;
                        })
                    })
                }
            }
            else {
                // cancel edit mode
                setEditMode(false);
            }

        }

        if(thresholdArray.current.length && Object.keys(thresholdsData).length) {
            const resourceThreshold = Object.entries(groupByKey(thresholdArray.current, "resourceTypeKey")).map(([key, value]) => ({ key:key, value:value }));
    
            // HEADERS
            let tableColumns = statusKeys.map(el => {
                return {
                    header: intl.formatMessage({ id: "QUALITY_LEVEL.TSI." + el.value }), 
                    key: el.key,
                    Cell: (props) => (
                        <NumberFieldThreshold 
                            qLevel={el.key}
                            resourceKey={props[el.key].resourceTypeKey}
                            valueMin={props[el.key].min}
                            valueMax={props[el.key].max}
                            readonly={!editMode}
                            handleThresholdChange={handleThresholdChange}
                            thresholdId={props[el.key].threshold ? props[el.key].threshold._id : null}
                        />
                    )
                }
            });
            tableColumns = [{ freeze: true, header: "", key: "resourceTypeKey" }, ...tableColumns]
    
            // ROWS
            const tableRows = resourceThreshold.map(resource => {
                const resourceType = resourceTypesConf.find(el => el.key.toString() === resource.key.toString())
                let thresholdRow = {
                    resourceTypeKey: intl.formatMessage({ id: resourceType.name })+" ("+ resourceType.unit +") ",
                };        
                
                const filteredByResource = Object.fromEntries(
                    Object.entries(thresholdsData)
                    .filter(([key, value]) => value[0].resourceTypeKey.toString() === resource.key.toString()) )
                
                // add threshold that wasn't there before to track its value
                if(resource.value.length !== Object.keys(filteredByResource).length) {
                    const diff = Object.values(filteredByResource).filter(x => resource.value.map(y => y._id).indexOf(x[0]._id) < 0)[0]
                    if(diff && diff.length > 0) diff.forEach(el => resource.value.push(el));
                }
    
                resource.value.sort((a,b) => a.qLevel > b.qLevel ? -1 : a.qLevel < b.qLevel ? 1 : 0).map(el => {
                    if(el){  
                        return Object.assign(thresholdRow, { 
                            [el.qLevel]: {min: el.min, max: el.max, threshold: el, resourceTypeKey: resource.key} 
                        });
                    }else return Object.assign(thresholdRow, {});
                });
                statusKeys.forEach(status => {
                    if( thresholdRow[status.key] === undefined ) Object.assign(thresholdRow, {[status.key]: {resourceTypeKey: resource.key}})
                })
                return thresholdRow; 
            });

            return <>
                {user.token ? <Button disabled={!isUser} onClick={switchOnEditMode}>{intl.formatMessage({id: "BUTTON.EDIT"})}</Button> : null}
                <div className="thresholds-error-message">{errMsg}</div>
                <Table columns={tableColumns} rows={tableRows} scrollHorizontal className="thresholds-overview-table"/> 
                {editMode ? 
                    <div className="button-group-bottom" >
                        <Button onClick={cancelEditMode}>{intl.formatMessage({id: "BUTTON.CANCEL"})}</Button>
                        <Button primary disabled={errMsg} onClick={submitThresholds}>{intl.formatMessage({id: "BUTTON.SAVE"})}</Button>
                    </div>
                : null}
                
                { user.token && Object.keys(props.thresholds)[0] === 'TSI' ? 
                <div className="button-group-left">
                    <ThresholdResourceSelect thresholds={props.thresholds} location={location} updateDataThreshold={updateDataThreshold}/>
                </div> : null }
            </>
        }    
        else return <div> 
            <Text size="md" className="no-thresholds">{intl.formatMessage({ id: 'THRESHOLDS.NO_DATA' })}</Text>
            { user.token ? 
            Object.keys(props.thresholds)[0] === 'TSI' && props.location.resourceTypes.filter(x => x > 3000 && x < 4000).length ?
            <div className="button-group-left" >
                    <ThresholdResourceSelect thresholds={props.thresholds} location={location} updateDataThreshold={updateDataThreshold}/>
                </div>
            : props.location.resourceTypes.filter(x => x > 3000 && x < 4000).length ?
                <div className="button-group-left button-add-new" >
                    <AddThreshold thresholds={props.thresholds} location={location} updateDataThreshold={updateDataThreshold} selectedResource={statusCodesTypes.find(code => code.key === Object.keys(props.thresholds)[0]).resource[0]}/>
                </div> : null : null}
        </div>
        
    }, [
        editMode,  
        intl, 
        resourceTypesConf, 
        statusKeys, 
        statusCodesTypes,
        thresholdsData, 
        user.token,
        isUser,
        location,
        errMsg,
        history,
        props
    ])

    return render();
}

export default ThresholdOverview;