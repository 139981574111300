import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { Button, Checkbox, Loader, Text } from '@react-gcc-eds/core';
import { HistoryDataReport } from 'components';
import './historyDataForm.scss';

import API from 'api';

const HistoryDataForm = (props) => {
    const { sidebarDispatch } = props;
    const { locationId } = useParams();
    const token = useSelector(userSelector).token;
    const intl = useIntl();
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [submit, setSubmit] = useState(0);
    const [skipFlag, setSkipFlag] = useState(false);

    const handleInputChange = event => {
        setFile(event.target.files[0]);
    }

    const handleInputClick = event => {
        event.target.value = null;
        setFile(null);
    }

    const handleClick = event => {
        fileInputRef.current.click();
    }

    useEffect(() => {
        if (submit && file !== null && !!file.name) {
            sidebarDispatch('SET', {
                title: intl.formatMessage({ id: 'LOCATION.LOADING_DATA' }) + '...',
                subtitle: intl.formatMessage({ id: 'APP.WAIT' }),
                display: () => <Loader size="large" />
            });
            API.measurements.postHistoryMeasurements(token, [locationId], file, skipFlag).then(({ data, status }) => {
                setTimeout(sidebarDispatch('SET', {
                    title: <FormattedMessage id='HISTORY_REPORT.TITLE' />,
                    display: () => <HistoryDataReport reportData={data} statusCode={status} sidebarDispatch={sidebarDispatch} />
                }), 500);
            }).catch(error => {
                let errorMsg;
                const response = error.response;
                if(error.response.status === 403) errorMsg = "ACCESS_DENIED";

                setTimeout(sidebarDispatch('SET', {
                    title: <FormattedMessage id='HISTORY_REPORT.ERROR' />,
                    display: () => <HistoryDataReport message={errorMsg || response.data.error || "UNKNOWN"} sidebarDispatch={sidebarDispatch} />
                }), 500)
            });
            setSubmit(false);
        }
    }, [file, submit, setSubmit, intl, locationId, sidebarDispatch, skipFlag, token]);

    return (
        <div className='history-form'>
            <Text as='span'>{intl.formatMessage({ id: !file ? "FORM.SELECT_FILE" : "FORM.SELECTED_FILE" })} </Text>
            <Button className='upload-history-input' iconName={!file ? 'upload' : 'document'} onClick={handleClick}>
                {file && file.name ? file.name : intl.formatMessage({ id: "FORM.UPLOAD" })}
            </Button>
            <input
                name="file"
                type="file"
                accept={'.xlsx, .csv'}
                ref={fileInputRef}
                onChange={handleInputChange}
                onClick={handleInputClick}
                style={{ display: "none" }}
            />
            <br />
            <br />
            <Text as='span'>{intl.formatMessage({ id: "FORM.SKIP_CHECK" })} </Text>
            <Checkbox
                className='skip-checkbox'
                checked={skipFlag}
                onChange={setSkipFlag}
            />
            <br />
            <br />
            <div className='history-upload-button'>
                <Button primary disabled={file === null} className='upload-history-next' onClick={() => setSubmit(true)}>
                    {intl.formatMessage({ id: "FORM.NEXT" })}
                </Button>
            </div>
        </div>
    );
}

export default HistoryDataForm;