import { Loader, Pagination, Table, Text, Tile } from "@react-gcc-eds/core";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import "./ContactsTable.scss"

function ContactsTable(props) {
    const { contactsData } = props;
    const intl = useIntl();
    
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = (page - 1) * rowsPerPage + rowsPerPage;

    const render = useCallback(() => {
        const rows = contactsData && contactsData.length ? contactsData.slice(startIndex, endIndex) : [];

        const headers = [
            {
                header: intl.formatMessage({ id: 'LOCATION.LOCATION' }),
                key: 'locationName'
            },
            { 
                header: intl.formatMessage({ id: 'CONTACTS_USERNAME' }),
                key: 'username'
            },
            { 
                header: intl.formatMessage({ id: 'CONTACTS_FIRST_NAME' }),
                key: 'firstName'
            },
            { 
                header: intl.formatMessage({ id: 'CONTACTS_LAST_NAME' }),
                key: 'lastName'
            },
            {
                header: intl.formatMessage({ id: 'CONTACTS_EMAIL' }),
                key: 'email'
            }
        ];

        if (contactsData !== null && contactsData && contactsData.length) return (
            <div className="contacts-table-page">
                <Table
                    className="contacts-table"
                    columns={headers}
                    rows={rows}
                    paginaton={
                        <Pagination 
                            activePage={page}
                            currentItemsPerPage={rowsPerPage}
                            itemsPerPageOptions={[10, 50, 100]}
                            onItemsPerPageChanged={(n) => setRowsPerPage(n)}
                            onLeftClick={(n) => setPage(n)} onRightClick={(n) => setPage(n)}
                            onPageChanged={(n) => setPage(n)} onPageClick={(n) => setPage(n)}
                            pages={Math.ceil(contactsData.length / rowsPerPage)}
                            showItemsPerPageSelector
                            showPageSelector
                        />
                    }
                    tableInfo={{
                        show: {
                            from: startIndex + 1,
                            to: endIndex > contactsData.length ? contactsData.length : endIndex
                        },
                        total: contactsData.length
                    }}
                />
            </div>
        )
        else if (contactsData === null) return <span>
            <Loader size="small" className="loading-data" />
            <Text className="loading-data" size="md">{intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })}</Text>
        </span>;
        else if (contactsData.error) return <Text size="md">{intl.formatMessage({ id: 'CONTACTS.NO_DATA' })}</Text>;
        else return <Text size="md">{intl.formatMessage({ id: 'CONTACTS.NO_GROUP' })}</Text>;

    }, [contactsData, intl, endIndex, startIndex, page, rowsPerPage])

    return (
        <Tile
            title={intl.formatMessage({id: 'CONTACTS.TABLE'})}
            className='contacts-table-tile'
        >
            { render() }
        </Tile>
    );
}

export default ContactsTable;