import React, { useEffect, useState } from 'react';
import { Loader, Row, Text } from '@react-gcc-eds/core';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import './latestMeasurements.scss'
import API from 'api';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'utils';

const LatestMeasurements = (props) => {
    const { locationId, tbLocation } = props;
    const intl = useIntl();

    // store  data in state
    const [data, setData] = useState(null);

    const resourceTypesConf = useSelector(selectResourcesTypesConf);

    // get latest measurements data
    useEffect(() => {
        setData(null);
        let api_req;
        if (tbLocation) api_req = API.measurements.getTBLocationLatest([locationId], resourceTypesConf.map(el => el.key));
        else api_req = API.measurements.getLocationLatest([locationId], resourceTypesConf.map(el => el.key));
        api_req.then(items => {
            if (items.data && items.data.length) {
                const values = items.data[0].values;
                // store data in state
                setData(values.map(latest => {
                    const resType = resourceTypesConf.find(rt => rt.key === latest.resourceKey);
                    if (resType) {
                        return {
                            label: intl.formatMessage({ id: resType.name }),
                            unit: resType.unit,
                            value: parseFloat(latest.value).toFixed(2),
                            timeStamp: formatDate(latest.timestamp, { getDetailedTime: true }),
                            resourceType: resType.key
                        }
                    } else {
                        return null;
                    }
                }).filter(el => el && !isNaN(el.value)))
            }
            else setData([]);
        })
        return;
    }, [locationId, resourceTypesConf, tbLocation, intl]);

    const LatestKpis = () => {
        let latestMeasurements = [];
        if (!(data && data.length)) {
            return <Text><FormattedMessage id="LOCATION.NO_DATA" /></Text>
        } else {
            const latestSensorData = data.filter(el => el.resourceType > 2000 && el.resourceType < 3000);
            const latestData = latestSensorData.length ? 
            latestSensorData : data.filter(el => el.resourceType < 2000 );

            latestData.forEach((chunk, index) => {
                if(chunk){
                    let element = <div className="stats-block" key={"column-1-" + index}>
                        <Text>{chunk.label}</Text>
                        <Text as="span" size="xl">{chunk.value} </Text>
                        <Text as="span">{chunk.unit}</Text>
                        <Text size="sm">{chunk.timeStamp}</Text> <br />
                    </div>
                    latestMeasurements.push(element);
                }
            });
            return latestMeasurements
        }
    }

    if (data !== null)
        return (
            <div>
                <Row asContainer lg={3} md={6} sm={12} name={"latestMeasurementsRow" + locationId} >
                    <div className="latest-measurements">
                        <LatestKpis />
                    </div>
                </Row>
            </div>
        );

    else if (data === null) return <span>
        <Loader size="small" className="loading-data" />
        <Text className="loading-data" size="md">{intl.formatMessage({ id: 'LOCATION.LOADING_DATA' })}</Text>
    </span>;
    else return <Text size="md" >{intl.formatMessage({ id: 'LOCATION.NO_DATA' })}</Text>
}

export default LatestMeasurements;