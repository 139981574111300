import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchCount } from './counterAPI';
import API from 'api';

const initialState = {
  data: [],
  status: 'idle',
  error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchConfiguration = createAsyncThunk( 'configuration/fetchConfiguration', async () => {
    const response = await API.configuration.getConfiguration();
    // The value we return becomes the `fulfilled` action payload
    console.log('CONFIGURATION LOADED');
    return response.data;
  }
);

export const counterSlice = createSlice({
  name: 'configuration',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConfiguration.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload;
      });
  },
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTypologyConf = (state) => state.configuration.data.typology;
export const selectStatusCodesConf = (state) => state.configuration.data.statusCodes;
export const selectDataTypesConf = (state) => state.configuration.data.samplingTypes;
export const selectResourcesTypesConf = (state) => state.configuration.data.resourceTypes;
export const selectStatusCodesTypesConf = (state) => state.configuration.data.statusCodesTypes;
export const selectLocalizationConf = (state) => state.configuration.data.localization;


export default counterSlice.reducer;
