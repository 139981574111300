import {
    clusterMarker,
    positionMarker,
    measurePointMarker,
    customNodeClusterMarker,
    customNodeMarker,
    customHardwareMarker,
    customSignalMarker
} from './Markers';

const LeafletMarkers = {
    clusterMarker,
    positionMarker,
    measurePointMarker,
    customNodeClusterMarker,
    customNodeMarker,
    customHardwareMarker,
    customSignalMarker
};
export default LeafletMarkers;