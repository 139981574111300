const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Ericsson Hilda',
                    size: 14,
                },
                color: {
                    color: '#000',
                    borderColor: '#fff'
                }
            }
        },
        tooltip: {
            usePointStyle: true,
            position: 'nearest',
            bodyFont: {
                family: 'Ericsson Hilda',
            },
            titleFont: {
                family: 'Ericsson Hilda'
            },
            callbacks: {
                labelPointStyle: (context) => { return { pointStyle: 'circle' } }
            }
        }
    },
    scales: {
        x: {
            ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                font: {
                    family: 'Ericsson Hilda',
                    size: 12,
                },
                color: {
                    color: '#000',
                }
            }
        },
        y: {
            ticks: {
                autoSkip: true,
                maxTicksLimit: 12,
                font: {
                    family: 'Ericsson Hilda',
                    size: 12,
                },
                color: {
                    color: '#000',
                }
            }
        }
    }
};

export default defaultOptions;