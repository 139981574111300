import React, { useCallback, useState } from 'react';
import { useMap, useMapEvent, Polyline, GeoJSON, LayerGroup } from 'react-leaflet';
import { MapMarker } from 'components';
import { useSelector } from 'react-redux';
import { selectLocationFilters } from 'redux/locationsSlice';
import { selectMapProps } from 'redux/mapSlice';
import { selectLocations, selectMeasurePoints } from 'redux/locationsSlice';
import './mapElements.scss'

/****************************
*       HELPER FUNCTIONS          
*****************************/

const mapConnections = (locations) => {
    var connections = [];
    for (var index in locations) {
        if (locations[index].connections !== undefined)
            connections.push(...locations[index].connections);
    }
    return connections;
}


/****************************
*       MAIN COMPONENT          
*****************************/

function MapElements(props) {
    const { locationId, positionInput } = props;
    const hidePopups = !!locationId;
    const locations = useSelector(state => selectLocations(state, locationId));
    const connections = mapConnections(locations);
    const measurePoints = useSelector(state => selectMeasurePoints(state, locationId));
    const map = useMap();
    const filters = useSelector(selectLocationFilters);
    const zoomThreshold = useSelector(selectMapProps).zoomThreshold;
    const [zoomLevel, setZoomLevel] = useState(map.getZoom());

    const filterList = !hidePopups ? filters : {
        statusCodes: [],
        locationTypes: []
    };

    if (hidePopups && map.dragging.enabled) {
        if (!positionInput) {
            map.zoomControl.remove();
            map.dragging.disable();
        }
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
    }

    useMapEvent('click', (event) => {
        if (positionInput) {
            props.positionChange([parseFloat(event.latlng.lat).toFixed(6), parseFloat(event.latlng.lng).toFixed(6)]);
        }
    })

    useMapEvent('zoom', () => {
        setZoomLevel(map.getZoom());
    });

    const onEachFeature = (feature = {}, layer) => {
        const { properties = {} } = feature;
        const { imenazivst, zona } = properties;
        layer.bindPopup(`<p>${imenazivst}</p><p>${zona}</p>`);
    }

    const maxShowZoom = hidePopups && !positionInput ? 0 : zoomThreshold;

    const array = useCallback(() => {
        return <>
        {/*<LayersControl.Overlay checked name='Markers'>*/}
            <LayerGroup>{[
                // LOCATIONS //
                ...locations.map((element) => 
                    <MapMarker key={element._id} element={element} location hidePopup={hidePopups} maxShowZoom={maxShowZoom} />),
                // MEASURE POINTS //
                zoomLevel > maxShowZoom ? [...measurePoints.map((element) => {
                    if (!filterList.statusCodes.find(status => element.statusCode.find(el => status.key === Object.keys(el)[0] && status.value === Object.values(el)[0])) && !filterList.locationTypes.includes(element.type)) {
                        return <MapMarker key={element._id} element={element} measurePoint hidePopup={hidePopups} maxShowZoom={maxShowZoom} />;
                    } else return null;
                })] : null,
                // MEASURE POINTS CONNECTIONS //
                zoomLevel > maxShowZoom ? [...connections.map((point) => {
                    if (!filterList.statusCodes.includes(point.filtersData.statusCode) && !(filterList.locationTypes.includes(point.filtersData.type) && filterList.locationTypes.includes(point.filtersData.parentType))) {
                        return (
                            <Polyline
                                key={point.key}
                                positions={point.positions}
                                color={'black'} dashArray={'4,6'}
                                dashOffset={'1'}
                                weight={2}
                            />);
                    }
                    else return null;
                })] : null
            ]}
            </LayerGroup>
           {/* </LayersControl.Overlay>
            <LayersControl.Overlay name='Shapes'>*/}
               {locationId ? null : 
                <LayerGroup>
                {[...locations.map((element) => <div key={element._id}>
                        {element.geoFeature ? <GeoJSON data={element.geoFeature} onEachFeature={onEachFeature} /> : null}
                    </div>)
                ]}
                </LayerGroup>
                }
           {/* </LayersControl.Overlay>*/}
        </>
    }, [connections, filterList.locationTypes, filterList.statusCodes, 
    hidePopups, locations, maxShowZoom, measurePoints, zoomLevel, locationId]);

    return array();
    
}

export default MapElements;