import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Button } from '@react-gcc-eds/core';
import { FormattedMessage } from 'react-intl';
import { DataEntryForm, HistoryDataForm } from 'components';
import { selectLocationDetails } from 'redux/locationsSlice';
import { isUserSelector } from 'redux/userSlice';

const SamplingButtons = (props) => {
    const { sidebarDispatch } = props;
    const { locationId } = useParams();
    const location = useSelector((state) => selectLocationDetails(state, locationId));
    const isUser = useSelector(isUserSelector);
    const [form, toggleForm] = useState(0);
    const [type, setType] = useState(null);
    const [field] = useState(location.resourceTypes.filter(resKey => resKey > 3000 && resKey < 4000));
    
    useEffect(() => {
        if (field && type === "data") {
            sidebarDispatch('SET', {
                title: <FormattedMessage id="FORM.TITLE" />,
                subtitle: <span><FormattedMessage id="FORM.SUBTITLE" />{location.name}</span>,
                display: () => <DataEntryForm locationId={locationId} sidebarDispatch={sidebarDispatch} field={field} />
            })
        }
        else if (type === "history") {
            sidebarDispatch('SET', {
                title: <FormattedMessage id="FORM.HISTORY_TITLE" />,
                subtitle: <span><FormattedMessage id="FORM.SUBTITLE" />{location.name}</span>,
                display: () => <HistoryDataForm sidebarDispatch={sidebarDispatch} />
            })
        }
    }, [form, location, locationId, sidebarDispatch, field, type]);

    return isUser ? <>
        <Button className="sampling-button form" iconName="tasks" onClick={() => { toggleForm(!form); setType("data"); sidebarDispatch('OPEN') }}> <FormattedMessage id="BUTTON.ADD.SAMPLE" /> </Button>
        <Button className="sampling-button form" iconName="tasks" onClick={() => { toggleForm(!form); setType("history"); sidebarDispatch('OPEN') }}> <FormattedMessage id="BUTTON.ADD.HISTORY" /> </Button>
    </> : null;
}

export default SamplingButtons;