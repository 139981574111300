import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Loader, SingleSelect, Text, TextField, Tooltip, Icon } from '@react-gcc-eds/core';
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import LeafletMarkers from 'utils/LeafletMarkers';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectLocationDetails } from 'redux/locationsSlice';
import { selectLocalizationConf, selectResourcesTypesConf, selectTypologyConf, selectStatusCodesTypesConf } from 'redux/configurationSlice';
import { userSelector } from 'redux/userSlice';
import { selectMapProps } from 'redux/mapSlice';
import { MapElements, FileUploadButton } from 'components';
import API from 'api';
import './locationDetailsForm.scss';

function LocationDetailsForm(props) {
    const { sidebarDispatch, locationId } = props;
    const intl = useIntl();
    const history = useHistory();
    const mapProps = useSelector(selectMapProps);
    const token = useSelector(userSelector).token;
    const location = useSelector(state => selectLocationDetails(state, locationId));
    const typology = useSelector(selectTypologyConf);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const statusTypes = useSelector(selectStatusCodesTypesConf);
    const localization = useSelector(selectLocalizationConf);
    const parentLocation = useParams().locationId;
    const locationImageUrl = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + (locationId ? location.picture : process.env.REACT_APP_DEFAULT_IMAGE);
    const [name, setName] = useState(locationId ? location.name : '');
    const [description, setDescription] = useState(locationId ? location.description[intl.locale] : '');
    const [position, setPosition] = useState(locationId ? location.position : [mapProps.defaultX, mapProps.defaultY]);
    const [image, setImage] = useState(null);
    const [resources, setResources] = useState(Object.fromEntries(resourceTypes.map(el => ([el.key, {
        name: el.name,
        checked: locationId ? location.resourceTypes.includes(parseInt(el.key)) : false
    }]))));
    const [statusCodeTypes, setStatusCodeTypes] = useState(Object.fromEntries(statusTypes.map(el => ([el.key, {
        name: el.key,
        status: locationId ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? Object.values(location.statusCode.find(status => Object.keys(status)[0] === el.key))[0] : 0 : 0,
        checked: locationId ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? true : false : false
    }]))));
    const [type, setType] = useState(locationId ? { title: intl.formatMessage({ id: 'MP_TYPES.' + typology.find(type => type.key === location.type).value }), value: location.type } : { title: intl.formatMessage({ id: 'MP_TYPES.MP_TYPE_UNKNOWN' }), value: 0 });
    const [warning, setWarning] = useState('');
    const [unchangedFlags, setFlags] = useState({});

    useEffect(() => {
        if (locationId) {
            const changedResources = !Object.entries(resources).map(([key, value]) => {
                if ((value.checked && !location.resourceTypes.includes(parseInt(key))) || (!value.checked && location.resourceTypes.includes(parseInt(key)))) return true;
                else return false;
            }).filter(el => el).length;

            const changedStatus = !Object.entries(statusCodeTypes).map(([key, value]) => {
                if ((value.checked && !location.statusCode.find(el => Object.keys(el)[0] === key)) || (!value.checked && location.statusCode.find(el => Object.keys(el)[0] === key))) return true;
                else return false;
            }).filter(el => el).length;

            const newFlags = {
                image: image === null,
                name: name === location.name,
                description: description === location.description[intl.locale],
                resources: changedResources,
                indexTypes: changedStatus,
                position: position[0] === location.position[0] && position[1] === location.position[1],
                all: name === location.name && changedResources && changedStatus && description === location.description[intl.locale] && position[0] === location.position[0] && position[1] === location.position[1] && image === null,
            };
            setFlags(newFlags);
        }
        else {
            const newFlags = {
                name: name !== '',
                position: true,
                description: true,
                resources: true,
                indexTypes: true,
                image: true,
                all: name === ''
            };
            setFlags(newFlags);
        }
    }, [locationId, name, description, position, resources, statusCodeTypes, image, location, intl])

    useEffect(() => {
        if (!unchangedFlags.all) {
            setWarning('');
        }
    }, [unchangedFlags, setWarning]);


    const renderForm = useCallback(() => {
        const handlePositionChange = (lat, lng) => {
            const newPos = [];
            parseFloat(lat) ? newPos.push(parseFloat(lat)) : newPos.push(0);
            parseFloat(lng) ? newPos.push(parseFloat(lng)) : newPos.push(0);
            setPosition(newPos);
        }

        const handleSubmit = () => {
            if (unchangedFlags.all) {
                const errorMsg = locationId ? intl.formatMessage({ id: 'FORM.LOCATION.FALSE_SUBMIT' }) : intl.formatMessage({ id: 'FORM.MP.FALSE_SUBMIT' });
                setWarning(errorMsg);
                return;
            }
            let newDescription = null;
            if (locationId && !unchangedFlags.description) {
                newDescription = { ...location.description };
                newDescription[intl.locale] = description;
            }
            else {
                const newDescriptionMap = new Map(localization.map(el => {
                    if (el.value === intl.locale) return [el.value, description];
                    else return [el.value, ''];
                }));
                newDescription = Object.fromEntries(newDescriptionMap);
            }
            const newLocation = {
                name: name,
                position: position,
                type: type.value,
                resourceTypes: Object.entries(resources).map(([key, value]) => value.checked ? key : null).filter(el => el !== null),
                statusCode: Object.entries(statusCodeTypes).map(([key, value]) => value.checked ? { [key]: value.status } : null).filter(el => el !== null),
                ...newDescription && { description: newDescription },
            }
            if (locationId) {
                API.locations.updateLocation(token, locationId, newLocation, image).then(({ data }) => {
                    sidebarDispatch('SET', {
                        title: <FormattedMessage id='REPORT.LOCATION.UPDATE_SUCCESS' />,
                        subtitle: <FormattedMessage id='REPORT.REFRESHING_PAGE' />,
                        display: () => <Loader size="large" />,
                        width: 'wide'
                    })
                    setTimeout(() => history.go(0), 700);
                }).catch(e => {
                    let errorMsg;
                    if (e.response.status === 403) errorMsg = "ACCESS_DENIED";

                    sidebarDispatch('SET', {
                        title: <FormattedMessage id='REPORT.ERROR' />,
                        display: () => <div className='error-msg' >
                            {errorMsg ? intl.formatMessage({id: "FORM." + errorMsg}) : e.message}
                        </div>
                    })
                })
            }
            else {
                API.locations.createLocation(token, newLocation, parentLocation, image).then(({ data }) => {
                    sidebarDispatch('SET', {
                        title: <FormattedMessage id='REPORT.LOCATION.ADD_SUCCESS' />,
                        subtitle: <FormattedMessage id='REPORT.REFRESHING_PAGE' />,
                        display: () => <Loader size="large" />,
                        width: 'wide'
                    })
                    setTimeout(() => history.go(0), 700);
                }).catch(e => {
                    let errorMsg;
                    if (e.response.status === 403) errorMsg = "ACCESS_DENIED";

                    sidebarDispatch('SET', {
                        title: <FormattedMessage id='REPORT.ERROR' />,
                        display: () => <div className='error-msg' >
                            {errorMsg ? intl.formatMessage({id: "FORM." + errorMsg}) : e.message}
                        </div>
                    })
                })
            }
        }

        const handleCheck = (checkFlag, key) => {
            const res = { ...resources };
            res[key].checked = checkFlag;
            setResources(res);
        }

        const handleStatusCheck = (checkFlag, key) => {
            const status = { ...statusCodeTypes };
            status[key].checked = checkFlag;
            setStatusCodeTypes(status);
        }

        const resourceTypesCheckboxes = Object.entries(resources).map(([key, el]) => {
            return <Checkbox onChange={(check) => handleCheck(check, key)} checked={el.checked} key={key} text={intl.formatMessage({ id: el.name })} />
        })
        const resourceTypesService = resourceTypesCheckboxes.filter(el => el.key > 1000 && el.key < 2000);

        const statusCodesTypesCheckboxes = Object.entries(statusCodeTypes).map(([key, el]) => {
            return <Checkbox onChange={(check) => handleStatusCheck(check, key)} checked={el.checked} key={key} text={intl.formatMessage({ id: key })} />
        })

        return <>
            <div className='name'>
                <Text as="div" size="md">{`${intl.formatMessage({ id: 'LOCATION.NAME' })}${unchangedFlags.name ? '' : '*'}`}</Text>
                <TextField focus type="text" name="name" placeholder={locationId ? location.name : intl.formatMessage({id: 'FORM.LOCATION_NAME'})} value={name} onChange={setName} />
            </div>
            <div className='description'>
                <Text as="div" size="md">{`${intl.formatMessage({ id: 'LOCATION.DESCRIPTION' })}${unchangedFlags.description ? '' : '*'}`}</Text>
                <textarea name="description" placeholder={locationId ? location.description[intl.locale] : intl.formatMessage({id: 'FORM.LOCATION_DESCRIPTION'})} value={description} onChange={event => setDescription(event.target.value)} />
            </div>
            <div className='type'>
                <Text as="div" size="md">{intl.formatMessage({ id: 'LOCATION.TYPE' })}</Text>
                <SingleSelect
                    items={typology.map(type => ({ title: intl.formatMessage({ id: 'MP_TYPES.' + type.value }), value: type.key }))}
                    label={ intl.formatMessage({id: 'MP_TYPES.' + typology.find(el => el.key === type.value).value}) }
                    placeholder={ intl.formatMessage({id: 'MP_TYPES.' + typology.find(el => el.key === type.value).value}) }
                    type='single'
                    onChange={setType}
                />
            </div>
            <div className='position'>
                <Text as="div" size="md"> {`${intl.formatMessage({ id: 'LOCATION.POSITION' })}${unchangedFlags.position ? '' : '*'}`} </Text>
                <Tooltip
                    key="addLocation"
                    position="top"
                    text={intl.formatMessage({ id: "FORM.SELET_NEW_PIN" })}
                    type="pointer"
                >
                    <Icon name="info" />
                </Tooltip>
                <MapContainer
                    center={position}
                    zoom={locationId ? 14 : 10}
                    maxZoom={mapProps.maxZoom}
                    minZoom={mapProps.minZoom}
                    maxBounds={mapProps.maxBounds}
                >
                    <TileLayer
                        key={"map-layer"}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url={
                            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        }
                    />
                    <MapElements key="map-elements" locationId={locationId ? locationId : true} positionInput positionChange={setPosition} />
                    {!locationId ? <Marker position={position} icon={LeafletMarkers.positionMarker()} /> : !unchangedFlags.position ? <Marker position={position} icon={LeafletMarkers.positionMarker()} /> : null}
                </MapContainer>
                <span className='coordinates'>
                    <Text className='position-label' as="div" size="sm">{intl.formatMessage({ id: 'FORM.NEW_COORDINATES' })}</Text>
                    <input type="number" step="0.000001" name="lat" value={position[0]} onChange={(e) => handlePositionChange(e.target.value, position[1])} />
                    <input type="number" step="0.000001" name="lng" value={position[1]} onChange={(e) => handlePositionChange(position[0], e.target.value)} />
                </span>
            </div>
            {statusCodesTypesCheckboxes.length ?
                <div className='data-indexes'>
                    <Text as="div" size="md">{`${intl.formatMessage({ id: 'LOCATION.QUALITY_INDEX' })}${unchangedFlags.indexTypes ? '' : '*'}`}</Text>
                    {statusCodesTypesCheckboxes}
                </div>
            : null}
            <div className='resource-types'>
                <Text as="div" size="md">{`${intl.formatMessage({ id: 'LOCATION.RESOURCES' })}${unchangedFlags.resources ? '' : '*'}`}</Text>
                <Text className='resource-label' as="div" size="sm">{intl.formatMessage({ id: resourceTypesService.length > 2 ?  'CHARTS.WEATHER_DATA' : 'CHARTS.TEMPERATURE_AND_PRECIPITATION' })}</Text>
                {resourceTypesService}
                <Text className='resource-label' as="div" size="sm">{intl.formatMessage({ id: 'CHARTS.SENSOR_DATA' })}</Text>
                {resourceTypesCheckboxes.filter(el => el.key > 2000 && el.key < 3000)}
                <Text className='resource-label' as="div" size="sm">{intl.formatMessage({ id: 'CHARTS.FIELD_DATA' })}</Text>
                {resourceTypesCheckboxes.filter(el => el.key > 3000 && el.key < 4000)}
            </div>
            <div className='picture'>
                <Text as="div" size="md">{`${intl.formatMessage({ id: 'LOCATION.PICTURE' })}${unchangedFlags.image ? '' : '*'}`}</Text>
                <FileUploadButton
                    single
                    text={intl.formatMessage({ id: 'FORM.NEW_LOCATION_IMAGE' })}
                    defaultImage={{ name: locationId ? location.name : 'hzhb.png', url: locationImageUrl }}
                    setFiles={setImage}
                />
            </div>
            <div className='actions'>
                <Button onClick={handleSubmit} primary>{intl.formatMessage({ id: 'FORM.SUBMIT_DATA' })}</Button>
            </div>
            {!!warning.length && <span className='warning'>{warning}</span>}
        </>
    }, [token, locationId, location, parentLocation, typology, image, statusCodeTypes, locationImageUrl, name, description, position, type, resources, unchangedFlags, warning, setName, setDescription, setPosition, setResources, setType, sidebarDispatch, mapProps, history, localization, intl])


    return <div className="location-details-form">
        {renderForm()}
    </div>;
}

export default LocationDetailsForm;