import React from 'react';
import { SingleSelect } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocalizationConf } from 'redux/configurationSlice';

const LanguageSelector = (props) => {
    const intl = useIntl();
    const localization = useSelector(selectLocalizationConf);
    return (
        <SingleSelect
            className='language-selector'
            items={localization.map(el => ({ title: intl.formatMessage({ id: 'LANGUAGE.' + el.key }), value: el.value  }))}
            label={intl.formatMessage({ id: 'LANGUAGE.SELECT_LANGUAGE' })}
            onChange={(lang => { props.languageSelector(lang.value.toString()) })}
        />
    )
}

export default LanguageSelector;