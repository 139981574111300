import React, { useState, useCallback } from "react";
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectResourcesTypesConf } from 'redux/configurationSlice';
import { SingleSelect } from '@react-gcc-eds/core';
import AddThreshold from "./AddThreshold";

function ThresholdResourceSelect (props) {
    const intl = useIntl();
    const resourceTypesConf = useSelector(state => selectResourcesTypesConf(state));
    const thresholds = Object.values(props.thresholds)[0];
    const [resourceTitle, setResourceTitle ] = useState(intl.formatMessage({id: "LOCATION.RESOURCES"})) 
    const [selectedResource, setSelectedResource] = useState('');
    const resourceTypesLocation = props.location.resourceTypes.filter(x => x > 3000 && x < 4000);
    
    
    const render = useCallback(() => {
        let selectOptions;

        const handleSelectChange = (e) => {
            setResourceTitle(e.title)
            setSelectedResource(e.value);
        }

        if(props.location.resourceTypes.filter(x => x > 3000 && x < 4000).length){
            if (thresholds.length > 0 ){
                const resourceTypeSelect = resourceTypesLocation.filter(x => thresholds.map(el => el.resourceTypeKey.toString()).indexOf(x.toString()) < 0)
                selectOptions = resourceTypeSelect.map(el => {
                    const resource = resourceTypesConf.find(x=> x.key.toString() === el.toString());
                        return {
                            title: intl.formatMessage({id: resource.name}),
                            value: resource.key
                    }
                    
                })
            }
            else {
                selectOptions = resourceTypesLocation.map(el => {
                    const resource = resourceTypesConf.find(x => x.key.toString() === el.toString());
                    return {
                        title: intl.formatMessage({id: resource.name}),
                        value: resource.key
                    }
                })
            }
            return selectOptions.length ? <div className="thresholds-select">
                <SingleSelect
                    items={selectOptions}
                    label={resourceTitle}                    
                    topLabel={intl.formatMessage({id: "THRESHOLD.SELECT_RESOURCE"})}
                    onChange={(e) => handleSelectChange(e)}
                    placeholder="Select option"
                    type="single"
                />
            </div> : null
        }
        else {
            return null;
        }
    }, [intl, props, resourceTypesConf, resourceTypesLocation, resourceTitle, thresholds]);

    const resetSelect = useCallback(() => {
        setSelectedResource('');
        setResourceTitle(intl.formatMessage({id: "LOCATION.RESOURCES"}));
    },[setSelectedResource, setResourceTitle, intl])

    return <>{ render() }
        { selectedResource ? 
        <div className="button-add-new">
            <AddThreshold onThresholdAdd={resetSelect} thresholds={props.thresholds} location={props.location} updateDataThreshold={props.updateDataThreshold} selectedResource={selectedResource} />
        </div> : null }
    </>
}

export default ThresholdResourceSelect;
