import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Tile } from '@react-gcc-eds/core';
import {
    DateRangeSelector,
    MeasurementsTrendChart,
    SamplingLogQualityDistributionPie,
    SamplingLogTypeDistributionPie,
    SamplingLogsSamplesCount,
    LatestMeasurements,
    LocationDetailsTab,
    QualityStatusKPI,
    MeasurePointsTable,
    ReportingButtons,
    SamplingButtons,
    DropdownAggregation
} from 'components';
import { selectLocationDetails } from 'redux/locationsSlice';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';


function ComplexLocationDetails(props) {
    const { aggregation, aggregationCallbacks, sidebarDispatch } = props;
    // get id from path
    const { locationId } = useParams();
    const measurePoints = useSelector(state => selectLocationDetails(state, locationId)).measurePoints;
    const location = useSelector(state => selectLocationDetails(state, locationId));

    const services = location.resourceTypes.filter(resKey => resKey > 1000 && resKey < 2000);
    const sensors = location.resourceTypes.filter(resKey => resKey > 2000 && resKey < 3000);
    const field = location.resourceTypes.filter(resKey => resKey > 3000 && resKey < 4000);

    return (
        <div name={'locationDetailsComplex_' + locationId} className='location-details complex-layout'>
            <Row>
                <Tile lg={7} md={7} sm={12} className='latest-measurements-tile'>
                    <Row>
                        <QualityStatusKPI locationId={locationId} />
                    </Row>
                    <Row>
                        <LatestMeasurements locationId={locationId} />
                    </Row>
                    <Row>
                        <MeasurePointsTable measurePoints={measurePoints} />
                    </Row>
                </Tile>
                <LocationDetailsTab sidebarDispatch={sidebarDispatch} key={locationId} location={location} />
            </Row>

            <Row>
                <Tile lg={7} md={12} sm={12} className='date-range-selector-tile'>
                    <DateRangeSelector />
                </Tile>
                <Tile lg={5} md={12} sm={12} className='reporting-buttons-tile'>
                    <ReportingButtons />
                    <SamplingButtons
                        sidebarDispatch={sidebarDispatch}
                        key={locationId}
                    />
                </Tile>
            </Row>

            <Row>
                {sensors.length ? <Tile title={<FormattedMessage id="CHARTS.SENSOR_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.sensorAggregation.group} />}
                    canMaximize={!!services.length} lg={services.length ? 6 : 12} md={12} sm={12}
                    className='sensor-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation
                        key='sensor-trend-chart-key'
                        setAggregation={aggregationCallbacks.setSensorAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={sensors}
                        aggregation={aggregation.sensorAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}

                {services.length ? <Tile title={<FormattedMessage id={services.length > 2 ? "CHARTS.WEATHER_DATA" : "CHARTS.TEMPERATURE_AND_PRECIPITATION"} />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.weatherAggregation.group} />}
                    bottom={<span className="data-attribution text-sm"><FormattedMessage id="API.ATTRIBUTION"/></span>}
                    canMaximize={!!sensors.length} lg={sensors.length ? 6 : 12} md={12} sm={12}
                    className='weather-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='weather-trend-chart-key'
                        setAggregation={aggregationCallbacks.setWeatherAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={services}
                        aggregation={aggregation.weatherAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                {field.length ? <Tile title={<FormattedMessage id="CHARTS.FIELD_DATA" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.fieldAggregation.group} />}
                    lg={12} md={12} sm={12}
                    className='field-trend-chart-tile'
                    dropdownMenu={[<DropdownAggregation key='field-trend-chart-key'
                        setAggregation={aggregationCallbacks.setFieldAggregationCallback}
                        aggregation={['week', 'day', 'hour']}
                    />]}
                >
                    <MeasurementsTrendChart
                        locationId={locationId}
                        resourceTypes={field}
                        aggregation={aggregation.fieldAggregation.time}
                        sidebarDispatch={sidebarDispatch}
                    />
                </Tile> : null}
            </Row>

            <Row>
                <Tile title={<FormattedMessage id="CHARTS.NO_OF_MEASUREMENTS" />}
                    subtitle={<FormattedMessage id={"GROUPING.AGGREGATION." + aggregation.numberAggregation.group} />}
                    canMaximize lg={6} md={12} sm={12}
                    className='sampling-logs-type-distribution-bars-tile'
                    dropdownMenu={[<DropdownAggregation key='sampling-logs-type-key'
                        setAggregation={aggregationCallbacks.setNumberMeasurementsAggregationCallback}
                        aggregation={['month', 'week', 'day']}
                    />]}
                >
                    <SamplingLogsSamplesCount locations={[locationId]} aggregation={aggregation.numberAggregation.time} />
                </Tile>

                <Tile lg={3} md={6} sm={12}
                    title={<FormattedMessage id="CHARTS.TYPE_OF_MEASUREMENTS" />}
                    className='sampling-logs-type-distribution-pie-tile' >
                    <SamplingLogTypeDistributionPie locations={[locationId]} />
                </Tile>

                <Tile lg={3} md={6} sm={12}
                    title={<FormattedMessage id="CHARTS.AVERAGE_QUALITY" />}
                    className='sampling-logs-quality-distribution-pie-tile' >
                    <SamplingLogQualityDistributionPie locations={[locationId]} />
                </Tile>
            </Row>

        </div >
    );
}

export default ComplexLocationDetails;
