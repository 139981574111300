import React, { useCallback } from 'react';
import { Text, Tile } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import { PictureGallery } from 'components';

import './samplingLogDetails.scss';


function SamplingLogDetails(props) {
    const { locationName, locationId, logId, sampleType, values, notes, pictures } = props;
    const intl = useIntl();

    const renderMeasurements = useCallback((row, name) => {
        const rowName = name.toUpperCase();
        return !!row.length ? <div className="content content-block">
            <Text as="div" size="lg" >{intl.formatMessage({ id: 'CHARTS.' + rowName })} ({row.length})</Text>
            <div className="values">
                {row.map(el =>
                    <div className="value-block" key={locationId + el.id}>
                        <div className="content content-block">
                            <Text as="div" size="md" className="resource-label">{el.resource}</Text>
                            <div className="value">
                                <Text as="span" size="xl">{parseFloat(el.value).toFixed(2)}</Text>
                                <Text as="span" size="lg" >{el.unit}</Text>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div> : null;
    }, [intl, locationId])


    const services = values.filter(el => el.id > 1000 && el.id < 2000);
    const sensors = values.filter(el => el.id > 2000 && el.id < 3000);
    const field = values.filter(el => el.id > 3000 && el.id < 4000);
    const microbiological = values.filter(el => el.id > 6000 && el.id < 7000);
    const chemical = values.filter(el => el.id > 4000 && el.id < 5000);
    const indicator = values.filter(el => el.id > 5000 && el.id < 5500);
    const water_indicator = values.filter(el => el.id > 5500 && el.id < 6000);

    return <Tile>
        <div className="sampling-log-tile">
            <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "SAMPLING_LOG_REPORT.LOCATION_NAME" })}</Text>
                <Text as="span" className="location-name">{locationName} </Text>
            </div>
            <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "SAMPLING_LOG_REPORT.SAMPLED_OBJECT_ID" })}</Text>
                <Text as="span" className="location-name">{locationId} </Text>
            </div>
            <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "SAMPLING_LOG_REPORT.SAMPLING_LOG_ID" })}</Text>
                <Text as="span" size="lg" className="details-label">{logId} </Text>
            </div>
            <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "SAMPLING_LOG_REPORT.SAMPLE_TYPE" })}</Text>
                <Text as="span" size="lg" className="details-label">{intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType })}</Text>
            </div>

            {!!notes && notes.length ? <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "FORM.NOTES_LABEL" })}</Text>
                <Text as="span" size="lg" className="notes details-label">{notes}</Text>
            </div> : null}

            {renderMeasurements(services, 'services')}

            {renderMeasurements(sensors, 'sensors')}

            {renderMeasurements(field, 'field')}

            {renderMeasurements(chemical, 'chemical')}

            {renderMeasurements(microbiological, 'microbiological')}

            {renderMeasurements(water_indicator, 'water_indicator')}

            {renderMeasurements(indicator, 'indicator')}

            {!!pictures && pictures.length ? <div className="content content-block">
                <Text as="div" size="md" className="title-label">{intl.formatMessage({ id: "FORM.PICTURES" })} ({pictures.length})</Text>
                <PictureGallery pictures={pictures.map(el => ({ name: el.split('/').slice(-1), url: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + el }))} />
            </div> : null}

        </div>
    </Tile>;
}

export default SamplingLogDetails;