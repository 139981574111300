import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Text, Button } from '@react-gcc-eds/core';
import './historyDataReport.scss';

const HistoryDataReport = (props) => {
    const locationId = useParams().locationId;
    const { reportData, statusCode } = props;
    const history = useHistory();
    const intl = useIntl();

    switch (statusCode) {
        case 200:
            return <div className='history-report'>
                <div>
                    <Text as="lg" >{intl.formatMessage({ id: "HISTORY_REPORT.200.TITLE" })}</Text>
                    <Text >{intl.formatMessage({ id: "HISTORY_REPORT.200.SUBTITLE" })}</Text>
                    <br />
                    <Text as='span'>{intl.formatMessage({ id: "HISTORY_REPORT.INVALID_ROWS" })} </Text><Text as='span' size='lg'> {reportData.errorArrays.number}</Text>
                    <br />
                    <br />
                    <Text as='span'>{intl.formatMessage({ id: "HISTORY_REPORT.VALID_ROWS" })} </Text><Text as='span' size='lg'> {reportData.samplingLogsArray.length}</Text>
                    <br />
                    <br />
                    <div className='history-report-buttons'>
                        <Button className='history-report' onClick={() => props.sidebarDispatch('CLOSE')}>
                            {intl.formatMessage({ id: 'FORM.CLOSE' })}
                        </Button>
                        <Button primary className='history-report' onClick={() => history.push(locationId + '/report', { data: reportData })}>
                            {intl.formatMessage({ id: 'FORM.DETAILS' })}
                        </Button>
                    </div>
                </div>
            </div>;
        case 201:
            return <div className='history-report'>
                <div>
                    <Text as="lg" >{intl.formatMessage({ id: "HISTORY_REPORT.201.TITLE" })}</Text>
                    <br />
                    <Text as='span'>{intl.formatMessage({ id: "HISTORY_REPORT.INVALID_ROWS" })} </Text><Text as='span' size='lg'> {reportData.errorArrays.number}</Text>
                    <br />
                    <br />
                    <Text as='span'>{intl.formatMessage({ id: "HISTORY_REPORT.VALID_ROWS" })} </Text><Text as='span' size='lg'> {reportData.samplingLogsArray.length}</Text>
                    <br />
                    <br />
                    <div className='history-report-buttons'>
                        <Button className='history-report' onClick={() => props.sidebarDispatch('CLOSE')}>
                            {intl.formatMessage({ id: 'FORM.CLOSE' })}
                        </Button>
                        <Button primary className='history-report' onClick={() => history.push(locationId + '/report', { data: reportData })}>
                            {intl.formatMessage({ id: 'FORM.DETAILS' })}
                        </Button>
                    </div>
                </div>
            </div>;
        default:
            return <div className='history-report'>
                <div>
                    <Text size='lg'>{intl.formatMessage({ id: "HISTORY_REPORT.ERROR." + props.message})}</Text>
                </div>
            </div>;
    }
}

export default HistoryDataReport;