import React, { useCallback } from 'react';
import { Row, Text, DateField, Button } from '@react-gcc-eds/core';
import { useSelector, useDispatch } from 'react-redux';
import { selectDateRange, setDatesRange, setNamedRange } from 'redux/dateRangeSlice';
import { FormattedMessage } from 'react-intl';
import './DateRangeSelector.scss';

const DateRangeSelector = (props) => {
    // const { title, subtitle, canMaximize, lg, md, sm, } = props;
    const selectedDateRange = useSelector(selectDateRange);
    const { rangeName } = selectedDateRange;
    const dispatch = useDispatch();

    const changeDateFrom = useCallback((value) => {
        const startDate = new Date(value);
        if (value && (startDate.getTime() < selectedDateRange.dateTo)) {
            dispatch(setDatesRange({
                dateFrom: startDate.getTime(),
                dateTo: selectedDateRange.dateTo
            }))
        }
    }, [selectedDateRange, dispatch]);

    const changeDateTo = useCallback((value) => {
        const endDate = new Date(value);
        if (value && (endDate.getTime() > selectedDateRange.dateFrom)) {
            dispatch(setDatesRange({
                dateFrom: selectedDateRange.dateFrom,
                dateTo: endDate.getTime()
            }))
        }
    }, [selectedDateRange, dispatch]);



    return (
        <div className="date-range-selector">
            <Row asContainer lg={3} md={6} sm={12} name='titleDateRangeRow' className='title-date-range-row'>
                <div name='startDateColumn' className='start-date-column'>
                    <Text as="span" size="lg"><FormattedMessage id={rangeName === 'week' || rangeName === 'month' ? "DATE_SELECTOR.DATE_FROM" : "DATE_SELECTOR.DATE_SELECTED"} /></Text>
                    <DateField name='startDate'
                        selectedDate={new Date(selectedDateRange.dateFrom)}
                        onDateSelected={changeDateFrom}
                    />
                </div>
                <div name='endDateColumn' className='end-date-column'>
                    <Text as="span" size="lg"><FormattedMessage id="DATE_SELECTOR.DATE_TO" /></Text>
                    <DateField name='endDate'
                        selectedDate={new Date(selectedDateRange.dateTo)}
                        onDateSelected={changeDateTo}
                    />
                </div>
                <div name='rangeButtonColumn' className='range-button-column'>
                    <Button name='btnWeek' key="week" onClick={() => dispatch(setNamedRange('week'))} primary={rangeName === 'week'}><FormattedMessage id="DATE_SELECTOR.WEEK" /></Button>
                    <Button name='btnMonth' key="month" onClick={() => dispatch(setNamedRange('month'))} primary={rangeName === 'month'} ><FormattedMessage id="DATE_SELECTOR.MONTH" /></Button>
                    <Button name='btnYear' key="year" onClick={() => dispatch(setNamedRange('year'))} primary={rangeName === 'year'}><FormattedMessage id="DATE_SELECTOR.YEAR" /></Button>
                </div>
            </Row>
        </div>

    );
}

export default DateRangeSelector;