import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthKeycloak, { keycloak } from 'api/AuthKeycloak';

const initialState = {
  token: null,
  refreshToken: null,
  userId: undefined,
  fullName: '',
  roles: [],
  cookieConsent: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  isDone: false,
  errorMessage: ''
};


export const authUser = createAsyncThunk('user/auth', async (arg, thunkAPI) => {
  const cookieConsent = document.cookie.indexOf('COOKIE_CONSENT') !== -1
  try {
    await AuthKeycloak('check-sso');
    if (keycloak.authenticated) {
      const userInfo = await keycloak.loadUserInfo();
      return {
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        userid: userInfo.sub,
        fullName: userInfo.preferred_username,
        roles: keycloak.realmAccess.roles,
        cookieConsent: cookieConsent
      };
    }
    else throw new Error()
  }
  catch {
    return thunkAPI.rejectWithValue({ cookieConsent: cookieConsent });

  }

});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleCookieConsent: (state, action) => {
      state.cookieConsent = action.payload.cookieConsent
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers:
    (builder) => {
      builder
        .addCase(authUser.fulfilled, (state, action) => {
          state.token = action.payload.token;
          state.refreshToken = action.payload.refreshToken;
          state.userId = action.payload.userid;
          state.fullName = action.payload.fullName;
          state.roles = action.payload.roles;
          state.cookieConsent = action.payload.cookieConsent;
          state.isSuccess = true;
          state.isDone = true;
          state.isError = false;
        })
        .addCase(authUser.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(authUser.rejected, (state, action) => {
          state.cookieConsent = action.payload !== undefined ? action.payload.cookieConsent : false;
          // console.log('Cookie consent ', action.payload.cookieConsent)
          state.isSuccess = false;
          state.isDone = true;
        })

    }
});

// Export selectors
export const userSelector = (state) => state.user;
export const isAdminSelector = (state) => state.user.roles.includes("app-admin");
export const isUserSelector = (state) => state.user.roles.includes("app-user");
export const cookieConsentSelector = (state) => state.user.cookieConsent;
export const fullNameSelector = (state) => {
  if (!state.user.fullName) return { firstName: '', lastName: '' };
  let [firstName, lastName] = state.user.fullName.split(" ");
  return {
    firstName,
    lastName
  };
};
export const { toggleCookieConsent } = userSlice.actions;

export default userSlice.reducer;
