import axios from 'axios';

export default axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH,
    headers: {'Content-Type' : 'application/json; charset=UTF-8'},
});

export const setAuthHeader = (token, config) => {
    if (config) {
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    }
    else return {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
}