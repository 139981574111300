import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Tile, MultiSelect, Text, Pill, Dropdown, Icon, Tooltip, Checkbox } from '@react-gcc-eds/core';
import { DateRangeSelector } from 'components';
import { useSelector } from 'react-redux';
import { selectDataTypesConf } from "redux/configurationSlice";
import { selectLegacyLocations } from 'redux/locationsSlice';
import './reportingFilters.scss';


function MeasurementReportingFilters(props) {
    const {
        multiLocation,
        tablesView,
        selectedLocations,
        sidebarDispatch,
        filterHandler,
        locationResources,
        // selectedType,
        sampleTypesSelected,
        measurementTypesSelected,
        types,
        aggregation,
    } = props;
    const [filterSidebar, setFilterSidebar] = useState(0);
    const [resourceTypes, setResourceTypes] = useState(types)
    const intl = useIntl();
    const samplingTypesConfig = useSelector(selectDataTypesConf);
    const locations = useSelector(selectLegacyLocations);

    useEffect(() => {
        if (locationResources !== undefined && locationResources.length >= 0) setResourceTypes(types.filter(el => locationResources.includes(el.key)));
    }, [locationResources, types, setResourceTypes]);

    useEffect(() => {
        const selectedFilters = sampleTypesSelected.length || measurementTypesSelected.length;
        sidebarDispatch('SET', {
            title: intl.formatMessage({ id: 'LOCATION.ADITIONAL_FILTERS' }),
            subtitle: selectedFilters > 0 ? <Pill onRemove={() => filterHandler()}><FormattedMessage id="LOCATION.FILTERS.CLEAR" /></Pill> : '',
            width: 'narrow',
            display: () => <div className="aditional-filter-items">

                <div className="filter-item">
                    <Text as="span" size="lg"> <FormattedMessage id="SAMPLE_LOG.TYPES" /> </Text>
                    <MultiSelect
                        items={samplingTypesConfig.map(samplingType => {
                            return {
                                title: intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${samplingType.value}` }),
                                value: samplingType.key
                            }
                        })}
                        onChange={(data) => filterHandler(data, 'samples')}
                        placeholder={`${sampleTypesSelected.length} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`}
                        selectedItems={sampleTypesSelected.map(el => {
                            return {
                                title: intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${samplingTypesConfig.find(sampleType => sampleType.key === el.value).value}` }),
                                value: el.value
                            }
                        })}
                        type="input-multi"
                    /></div>
                <div className="filter-item">
                    <Text as="span" size="lg"> <FormattedMessage id="CHARTS.TYPE_OF_MEASUREMENTS" /> </Text>
                    <MultiSelect
                        items={resourceTypes.map(resource => {
                            return {
                                title: `${intl.formatMessage({ id: resource.name })} (${intl.formatMessage({ id: "RESOURCE_TYPE.CATEGORY." + (resource.key / 1000).toFixed(0)  })})`,
                                value: resource.key
                            }
                        })}
                        onChange={(data) => filterHandler(data, 'measurements')}
                        placeholder={`${measurementTypesSelected.length} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`}
                        selectedItems={measurementTypesSelected.map((el,index) => {
                            const resource = resourceTypes.find(resource => resource.key === el.value);
                            return {
                                title: `${intl.formatMessage({id: resource.name})} (${intl.formatMessage({ id: "RESOURCE_TYPE.CATEGORY." + (resource.key / 1000).toFixed(0)  })})`,
                                value: el.value
                            }
                        })}
                        type="input-multi"
                    />
                </div>
                <div className="filter-item">
                    <Text as="div" size="lg"> <FormattedMessage id="GROUPING.GROUP_BY" /> </Text>
                    <Dropdown
                        items={[
                            {
                                title: intl.formatMessage({ id: 'GROUPING.NO_GROUP' }),
                                value: {
                                    callback: () => filterHandler({ group: 'NO_GROUP', time: null }, 'aggregation'),
                                    name: 'h'
                                }
                            },
                            {
                                title: intl.formatMessage({ id: 'GROUPING.PER_HOUR' }),
                                value: {
                                    callback: () => filterHandler({ group: 'PER_HOUR', time: 'h' }, 'aggregation'),
                                    name: 'h'
                                }
                            },
                            {
                                title: intl.formatMessage({ id: 'GROUPING.PER_DAY' }),
                                value: {
                                    callback: () => filterHandler({ group: 'PER_DAY', time: 'd' }, 'aggregation'),
                                    name: 'd'
                                }
                            },
                            {
                                title: intl.formatMessage({ id: 'GROUPING.PER_WEEK' }),
                                value: {
                                    callback: () => filterHandler({ group: 'PER_WEEK', time: 'w' }, 'aggregation'),
                                    name: 'w'
                                }
                            }
                        ]}
                        label={intl.formatMessage({ id: "GROUPING.AGGREGATION." + aggregation.group })}
                        onItemClick={(item) => item.value.callback()}
                    />
                </div>
                {multiLocation && <div className='filter-item'>
                    <Checkbox text={<FormattedMessage id="LOCATION.FILTERS.TABLE_VIEW" />} checked={tablesView} onChange={() => filterHandler(null, 'tables')} />
                </div>}
            </div>
        });
    },
        [
            filterHandler,
            filterSidebar,
            tablesView,
            samplingTypesConfig,
            sampleTypesSelected,
            measurementTypesSelected,
            resourceTypes,
            locationResources,
            multiLocation,
            aggregation,
            intl,
            sidebarDispatch
        ]
    );

    const selectedFilters = sampleTypesSelected.length || measurementTypesSelected.length;
    return <Row>
        <Tile
            className="filter-tile"
            title={
                <Row>
                    <DateRangeSelector />
                    {!!multiLocation &&
                        <div className='filter-item location-select'>
                            <Text as="span" size="lg"> <FormattedMessage id="LOCATION.ALL_LOCATIONS" />: </Text>
                            <MultiSelect
                                items={locations.map(location => {
                                    return {
                                        title: location.name,
                                        value: location._id
                                    }
                                })}
                                onChange={(data) => filterHandler(data, 'locations')}
                                placeholder={intl.formatMessage({ id: "LOCATION.SELECT" })}
                                selectedItems={selectedLocations}
                                type="input-multi"
                            />
                        </div>
                    }
                </Row>
            }
            actions={[
                <Tooltip
                    key="aditional-filters" position="left" type="pointer"
                    text={
                        `${sampleTypesSelected.length + measurementTypesSelected.length} ${intl.formatMessage({ id: "INPUT.SELECTED_PLACEHOLDER" })}`
                    }
                >
                    { /*promijenit za senzore i to na dev brancu*/}
                    {/* <Pill
                        className={selectedType === 'air' ? "filter-type selected" : ""}
                        onToggle={() => filterHandler(measurementTypes, 'air')}
                    >
                        <Icon name="weather-windforce" />
                        {intl.formatMessage({ id: "CHARTS.AIR" })}
                    </Pill>
                    <Pill
                        className={selectedType === 'water' ? "filter-type selected" : ""}
                        onToggle={() => filterHandler(measurementTypes, 'water')}
                    >
                        <Icon name="weather-humidity" />
                        {intl.formatMessage({ id: "CHARTS.WATER" })}
                    </Pill> */}
                    <Pill onToggle={() => { setFilterSidebar(!filterSidebar); sidebarDispatch('OPEN') }}>
                        <Icon name={selectedFilters ? "filter" : "filter-off"} />
                        {intl.formatMessage({ id: "LOCATION.ADITIONAL_FILTERS" })}
                    </Pill>
                </Tooltip>
            ]}
        />
    </Row>;
}

export default MeasurementReportingFilters;