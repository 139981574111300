import React from 'react';
import { AppView, Tile, Text, Table } from '@react-gcc-eds/core';
import { useIntl } from 'react-intl';
import './cookiesPolicy.scss'

function CookiesPolicy() {
    const intl = useIntl();
    return (
        <AppView
            theme='light'
            className='cookie-policy-title'
            title={intl.formatMessage({ id: 'COOKIE_POLICY.TITLE' })}
            subtitle={intl.formatMessage({ id: 'COOKIE_POLICY.SUBTITLE' })}
        >

            <Tile className='cookies-policy-container'>
                <Tile>
                    <Text as="span" size="xl">{intl.formatMessage({ id: 'COOKIE_POLICY.WHAT_ARE_COOKIES' })}</Text>
                    <Text>{intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_EXPLANATION' })}</Text>
                </Tile>
                <Tile>
                    <Text as="span" size="xl">{intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_USAGE' })}</Text>
                    <Text>{intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_APPLICATION_USAGE' })}</Text>
                </Tile>
                <Tile className='cookie-content-tile'>
                <Text as="span" size="xl">{intl.formatMessage({ id: 'COOKIE_POLICY.COOKIES_WE_USE' })}</Text>
                <Text className="cookies-policy-we-use">{intl.formatMessage({ id: 'COOKIE_POLICY.COOKIES_WE_USE_EXPLANATION' })}</Text>
                    <Table
                        className="cookie-policy-table"
                        columns={[
                            {
                                header: intl.formatMessage({ id: 'COOKIE_POLICY.COLUMN_NAME' }),
                                key: 'name'
                            },
                            {
                                header: intl.formatMessage({ id: 'COOKIE_POLICY.COLUMN_SERVICE' }),
                                key: 'service'
                            },
                            {
                                header: intl.formatMessage({ id: 'COOKIE_POLICY.COLUMN_PURPOSE' }),
                                key: 'purpose'
                            },
                            {
                                header: intl.formatMessage({ id: 'COOKIE_POLICY.COLUMN_COOKIE_TYPE' }),
                                key: 'cookieType'
                            },
                            {
                                header: intl.formatMessage({ id: 'COOKIE_POLICY.COLUMN_DURATION' }),
                                key: 'duration'
                            }
                        ]}
                        rows={[
                            {
                                name: 'COOKIE_CONSENT',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_CONSENT_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_CONSENT_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_CONSENT_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.COOKIE_CONSENT_DURATION' })
                            },
                            {
                                name: 'KEYCLOAK_SESSION_LEGACY',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_LEGACY_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_LEGACY_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_LEGACY_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_LEGACY_DURATION' })
                            },
                            {
                                name: 'KEYCLOAK_IDENTITY_LEGACY',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_LEGACY_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_LEGACY_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_LEGACY_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_LEGACY_DURATION' })
                            },
                            {
                                name: 'AUTH_SESSION_ID_LEGACY',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_LEGACY_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_LEGACY_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_LEGACY_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_LEGACY_DURATION' })
                            },
                            {
                                name: 'AUTH_SESSION_ID',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.AUTH_SESSION_ID_DURATION' })
                            },
                            {
                                name: 'KEYCLOAK_IDENTITY',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_IDENTITY_DURATION' })
                            },
                            {
                                name: 'KEYCLOAK_SESSION',
                                service: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_SERVICE' }),
                                purpose: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_PURPOSE' }),
                                cookieType: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_COOKIE_TYPE' }),
                                duration: intl.formatMessage({ id: 'COOKIE_POLICY.KEYCLOAK_SESSION_DURATION' })
                            }
                        ]} />
                </Tile>
            </Tile>
        </AppView>
    )

}

export default CookiesPolicy;