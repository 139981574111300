import React, { useRef, useState } from 'react';
import { Button } from '@react-gcc-eds/core';
import { PictureGallery } from 'components';
import { useIntl } from 'react-intl';

const FileUploadButton = (props) => {
    const fileInputRef = useRef(null);
    const intl = useIntl();
    const defaultPicture = props.defaultImage ? [props.defaultImage] : [];
    const [pictures, setPictures] = useState(defaultPicture);
    const [error, setError] = useState(null);


    const handleInputChange = event => {
        let pictures = event.target.files;
        if (!pictures.length) return;

        const imgs = [];
        for (const picture of pictures) {
            if (!picture.type.includes('image')) setError(intl.formatMessage({ id: "FORM.PHOTO_UPLOAD_ERROR" }));
            else imgs.push({ name: picture.name, url: URL.createObjectURL(picture) })
        }
        setPictures(imgs);

        if (props.setFiles) props.setFiles(pictures);
        if (props.setImages) props.setImages(imgs);
    }

    const handleInputClick = event => {
        event.target.value = null;
        if (props.setFiles) props.setFiles(null);
        setPictures(defaultPicture);
        setError(null);
    }

    const handleClick = event => {
        fileInputRef.current.click();
    }

    return (
        <>
            {pictures.length ? <PictureGallery single={props.single} pictures={pictures} /> : null}
            {error !== null && error}
            <Button disabled={props.disabled} className={props.className} iconName='upload' onClick={handleClick}>
                {props.altText && pictures.length ? props.altText : props.text}
            </Button>
            <input
                name="pictures"
                type="file"
                multiple={!props.single}
                accept="image/png, image/jpeg, image/jpg"
                ref={fileInputRef}
                onChange={handleInputChange}
                onClick={handleInputClick}
                style={{ display: "none" }}
            />
        </>
    );
};

export default FileUploadButton;